import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';

import { openModal } from 'components/Modal';

const UsersListEntry = (props) => {
    //TODO: filter active position and ressorts
    return (
        <tr onClick={() => props.openModal('userEdit', props.data)} className="hover-pointer">
            <td>{props.data.user.email}</td>
            <td>{props.data?.member?.first_name}</td>
            <td>{props.data?.member?.last_name}</td>
            <td>{props.data.roles.join(', ')}</td>
            <td style={{ width: '2rem' }}>
                <Dropdown
                    icon={<Icon name="ellipsis vertical" color="grey" size="large" />}
                    floating
                    pointing
                    className="right"
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            icon="edit"
                            text="Bearbeiten"
                            onClick={() => props.openModal('userEdit', props.data)}
                        />
                        <Dropdown.Item
                            icon="delete"
                            text="Löschen"
                            onClick={() => props.openModal('userDelete', props.data)}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
};

const mapStateToProps = (state, ownProps) => ({
    //TODO set real role
});

UsersListEntry.propTypes = {
    data: PropTypes.object,
    openModal: PropTypes.func.isRequired,
    roleName: PropTypes.string,
};

export default connect(mapStateToProps, { openModal })(UsersListEntry);
