import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

import { deleteRole, fetchRoles } from 'features/administration/roles/redux/rolesActions';

const RightDeleteModal = (props) => {
    const dispatch = useDispatch();

    const handleConfirm = () => {
        console.info('DATA: ', props.data);
        dispatch(deleteRole(props.data.id));
        dispatch(fetchRoles());
        props.closeModal();
    };

    const right = props.data;
    return (
        <React.Fragment>
            <Modal.Header>Recht löschen</Modal.Header>
            <Modal.Content className="text-big">
                Sind Sie sicher, dass Sie das Recht <strong>{right.name}</strong> (verwendet in{' '}
                {right.roles.length > 1 ? right.roles.length + ' Rechten' : 'einem Recht'}) löschen
                möchten?
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    content="Recht löschen"
                    icon="checkmark"
                    labelPosition="left"
                    onClick={handleConfirm}
                />
                <Button content="Abbrechen" onClick={props.closeModal} />
            </Modal.Actions>
        </React.Fragment>
    );
};

RightDeleteModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
};

export default RightDeleteModal;
