import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { formatDate, datesEqual } from './calendarHelper';

const StyledDay = styled.div`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;

    border: 1px solid ${(p) => (p.today && '#2185d0') || (p.selected && '#ccc') || 'transparent'};

    ${(p) => p.columnStart && `grid-column-start: ${p.columnStart};`} ${(p) =>
        p.weekend && 'color: rgba(0,0,0, 0.35);'}
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    ${(p) =>
        p.hasTermine &&
        css`
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 4px;
                left: 4px;
                width: 0.35rem;
                height: 0.35rem;
                background-color: #2185d0;
                border-radius: 50%;
            }
        `};
`;

const Day = ({ date, onClick, termine, selected }) => {
    return (
        <StyledDay
            data-date={formatDate(date)}
            onClick={() => onClick(date.getDate())}
            columnStart={date.getDate() === 1 && (date.getDay() || 7)}
            selected={selected}
            today={datesEqual(date, new Date())}
            hasTermine={termine.length}
            weekend={date.getDay() === 0 || date.getDay() === 6}
            /* title={
                termine.length
                    ? 'Termine:\n' + termine.map(termin => '- ' + termin.title).join('\n')
                    : ''
            } */
        >
            {date.getDate()}
        </StyledDay>
    );
};

Day.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    onClick: PropTypes.func,
    termine: PropTypes.array,
    selected: PropTypes.bool,
};

export default Day;
