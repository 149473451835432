import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Divider, Header, Message, Segment } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';

import Form from 'components/Form';
import Input from 'components/Input';
import fieldLevelValidation from 'utilities/fieldLevelValidation';
import { changePassword } from './userActions';
import { getPasswordIsChanging, getPasswordChangigError } from './userSelector';
import { logout } from 'features/auth/authActions';

class ChangePassword extends Component {
    submitHandler = (values) => {
        this.props.changePassword({
            current_password: values.current_password,
            new_password: values.new_password,
            new_password_confirm: values.new_password_confirm,
        });
    };

    // function calls can no longer be part of the validate array and need to be used
    // outside the validate prop
    isEqualToNewPasswordField = fieldLevelValidation.isEqualToField('new_password');

    render() {
        console.log({ props: this.props });
        return (
            <Container className="small">
                <Header as="h1" content="Passwort ändern" textAlign="center" />
                <Segment>
                    <Form
                        onSubmit={this.props.handleSubmit(this.submitHandler)}
                        loading={this.props.changing}
                    >
                        <Field
                            name="current_password"
                            label="Altes Passwort"
                            type="password"
                            component={Input}
                            validate={[fieldLevelValidation.required]}
                            autoComplete="current-password"
                        />
                        <Field
                            name="new_password"
                            label="Neues Passwort (min. 8 Zeichen)"
                            type="password"
                            component={Input}
                            validate={[
                                fieldLevelValidation.required,
                                fieldLevelValidation.minLength(8),
                            ]}
                            autoComplete="new-password"
                        />
                        <Field
                            name="new_password_confirm"
                            label="Neues Passwort bestätigen"
                            type="password"
                            component={Input}
                            validate={[
                                fieldLevelValidation.required,
                                this.isEqualToNewPasswordField,
                            ]}
                            autoComplete="new-password"
                        />
                        <Divider hidden />
                        <div style={{display: 'flex', justifyContent: 'center'}}><Button
                            primary
                            type="submit"
                            content="Passwort ändern"
                            style={{ minWidth: '50%' }}
                        /></div>
                        {/* <Button
                            as={Link}
                            to={'/user/resetPassword'}
                            content="Passwort vergessen"
                            className="blank"
                        /> */}
                    </Form>
                </Segment>
                {!this.props.changing && this.props.error && (
                    <Message negative>
                        <Message.Header>Fehler!</Message.Header>
                        <p>{this.props.error}</p>
                    </Message>
                )}

                {this.props.submitSucceeded && !this.props.changing && !this.props.error && (
                    <Message positive>
                        <Message.Header>Password geändert!</Message.Header>
                        <p>Dein Passwort wurde erfolgreich geändert.</p>
                        <Button
                            primary
                            content="Zurück zur Mitgliederliste"
                            as={Link}
                            to={'/member'}
                        />
                    </Message>
                )}
            </Container>
        );
    }
}

ChangePassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    changing: PropTypes.bool.isRequired,
    error: PropTypes.string,
    logout: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    changing: getPasswordIsChanging(state),
    error: getPasswordChangigError(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            changePassword,
            logout,
        },
        dispatch
    );
};

export default reduxForm({
    form: 'changePassword',
})(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
