import * as types from './authTypes';

// LOGIN
export const login = (username, password) => ({
    type: types.LOGIN,
    request: {
        url: '/api/token',
        auth: { username, password },
        method: 'post',
    },
});

export const loginGitlab = (code) => ({
    type: types.LOGIN_GITLAB,
    request: {
        url: '/api/login',
        data: {code},
        method: 'post',
    },
});



// LOGOUT
// export const logout = () => (dispatch, getState) => {
//     dispatch(logoutRequest());
//     backendRequest({
//         handler: 'auth/deleteAllApiTokens',
//         data: {
//             delete_token: getState().auth.apiToken
//         },
//         success: () => dispatch(logoutSuccess()),
//         failure: (error) => dispatch(logoutFailure(error))
//     }, getState);
// };

export const logout = () => ({
    type: types.LOGOUT_SUCCESS,
});

// VALIDATE_API_TOKEN
export const validateApiToken = (username, apiToken) => ({
    type: types.VALIDATE_API_TOKEN,
    request: {
        url: 'api/token/validate',
        data: { apiToken, username },
        method: 'post',
    },
});
