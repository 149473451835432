import { Education, Member } from 'features/member/memberTypes';
import { calcAge } from 'utilities';
import { FilterValue } from './types';

export const isInFilter = (filter: FilterValue[], value: any) => {
    return filter.indexOf(value) > -1;
};

export const school = (educations: Education[], filter: FilterValue[]) => {
    return educations.some((education) => isInFilter(filter, education.school));
};

export const subject = (educations: Education[], filter: FilterValue[]) => {
    return educations.some((education) => isInFilter(filter, education.subject));
};

export const degree = (educations: Education[], filter: FilterValue[]) => {
    return educations.some((education) => isInFilter(filter, education.degree));
};

export const age = (contact: any, filter: FilterValue[]) => {
    return isInFilter(filter, calcAge(contact.contact.birth_date));
};

export const ressort = (member: Member, filter: FilterValue[]) => {
    return member.ressorts
        .filter((ressort) => !ressort.end)
        .some((ressort) => isInFilter(filter, ressort.name_short));
};

export const position = (member: Member, filter: FilterValue[]) => {
    return member.positions
        .filter((position) => !position.end)
        .some((position) => isInFilter(filter, position.title));
};

export const status = (member: Member, filter: FilterValue[]) => {
    return member.status
        .filter((status) => !status.end)
        .some((status) => isInFilter(filter, status.title));
};

export const mSchool = (member: Member, filter: FilterValue[]) => {
    return member.educations && school(member.educations, filter);
};

export const mSubject = (member: Member, filter: FilterValue[]) => {
    return member.educations && subject(member.educations, filter);
};

export const mDegree = (member: Member, filter: FilterValue[]) => {
    return member.educations && degree(member.educations, filter);
};
