import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const CustomIcon = styled(Icon)`
    &&:before {
        content: '';
        display: block;
        height: 1em;
        width: 1em;
        background-image: url('${(p) => p.src}') !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
`;

CustomIcon.propTypes = {
    src: PropTypes.string.isRequired,
};

export default CustomIcon;
