import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';

import { logout } from './authActions';
import { getLoggingOut } from './AuthSelector';

const RestrictedPage = (props) => (
    <div id="restricted-page">
        <Grid centered>
            <Grid.Column width="10">
                <Header as="h1" content="Seite gesperrt" textAlign="center" />
                <Segment>
                    <Form loading={props.loggingOut}>
                        <p>
                            Du hast nicht die erforderliche Berechtigung, um diese Seite zu sehen.
                        </p>
                        <p>
                            Wende dich bitte an{' '}
                            <a href="mailto:it@hhc-duesseldorf.de">it@hhc-duesseldorf.de</a>, wenn
                            du der Meinung bist, dass du die erforderliche Berechtigung haben
                            solltest.
                        </p>
                        <Button
                            as="a"
                            href="mailto:it@hhc-duesseldorf.de"
                            primary
                            content="IT kontaktieren"
                            style={{ marginRight: '1rem' }}
                        />
                        <Button
                            className="blank"
                            content="Abmelden, um sich neu anzumelden"
                            onClick={props.logout}
                        />
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    </div>
);

RestrictedPage.propTypes = {
    logout: PropTypes.func.isRequired,
    loggingOut: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    loggingOut: getLoggingOut(state),
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedPage);
