import React from 'react';
import { NavLink } from 'react-router-dom';
import { Divider, Dropdown, Header, Icon, Image } from 'semantic-ui-react';

function UserDropdown({
    email,
    loggingOut,
    member,
    userProfileImage,
    logoutAction,
    memberFetching,
}) {
    const renderlogoutIcon = () =>
        loggingOut ? <Icon loading name="spinner" /> : <Icon name="sign out" />;

    const username = email.split('@hhc-duesseldorf.de')[0];

    const DropdownLabel = (
        <>
            <Image
                src={userProfileImage}
                size="mini"
                className="circular"
                centered
                style={{ marginRight: '0.5rem', width: '2rem' }}
            />
            {member && (!memberFetching || (member.first_name && member.last_name))
                ? `${member.first_name} ${member.last_name}`
                : username}
        </>
    );

    return (
        <Dropdown item style={{ marginLeft: 'auto' }} className="right" trigger={DropdownLabel}>
            <Dropdown.Menu>
                <Dropdown.Item
                    text="Profil"
                    icon="user"
                    as={NavLink}
                    to={`/member/${member?.id}`}
                    disabled={!member}
                />
                <Dropdown.Item
                    text="E-Mail Signatur"
                    icon="mail"
                    as={NavLink}
                    to="/user/signature"
                    disabled={!member}
                />
                <Dropdown.Item
                    text="Passwort ändern"
                    icon="lock"
                    as={NavLink}
                    to="/user/changePassword"
                />
                <Divider />
                <Dropdown.Item text="Abmelden" icon={renderlogoutIcon()} onClick={logoutAction} />
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UserDropdown;
