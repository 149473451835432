import React, { FC } from 'react';
import { Tab } from 'semantic-ui-react';

import ViewContact from 'components/view/ViewContact';
import ViewMember from 'components/view/ViewMember';
import ViewMemberContactInformation from 'components/view/ViewMemberContactInformation';
import ViewMemberEducation from 'components/view/ViewMemberEducation';
import { Member } from '../memberTypes';

interface MemberDetailsProps {
    member: Member;
}

const MemberDetails: FC<MemberDetailsProps> = ({ member }) => {
    const renderContact = () => {
        return <ViewContact contact={member} />;
    };

    const renderContactInformationTab = () => (
        <Tab.Pane>
            <ViewMemberContactInformation member={member} />
        </Tab.Pane>
    );

    const renderMemberTab = () => (
        <Tab.Pane>
            <ViewMember member={member} />
        </Tab.Pane>
    );

    const renderEducationTab = () => (
        <Tab.Pane>
            <ViewMemberEducation educations={member.educations} />
        </Tab.Pane>
    );

    const getPanes = () => {
        const panes = [
            { menuItem: 'Kontaktdaten', render: renderContactInformationTab },
            { menuItem: 'Mitgliedschaft', render: renderMemberTab },
            { menuItem: 'Ausbildung', render: renderEducationTab },
        ];
        return panes;
    };

    return (
        <React.Fragment>
            {renderContact()}
            <Tab
                menu={{ secondary: true, pointing: true, className: 'scrollable' }}
                panes={getPanes()}
            />
        </React.Fragment>
    );
};

export default MemberDetails;
