import NotFoundPage from 'components/Layout/NotFoundPage';
import LoginPage from 'features/auth/LoginPage';
import OverviewPage from 'features/overview/OverviewPage';
// import ContactsPage from 'features/contacts/ContactsPage';
import MemberPage from 'features/member/MemberPage';
import ChangePassword from 'features/user/ChangePassword';
// import ResetPassword from 'features/user/ResetPassword';
// import RequestPasswordReset from 'features/user/RequestPasswordReset';
import SignatureGenerator from 'features/signature/SignatureGenerator';
import Geburtstagskalender from 'features/calendar/Geburtstagskalender';
import UsersPage from 'features/administration/users/UsersPage';
import RolesPage from 'features/administration/roles/RolesPage';
import RightsPage from 'features/administration/rights/RightsPage';
import { RIGHTS } from 'utilities/useUserRights';

const routes = [
    {
        path: '/',
        component: OverviewPage,
        exact: true,
    },
    {
        path: '/login',
        component: LoginPage,
        exact: true,
    },
    // {
    //     path: '/contacts',
    //     component: ContactsPage,
    //     isPrivate: true,
    //     exact: true,
    //     restrictions: 'readAllContacts',
    // },
    {
        path: '/member/geburtstagskalender',
        component: Geburtstagskalender,
        isPrivate: true,
        exact: true,
        restrictions: [RIGHTS.readAllMember],
    },
    {
        path: '/member/:memberId?',
        component: MemberPage,
        isPrivate: true,
        exact: true,
        restrictions: [RIGHTS.readAllMember],
    },
    {
        path: '/user/changePassword',
        component: ChangePassword,
        isPrivate: true,
        exact: true,
    },
    // {
    //     path: '/user/resetPassword',
    //     component: RequestPasswordReset,
    //     exact: true,
    // },
    // {
    //     path: '/user/resetPassword/:id',
    //     component: ResetPassword,
    //     exact: true,
    // },
    {
        path: '/user/signature',
        component: SignatureGenerator,
        isPrivate: true,
    },
    {
        path: '/administration/users',
        isPrivate: true,
        component: UsersPage,
        restrictions: [RIGHTS.editUsersRolesRights],
    },
    {
        path: '/administration/roles',
        isPrivate: true,
        component: RolesPage,
        restrictions: [RIGHTS.editUsersRolesRights],
    },
    {
        path: '/administration/rights',
        isPrivate: true,
        component: RightsPage,
        restrictions: [RIGHTS.editUsersRolesRights],
    },
    {
        component: NotFoundPage,
        isPrivate: true,
    },
];

export default routes;
