import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';

import { openModal } from 'components/Modal';

const RolesListEntry = (props) => {
    return (
        <tr onClick={() => props.openModal('roleCreateEdit', props.data)} className="hover-pointer">
            <td>{props.data.id}</td>
            <td>{props.data.rolename}</td>
            <td style={{ width: '2rem' }}>
                <Dropdown
                    icon={<Icon name="ellipsis vertical" color="grey" size="large" />}
                    floating
                    pointing
                    className="right"
                >
                    <Dropdown.Menu>
                        <Dropdown.Item
                            icon="edit"
                            text="Bearbeiten"
                            onClick={() => props.openModal('roleCreateEdit', props.data)}
                        />
                        <Dropdown.Item
                            icon="delete"
                            text="Löschen"
                            onClick={() => props.openModal('roleDelete', props.data)}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
};

RolesListEntry.propTypes = {
    data: PropTypes.object,
    openModal: PropTypes.func.isRequired,
};

export default connect(null, { openModal })(RolesListEntry);
