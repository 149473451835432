export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_MEMBER_FOR_USER = 'FETCH_MEMBER_FOR_USER';
export const FETCH_MEMBER_FOR_USER_SUCCESS = 'FETCH_MEMBER_FOR_USER_SUCCESS';
export const FETCH_MEMBER_FOR_USER_FAILURE = 'FETCH_MEMBER_FOR_USER_FAILURE';

export const FETCH_ROLES_FOR_USER = 'FETCH_ROLES_FOR_USER';
export const FETCH_ROLES_FOR_USER_SUCCESS = 'FETCH_ROLES_FOR_USER_SUCCESS';
export const FETCH_ROLES_FOR_USER_FAILURE = 'FETCH_ROLES_FOR_USER_FAILURE';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
