import * as types from './usersTypes';

const initialState = {
    usersList: [],
    fetching: false,
    error: '',
    fetchingUserRoles: false,
    errorUserRoles: '',
    creating: false,
    editing: false,
    deleting: false,
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_USERS:
            return {
                ...state,
                fetching: true,
            };
        case types.FETCH_USERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                usersList: action.payload,
            };
        case types.FETCH_USERS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: action.error && action.error.data,
            };
        case types.FETCH_MEMBER_FOR_USER:
            return {
                ...state,
                fetching: true,
                initialMember: action.payload,
            };

        case types.FETCH_ROLES_FOR_USER:
            return {
                ...state,
                fetchingUserRoles: true,
                errorUserRoles: '',
            };
        case types.FETCH_ROLES_FOR_USER_SUCCESS:
            return {
                ...state,
                fetchingUserRoles: false,
                usersList: state.usersList.map((user) => {
                    if (user.id === action.payload.user_id) {
                        user.user_roles = action.payload.user_roles;
                    }
                    return user;
                }),
                errorUserRoles: '',
            };
        case types.FETCH_ROLES_FOR_USER_FAILURE:
            return {
                ...state,
                fetchingUserRoles: false,
                errorUserRoles: action.error && action.error.data,
            };

        case types.CREATE_USER:
            return {
                ...state,
                creating: true,
            };
        case types.CREATE_USER_SUCCESS:
        case types.CREATE_USER_FAILURE:
            return {
                ...state,
                creating: false,
            };

        case types.EDIT_USER:
            return {
                ...state,
                editing: true,
            };
        case types.EDIT_USER_SUCCESS:
        case types.EDIT_USER_FAILURE:
            return {
                ...state,
                editing: false,
            };

        case types.DELETE_USER:
            return {
                ...state,
                deleting: true,
            };
        case types.DELETE_USER_SUCCESS:
        case types.DELETE_USER_FAILURE:
            return {
                ...state,
                deleting: false,
            };

        default:
            return state;
    }
};

export default users;
