import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Image, Header, Label, List, Loader, Select } from 'semantic-ui-react';
import moment from 'moment';

import Calendar from './Calendar';
import { translatePrefixIntoImage } from 'utilities/index';
import { fetchMember } from 'features/member/memberActions';
import { getMemberList, getMemberFetching } from 'features/member/memberSelector';

const DATE_FILTER = {
    ALL: { value: 'ALL', title: 'Alle Geburtstage' },
    LAST_THURSDAY: { value: 'LAST_THURSDAY', title: 'Seit letztem Donnerstag' },
    TODAY: { value: 'TODAY', title: 'Heute' },
    THIS_MONTH: { value: 'THIS_MONTH', title: 'Dieser Monat' },
    NEXT_MONTH: { value: 'NEXT_MONTH', title: 'Nächster Monat' },
    THIS_WEEK: { value: 'THIS_WEEK', title: 'Diese Woche' },
    NEXT_WEEK: { value: 'NEXT_WEEK', title: 'Nächste Woche' },
};

const MEMBER_FILTER = {
    ALL: { value: 'ALL', title: 'Alle Mitglieder' },
    OrdentlichesMitglied: {
        value: 'OrdentlichesMitglied',
        title: 'Ordentliches Mitglied',
    },
    Alumni: { value: 'Alumni', title: 'Alumni' },
};

class Geburtstagskalender extends Component {
    state = {
        dateFilter: DATE_FILTER.ALL,
        memberFilter: MEMBER_FILTER.ALL,
    };

    componentDidMount() {
        this.props.fetchMember();
    }

    isFetching = () => this.props.memberFetching;

    changeDateFilter = (e, props) => {
        this.setState({
            dateFilter: DATE_FILTER[props.value],
        });
    };

    changeMemberFilter = (e, props) => {
        this.setState({
            memberFilter: MEMBER_FILTER[props.value],
        });
    };

    memberFilteredByStatus = () =>
        this.props.member.filter((member) => {
            switch (this.state.memberFilter.value) {
                case MEMBER_FILTER.OrdentlichesMitglied.value:
                    return member.status.some((status) => {
                        return status.end === null && status.title === 'Active';
                    });
                case MEMBER_FILTER.Alumni.value:
                    return member.status.some((status) => {
                        return status.end === null && status.title === 'Alumni';
                    });
                case MEMBER_FILTER.ALL.value:
                default:
                    return true;
            }
        });

    memberFilteredAndSorted = () =>
        this.memberFilteredByStatus()
            .filter((member) => {
                const today = moment();
                const birthDate = moment(member?.birth_date);
                birthDate.year(today.year());

                switch (this.state.dateFilter.value) {
                    case DATE_FILTER.THIS_MONTH.value:
                        return birthDate.month() === today.month();
                    case DATE_FILTER.NEXT_MONTH.value:
                        return birthDate.month() === today.month() + 1;
                    case DATE_FILTER.THIS_WEEK.value:
                        return today.week() === birthDate.week();
                    case DATE_FILTER.NEXT_WEEK.value:
                        return today.week() + 1 === birthDate.week();
                    case DATE_FILTER.TODAY.value:
                        return birthDate.isSame(today, 'day');
                    case DATE_FILTER.LAST_THURSDAY.value:
                        const lastThursday =
                            today.weekday() <= 3
                                ? moment(today).weekday(-4)
                                : moment(today).weekday(3);
                        return birthDate.isBetween(lastThursday, today, null, '(]');
                    case DATE_FILTER.ALL.value:
                    default:
                        return true;
                }
            })
            .sort((a, b) => {
                const today = new Date();
                const aDate = new Date(a.member?.birth_date).setFullYear(today.getFullYear());
                const bDate = new Date(b.member?.birth_date).setFullYear(today.getFullYear());
                return aDate - bDate;
            });

    calendarTermine = () => {
        const thisYear = new Date().getFullYear();
        let termine = [];
        for (let i = -1; i <= 1; i++) {
            termine = termine.concat(
                this.props.member
                    .filter((member) => member.birth_date)
                    .map((member) => ({
                        date: thisYear + i + member.birth_date.slice(4),
                        title: (
                            <Label size="large" image>
                                <Image avatar src={translatePrefixIntoImage(member.salutation)} />
                                {member.first_name} {member.last_name}
                            </Label>
                        ),
                    }))
            );
        }
        return termine;
    };

    render() {
        const dateFilterOptions = Object.keys(DATE_FILTER).map((filter) => ({
            key: DATE_FILTER[filter].value,
            value: DATE_FILTER[filter].value,
            text: DATE_FILTER[filter].title,
        }));

        const memberFilterOptions = Object.keys(MEMBER_FILTER).map((filter) => ({
            key: MEMBER_FILTER[filter].value,
            value: MEMBER_FILTER[filter].value,
            text: MEMBER_FILTER[filter].title,
        }));

        return (
            <Grid centered stackable>
                <Grid.Column width={16}>
                    <Header as="h1" content="Geburtstagskalender" textAlign="center" />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Calendar termine={this.calendarTermine()} termineLabel="Geburtstage" />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Select
                        name="date-filter"
                        placeholder="Filter nach Datum"
                        onChange={this.changeDateFilter}
                        options={dateFilterOptions}
                        value={this.state.dateFilter.value}
                        style={{ marginRight: '1rem' }}
                    />
                    <Select
                        name="member-filter"
                        label="Filter nach Status"
                        onChange={this.changeMemberFilter}
                        options={memberFilterOptions}
                        value={this.state.memberFilter.value}
                    />
                    <List relaxed style={{ overflowY: 'auto' }}>
                        <Loader
                            active={this.isFetching()}
                            inline
                            style={{ margin: '0.75rem 2rem' }}
                        />
                        {!this.isFetching() &&
                            (this.memberFilteredAndSorted().length ? (
                                this.memberFilteredAndSorted().map((member) => (
                                    <List.Item key={member.id}>
                                        <Label
                                            size="large"
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <Image
                                                avatar
                                                src={translatePrefixIntoImage(member.salutation)}
                                                style={{ marginRight: '1rem' }}
                                            />
                                            {member.first_name} {member.last_name}
                                            <Label.Detail style={{ marginLeft: 'auto' }}>
                                                {new Date(member.birth_date).toLocaleDateString(
                                                    'de',
                                                    {
                                                        day: '2-digit',
                                                        month: 'short',
                                                    }
                                                )}
                                            </Label.Detail>
                                        </Label>
                                    </List.Item>
                                ))
                            ) : (
                                <span style={{ marginLeft: '1rem' }}>Keine Geburtstage</span>
                            ))}
                    </List>
                </Grid.Column>
            </Grid>
        );
    }
}

Geburtstagskalender.propTypes = {
    fetchMember: PropTypes.func.isRequired,
    memberFetching: PropTypes.bool,
    member: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    member: getMemberList(state),
    memberFetching: getMemberFetching(state),
});

export default connect(mapStateToProps, {
    fetchMember,
})(Geburtstagskalender);
