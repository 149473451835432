import React from 'react';
import PropTypes from 'prop-types';

const FlexContainer = ({
    children,
    vAlign,
    hAlign,
    direction,
    reverse,
    inline,
    width,
    style,
    ...props
}) => {
    const justifyContent = (justifyContentProp) => {
        switch (justifyContentProp) {
            case 'left':
                return 'flex-start';
            case 'right':
                return 'flex-end';
            default:
                return justifyContentProp;
        }
    };

    const alignItems = (alignItemsProp) => {
        switch (alignItemsProp) {
            case 'top':
                return 'flex-start';
            case 'bottom':
                return 'flex-end';
            default:
                return alignItemsProp;
        }
    };

    return (
        <div
            style={{
                width: width,
                display: inline ? 'flex-inline' : 'flex',
                flexDirection: direction + (reverse ? 'reverse' : ''),
                justifyContent:
                    direction === 'row' ? justifyContent(hAlign) : justifyContent(vAlign),
                alignItems: direction === 'row' ? alignItems(vAlign) : alignItems(hAlign),
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
};

FlexContainer.propTypes = {
    children: PropTypes.node,
    vAlign: PropTypes.string,
    hAlign: PropTypes.string,
    direction: PropTypes.string,
    reverse: PropTypes.bool,
    inline: PropTypes.bool,
    width: PropTypes.string,
    style: PropTypes.object,
};

FlexContainer.defaultProps = {
    hAlign: 'left',
    vAlign: 'top',
    direction: 'row',
    reverse: false,
    inline: false,
    width: '100%',
};

export default FlexContainer;
