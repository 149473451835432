export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLES = 'CREATE_ROLES';
export const EDIT_ROLE = 'EDIT_ROLE';
export const EDIT_ROLES = 'EDIT_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLES = 'DELETE_ROLES';
