/**
 * Parse a Date object into a string (us: yyyy-mm-dd, de: dd.mm.yyyy)
 */
export const formatDate = (date: Date, locale: string = 'de'): string => {
    const yyyy = date.getFullYear();
    const m = date.getMonth() + 1;
    const mm = m < 10 ? '0' + m : m;
    const d = date.getDate();
    const dd = d < 10 ? '0' + d : d;

    switch (locale) {
        case 'de':
            return `${dd}.${mm}.${yyyy}`;
        case 'us':
            return `${yyyy}-${mm}-${dd}`;
        default:
            return date.toDateString();
    }
};

/**
 * Check if date1 and date2 are euqal
 */
export const datesEqual = (date1: Date, date2: Date): boolean => {
    const date1WithoutTime = new Date(date1.setHours(0, 0, 0, 0));
    const date2WithoutTime = new Date(date2.setHours(0, 0, 0, 0));
    return date1WithoutTime.getTime() === date2WithoutTime.getTime();
};
