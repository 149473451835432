import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';

import { getMemberForCurrentUser, getUserContactId, getUsername } from 'features/auth/AuthSelector';
import { getUserProfileImage } from 'features/user/userSelector';
import { logout } from 'features/auth/authActions';
import UserDropdown from './UserDropdown';
import { getMember, getMemberFetching } from 'features/member/memberSelector';
import { fetchMember } from '../../features/member/memberActions';
import { RIGHTS, useUserRights } from 'utilities/useUserRights';

const MainMenu = (props) => {
    const userRights = useUserRights();

    useEffect(() => {
        if (
            props.isAuthenticated &&
            props.userMemberId !== null &&
            !props.memberFetching &&
            !props.memberList.length
        ) {
            props.fetchMember();
        }
    }, []);

    return (
        <Fragment>
            <Menu.Item as={NavLink} to="/" exact>
                <Icon name="home" />
                Übersicht
            </Menu.Item>
            {userRights[RIGHTS.readAllMember] && (
                <Dropdown text="Mitglieder" item>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            text="Mitgliederliste"
                            icon="group"
                            as={NavLink}
                            to="/member"
                            exact
                        />
                        <Dropdown.Item
                            text="Geburtstagskalender"
                            icon="calendar"
                            as={NavLink}
                            to="/member/geburtstagskalender"
                        />
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {userRights[RIGHTS.editUsersRolesRights] && (
                <Dropdown item text="Administration">
                    <Dropdown.Menu>
                        <Dropdown.Item text="Benutzer" as={NavLink} to="/administration/users" />
                        <Dropdown.Item text="Rollen" as={NavLink} to="/administration/roles" />
                        <Dropdown.Item text="Rechte" as={NavLink} to="/administration/rights" />
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {props.isAuthenticated ? (
                <UserDropdown
                    email={props.username}
                    loggingOut={props.loggingOut}
                    userProfileImage={props.userProfileImage}
                    logoutAction={props.logoutAction}
                    member={props.member}
                    memberFetching={props.memberFetching}
                />
            ) : (
                <Menu.Item
                    as={NavLink}
                    to="/login"
                    style={{ marginLeft: 'auto' }}
                    className="right"
                >
                    Login
                </Menu.Item>
            )}
        </Fragment>
    );
};

MainMenu.propTypes = {
    toggleSidebar: PropTypes.func,
    loggingOut: PropTypes.bool,
    logoutAction: PropTypes.func,
    username: PropTypes.string,
    userProfileImage: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    member: PropTypes.object,
    memberFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    username: getUsername(state),
    userProfileImage: getUserProfileImage(state),
    member: getMemberForCurrentUser(state),
    userMemberId: getUserContactId(state),
    memberList: getMember(state),
    loggingOut: state.auth.loggingOut,
    memberFetching: getMemberFetching(state),
});

export default withRouter(
    connect(mapStateToProps, { logoutAction: logout, fetchMember })(MainMenu)
);
