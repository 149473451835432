export const FETCH_RIGHTS = 'FETCH_RIGHTS';
export const FETCH_RIGHTS_SUCCESS = 'FETCH_RIGHTS_SUCCESS';
export const FETCH_RIGHTS_FAILURE = 'FETCH_RIGHTS_FAILURE';

export const FETCH_DESCRIPTION_FOR_RIGHT = 'FETCH_DESCRIPTION_FOR_RIGHT';
export const FETCH_DESCRIPTION_FOR_RIGHT_SUCCESS = 'FETCH_DESCRIPTION_FOR_RIGHT_SUCCESS';
export const FETCH_DESCRIPTION_FOR_RIGHT_FAILURE = 'FETCH_DESCRIPTION_FOR_RIGHT_FAILURE';

export const CREATE_RIGHT = 'CREATE_RIGHT';
export const CREATE_RIGHTS = 'CREATE_RIGHTS';
export const EDIT_RIGHT = 'EDIT_RIGHT';
export const EDIT_RIGHTS = 'EDIT_RIGHTS';
export const DELETE_RIGHT = 'DELETE_RIGHT';
export const DELETE_RIGHTS = 'DELETE_RIGHTS';

export const UPDATE_RIGHT = 'UPDATE_RIGHT';
