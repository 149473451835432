import { translateMemberRessort, translateMemberPositionTitle } from 'utilities';

const signature = (member, highestCurrentPosition, vorstand) => `
  <style>
    /* ------------------------------ */
    /*** Client-specific CSS Styles ***/
    /* ------------------------------ */

    /* source: https://templates.mailchimp.com/development/css/client-specific-styles/ */

    /* Outlook.com / Hotmail */
    .ExternalClass {
      width: 100%;
    }

    .ExternalClass,
    .ExternalClass p,
    .ExternalClass span,
    .ExternalClass font,
    .ExternalClass td,
    .ExternalClass div {
      line-height: 100%;
    }

    /* Outlook 2007 / 2010 / 2013 */
    #outlook a {
      padding: 0;
    }

    #email-container table {
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }

    #email-container img {
      -ms-interpolation-mode: bicubic;
    }

    body {
      /* OSX / iOS */
      -webkit-text-size-adjust: 100%;
      /* Windows Mobile */
      -ms-text-size-adjust: 100%;
    }

    /* --------- */
    /*** RESET ***/
    /* --------- */

    /* source: https://templates.mailchimp.com/development/css/reset-styles/ */

    body {
      margin: 0;
      padding: 0;
    }

    #email-container img {
      border: 0 none;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
    }

    #email-container a img {
      border: 0 none;
    }

    .imageFix {
      display: block;
    }

    #email-container table,
    #email-container td {
      border-collapse: collapse;
    }

    #email-container table.no-collapse,
    #email-container table.no-collapse > tbody > tr > td,
    #email-container td.no-collapse {
      border-collapse: separate !important;
    }

    #body-table {
      margin: 0;
      padding: 0;
    }

    /* ----------------- */
    /*** Custom Styles ***/
    /* ----------------- */

    /* COLORS
     * hhc-blue: #042a4f;
     * hhc-grey: #898c90;
     * light-grey: #ccc;
     */

    body,
    #body-table {
      font-size: 14px;
      font-family: "Arial", "Helvetica", sans-serif;
    }

    #email-container {
      width: 100%;
      max-width: 600px;
    }

    a, a:link, a:visited {
      color: #042a4f;
      text-decoration: none;
    }

    a:hover, a:active, a:focus {
      color: #898c90;
      text-decoration: underline;
    }

    .spacer {
      font-size: 10px;
      line-height: 4px;
    }

    .infos-column {
      padding-right: 10px;
    }

    .image-column {
      padding-left: 10px;
      border-left: 1px solid #ccc;
    }

    .contact-table td {
      padding: 4px 0 4px 0;
    }

    .name {
      color: #042a4f;
      font-size: 18px;
      font-weight: bold;
    }

    .position {
      font-weight: bold;
    }

    .footer-text {
      font-size: 10px;
      line-height: 10px;
      color: #898c90;
    }

    /* Mobile View */
    @media (max-width: 480px) {
      .infos-column,
      .image-column {
        display: block !important;
        width: 100% !important;
        padding: 0;
        border: none;
      }

      .image-column td {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 14px !important;
        padding-bottom: 14px !important;
      }
    }
  </style>
  <table border="0" cellpadding="0" cellspacing="0" id="body-table">
    <tr>
      <td align="left" valign="top">
        <table border="0" cellpadding="0" cellspacing="0" width="600" id="email-container">
          <tr>
            <td align="center" valign="top">
              <!-- CONTENT -->
              <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%">

                <tr>
                  <td width="340" align="center" valign="top" class="infos-column">
                    <!-- INFOS -->
                    <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%">
                      <tr>
                        <td align="left" valign="top">
                          <span class="name">${member.first_name} ${member.last_name}</span>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" valign="top">
                          <span class="position">${highestCurrentPosition}</span>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" valign="top"><span class="spacer">&nbsp;</span></td>
                      </tr>
                      <tr>
                        <td align="center" valign="top">
                          <table border="0" cellpadding="4" cellspacing="0" height="100%"
                            width="100%" class="no-collapse contact-table">
                            <tr>
                              <td width="10%" align="left" valign="top">Mobil:&nbsp;</td>
                              <td align="left" valign="top">
                                <a href="tel:${member.phone}">${member.phone}</a>
                              </td>
                            </tr>
                            <tr>
                              <td width="10%" align="left" valign="top">E-Mail:&nbsp;</td>
                              <td align="left" valign="top">
                                <a href="mailto:${member.mail_hhc}">${member.mail_hhc}</a>
                              </td>
                            </tr>
                            <tr>
                              <td width="10%" align="left" valign="top">Website:&nbsp;</td>
                              <td align="left" valign="top">
                                <a href="https://hhc-duesseldorf.de">www.hhc-duesseldorf.de</a>
                              </td>
                            </tr>
                            <tr>
                              <td width="10%" align="left" valign="top">Adresse:&nbsp;</td>
                              <td align="left" valign="top">
                                <span>K&ouml;nigsallee 27,</span><br/>
                                <span>40212 D&uuml;sseldorf</span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td align="left" valign="center" class="image-column">
                    <!-- IMAGE -->
                    <table border="0" cellpadding="10" cellspacing="0" height="100%" width="100%"
                      class="no-collapse">
                      <tr>
                        <td align="left" valign="center">
                          <img
                            width="220"
                            height="85"
                            src="https://hhc-duesseldorf.de/wp-content/uploads/Logo-Vektor-Blau-PNG-72.png"
                            alt="HHC Logo"
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top"><span class="spacer">&nbsp;</span></td>
                </tr>
                <tr>
                  <td colspan="2" align="center" valign="top">
                    <!-- FOOTER -->
                    <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%"
                      class="footer-table">
                      <tr class="spaceUnder">
                        <td align="left" valign="top">
                          <span class="footer-text">Heinrich Heine Consulting e.V. - K&ouml;nigsallee 27, 40212 D&uuml;sseldorf, Vereinsregisternummer: 9926</span>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" valign="top">
                          <span class="footer-text">
                            Mitglieder des Vorstands: 
                            ${[vorstand.first, vorstand.second, vorstand.third, vorstand.finance]
                                .map(
                                    (v) =>
                                        v.firstName +
                                        ' ' +
                                        v.lastName +
                                        ' (' +
                                        translateMemberPositionTitle(v.position) +
                                        ')'
                                )
                                .join(', ')}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
`;

export default signature;
