import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import fieldLevelValidation from '../../utilities/fieldLevelValidation';
import Input from '../Input';
import Select from '../Select';
import {
    datetimeToDateFieldFormatter,
    getAllMemberPositions,
    translateMemberPositionTitle,
} from 'utilities';

const EditPosition = (props) => {
    const namePrefix =
        props.name !== undefined && props.index !== undefined
            ? props.name + '.' + props.index + '.'
            : 'positions.';

    const positionOptions = getAllMemberPositions().map((position) => ({
        key: position,
        value: position,
        text: translateMemberPositionTitle(position),
    }));

    return (
        <Form.Group>
            <Field
                search
                width={8}
                name={namePrefix + 'title'}
                component={Select}
                label="Position"
                options={positionOptions}
                validate={fieldLevelValidation.required}
                required
                disabled={props.disabled}
            />
            <Field
                width={4}
                name={namePrefix + 'begin'}
                component={Input}
                type="date"
                label="von"
                validate={fieldLevelValidation.required}
                required
                disabled={props.disabled}
                format={datetimeToDateFieldFormatter}
            />
            <Field
                width={4}
                name={namePrefix + 'end'}
                component={Input}
                type="date"
                label="bis"
                disabled={props.disabled}
                format={datetimeToDateFieldFormatter}
            />
        </Form.Group>
    );
};

EditPosition.propTypes = {
    index: PropTypes.number,
    meta: PropTypes.object,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default EditPosition;
