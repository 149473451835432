import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'utilities';
import './filterTagStyles.scss';

const FilterTag = ({ name, value, filterAction, label, children }) => {
    const filterHandler = () => {
        const data = { name, value };
        filterAction(data);
    };

    if (!value) {
        return null;
    }

    return (
        <div
            className={`filter-tag-${name}-${value}`}
            onClick={filterHandler}
            title={`Filtern nach ${capitalize(name)}: ${label}`}
        >
            {children ? children : label}
        </div>
    );
};

FilterTag.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    filterAction: PropTypes.func.isRequired,
    label: PropTypes.string,
    children: PropTypes.array,
};

export default FilterTag;
