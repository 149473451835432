import { getMemberForId } from 'features/member/memberSelector';

const getAuthData = (state) => state.auth;

export const getApiToken = (state) => getAuthData(state).apiToken;

export const getUsername = (state) => getAuthData(state).username;

export const isAuthenticated = (state) => (getApiToken(state) && getUsername(state) ? true : false);

export const getLoggingOut = (state) => state.auth.loggingOut;

export const getUserRights = (state) => state.auth.rights;

export const getUserContactId = (state) => state.auth.memberId;

export const getMemberForCurrentUser = (state) => {
    const memberId = getUserContactId(state);
    if (memberId) {
        return getMemberForId(state, memberId);
    }
    return null;
};

export default {
    isAuthenticated,
};
