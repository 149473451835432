import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { useUserRights } from 'utilities/useUserRights';
import { validateApiToken } from './authActions';
import AuthSelector, { getApiToken, getUsername } from './AuthSelector';
import RestrictedPage from './RestrictedPage';

const PrivateRoute = (props) => {
    const userRights = useUserRights();

    useEffect(() => {
        props.validateApiToken(props.username, props.apiToken);
    }, []);

    function isAllowed() {
        if (props.restrictions !== undefined && props.restrictions.length > 0) {
            return props.restrictions.some((right) => userRights[right]);
        }
        return true;
    }

    return props.isAuthenticated ? (
        <Route path={props.path} component={isAllowed() ? props.component : RestrictedPage} />
    ) : (
        <Redirect
            to={{
                pathname: '/login',
                state: { previous: props.path },
            }}
        />
    );
};

PrivateRoute.propTypes = {
    path: PropTypes.string,
    component: PropTypes.elementType,
    isAuthenticated: PropTypes.bool,
    validateApiToken: PropTypes.func,
    username: PropTypes.string,
    apiToken: PropTypes.string,
    restrictions: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state, ownProps) => ({
    isAuthenticated: AuthSelector.isAuthenticated(state),
    username: getUsername(state),
    apiToken: getApiToken(state),
});

export default connect(mapStateToProps, { validateApiToken })(PrivateRoute);
