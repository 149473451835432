import FilterableTableList from 'components/lists/FilterableTableList';
import SortButton from 'components/lists/SortButton/SortButton';
import React, { FC } from 'react';
import useMediaQueries from 'utilities/mediaQueriesHook';
import { Filter } from 'utilities/types';
import { Member } from '../memberTypes';
import MemberListEntry from './MemberListEntry/MemberListEntry';

interface MemberListProps {
    member: Member[];
    searchValue: string;
    sortValue: string;
    sortDirection: string;
    filter: Filter;
    fetching: boolean;
    setFilter: (filter: Filter) => void;
}

const MemberList: FC<MemberListProps> = ({
    member,
    searchValue,
    sortValue,
    sortDirection,
    filter,
    fetching,
    setFilter,
}) => {
    const { isMobile } = useMediaQueries();

    const header = [
        null,
        <>
            <SortButton
                {...{ sortValue, sortDirection, setFilter }}
                name="Vorname"
                value="first_name"
            />
            <SortButton
                {...{ sortValue, sortDirection, setFilter }}
                name="Nachname"
                value="last_name"
            />
        </>,
        <SortButton {...{ sortValue, sortDirection, setFilter }} name="ID" value="id" />,
        <SortButton {...{ sortValue, sortDirection, setFilter }} name="Status" value="status" />,
        <SortButton {...{ sortValue, sortDirection, setFilter }} name="Ressort" value="ressort" />,
        <SortButton
            {...{ sortValue, sortDirection, setFilter }}
            name="Position"
            value="position"
        />,
        null,
    ];

    return (
        <FilterableTableList
            component={MemberListEntry}
            items={member}
            fetching={fetching}
            searchValue={searchValue}
            searchRange={['first_name', 'last_name']}
            sortDirection={sortDirection}
            sortValue={sortValue}
            filter={filter}
            header={isMobile ? null : header}
            columnWidth={['42px', 'auto', 'auto', 'auto', 'auto', '2rem']}
            listType="divided dashed striped"
            title="Suchergebnisse"
        />
    );
};

export default MemberList;
