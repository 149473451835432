export enum MemberPositionTitles {
    Member = 'Member',
    HeadOf_NW = 'HeadOf_NW',
    HeadOf_QM = 'HeadOf_QM',
    HeadOf_FI = 'HeadOf_FI',
    HeadOf_IT = 'HeadOf_IT',
    HeadO_HR = 'HeadO_HR',
    HeadOf_MA = 'HeadOf_MA',
    HeadOf_SA = 'HeadOf_SA',
    CFO = 'CFO',
    COO = 'COO',
    CEO = 'CEO',
    COO_2 = 'COO-2',
}

export enum MemberExpertTeamPositions {
    Member = 'Member',
    TeamLead = 'TeamLead',
}

export enum MemberExpertTeamTitles {
    SA = 'SA',
    MA = 'MA',
    NW = 'NW',
    HR = 'HR',
    IT = 'IT',
    FI = 'FI',
    QM = 'QM',
}

export enum MemberStatusTitles {
    Applicant = 'Applicant',
    Trainee = 'Trainee',
    Active = 'Active',
    Alumni = 'Alumni',
    Out = 'Out',
}

export enum MemberRessortNameShorts {
    SA = 'SA',
    MA = 'MA',
    HR = 'HR',
    IT = 'IT',
    FI = 'FI',
    QM = 'QM',
    NW = 'NW',
}

export enum EducationStatus {
    ACTIVE = 'ACTIVE',
    DONE = 'DONE',
    CANCELED = 'CANCELED',
}
