export const types = {
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
    SET_MOBILE: 'SET_MOBILE',
};

export const toggleSidebar = () => ({
    type: types.TOGGLE_SIDEBAR,
});

export const setMobile = (isMobile) => ({
    type: types.SET_MOBILE,
    isMobile,
});
