import { Member, MemberPosition, MemberRessort, MemberStatus } from '../memberTypes';

/**
 * Get the status without an end and the latest begin.
 * Returns null if there is no status or no status without an end.
 *
 * NOTE: There should only be one position without an end
 *       but someone might have forgeotten to update the end
 *       when setting a new position. Therefore we also check the begin.
 */
export const getCurrentMemberStatus = (member: Member): MemberStatus | null => {
    let currentMemberStatus: MemberStatus | null = null;

    member.status.forEach((status) => {
        if (
            !status.end &&
            (!currentMemberStatus || new Date(status.begin) > new Date(currentMemberStatus.begin))
        ) {
            currentMemberStatus = status;
        }
    });

    return currentMemberStatus;
};

/**
 * Get the ressorts without an end sorted by the begin (latest -> earliest).
 */
export const getCurrentMemberRessorts = (member: Member): Array<MemberRessort> => {
    const currentRessorts = member.ressorts
        .filter((ressort) => !ressort.end)
        .sort((a, b) => new Date(b.begin).valueOf() - new Date(a.begin).valueOf());
    return currentRessorts;
};

/**
 * Get the positions without an end sorted by the begin (latest -> earliest).
 */
export const getCurrentMemberPositions = (member: Member): Array<MemberPosition> => {
    const currentPositions = member.positions
        .filter((ressort) => !ressort.end)
        .sort((a, b) => new Date(b.begin).valueOf() - new Date(a.begin).valueOf());
    return currentPositions;
};
