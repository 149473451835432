const usersData = (state) => state.users;

export const users = (state) => usersData(state).usersList;

export const usersFetching = (state) => usersData(state).fetching;
export const userCreating = (state) => usersData(state).creating;
export const userEditing = (state) => usersData(state).editing;
export const userDeleting = (state) => usersData(state).deleting;

export const userRolesByUserId = (state, userId) =>
    usersData(state).usersList.find((user) => user.id === userId)?.user_roles;

export const usersWithRolesAndMemberSelector = (state) => {
    const d = state.users.usersList?.map((user) => ({
        roles: state.roles.rolesList
            .filter((role) => role.users.includes(user.id))
            .map((role) => role.rolename),
        user,
        member: state.member.memberList.filter((member) => member.id === user.member_id)[0],
    }));
    return d;
};
