import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm /* Field */ } from 'redux-form';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';

import Form from 'components/Form';
import EditRole from './EditRole';

import * as ContactsSelector from 'features/contacts/contactsSelector';
import { fetchRights } from 'features/administration/rights/redux/rightsActions';
import * as RightsSelector from 'features/administration/rights/redux/rightsSelector';
import * as UsersSelector from 'features/administration/users/redux/usersSelector';
import { fetchUsers } from 'features/administration/users/redux/usersActions';
import { fetchRoles, createRole, editRole } from 'features/administration/roles/redux/rolesActions';

class RoleCreateEditForm extends Component {
    componentDidMount() {
        this.props.fetchRights();
        this.props.fetchUsers();
        this.props.fetchRoles();
    }

    onSubmit = (data) => {
        if (this.props.initialValues) {
            this.props.editRole(data);
        } else {
            this.props.createRole(data);
        }
        this.props.close();
        this.props.fetchRoles();
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <React.Fragment>
                {this.props.initialValues && <Header>ID: {this.props.initialValues.id}</Header>}
                <Form onSubmit={handleSubmit(this.onSubmit)}>
                    <EditRole
                        users={this.props.users}
                        rights={this.props.rights}
                        values={this.props.initialValues}
                    />
                </Form>
            </React.Fragment>
        );
    }
}

RoleCreateEditForm.propTypes = {
    handleSubmit: PropTypes.func,
    close: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    rights: PropTypes.array,
    fetchRights: PropTypes.func.isRequired,
    contacts: PropTypes.array,
};

const mapStateToProps = (state) => ({
    contacts: ContactsSelector.getContactsList(state),
    rights: RightsSelector.rights(state),
    users: UsersSelector.users(state),
});

const mapDispatchToProps = {
    fetchRights,
    fetchUsers,
    fetchRoles,
    editRole,
    createRole,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: 'roleCreateEdit',
    })(RoleCreateEditForm)
);
