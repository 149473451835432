import { Member } from 'features/member/memberTypes';
import React, { FC } from 'react';
import { generateRandomString } from 'utilities';
import { RIGHTS, useUserRights } from 'utilities/useUserRights';

interface ViewMemberContactInformationPropsType {
    member: Member;
}

const ViewMemberContactInformation: FC<ViewMemberContactInformationPropsType> = ({ member }) => {
    const { mail_hhc, mail_private, phone } = member;
    const userRights = useUserRights({ memberId: member.id });

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td width="5%">
                            <i className="ui phone icon"></i>
                        </td>
                        <td>{phone ? <a href={'tel:' + phone}>{phone}</a> : '-'}</td>
                    </tr>
                </tbody>
            </table>

            <div className="ui divider" />

            <table>
                <tbody>
                    <tr>
                        <td width="5%">
                            <i className="ui mail icon"></i>
                        </td>
                        <td width="20%">HHC</td>
                        <td>{mail_hhc ? <a href={'mailto:' + mail_hhc}>{mail_hhc}</a> : '-'}</td>
                    </tr>
                    <tr>
                        <td width="5%">
                            <i className="ui mail icon"></i>
                        </td>
                        <td width="20%">Privat</td>
                        <td>
                            {mail_private ? (
                                <a href={'mailto:' + mail_private}>{mail_private}</a>
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="ui divider" />

            <table>
                <tbody>
                    <tr>
                        <td width="5%">
                            <i className="ui home icon" />
                        </td>
                        <td>
                            {member.street && member.street_number && member.postcode ? (
                                userRights[RIGHTS.readAddressData] ||
                                userRights[RIGHTS.readOwnAddressData] ? (
                                    <div>
                                        {member.street} {member.street_number}
                                        <br />
                                        {member.postcode} {member.city}
                                    </div>
                                ) : (
                                    <div style={{ filter: 'blur(4px)', userSelect: 'none' }}>
                                        {generateRandomString(5, 15)} {generateRandomString(1, 4)}
                                        <br />
                                        {generateRandomString(5, 5)} {generateRandomString(4, 10)}
                                    </div>
                                )
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="ui divider" />

            <table>
                <tbody>
                    <tr>
                        <td width="5%">
                            <i className="ui instagram icon"></i>
                        </td>
                        <td>
                            {member.link_instagram ? (
                                <a
                                    href={'https://www.instagram.com/' + member.link_instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    @{member.link_instagram}
                                </a>
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td width="5%">
                            <i className="ui linkedin icon"></i>
                        </td>
                        <td>
                            {member.link_linkedin ? (
                                <a
                                    href={member.link_linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {member.link_linkedin}
                                </a>
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td width="5%">
                            <i className="ui xing icon"></i>
                        </td>
                        <td>
                            {member.link_xing ? (
                                <a
                                    href={member.link_xing}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {member.link_xing}
                                </a>
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td width="5%">
                            <i className="ui facebook icon"></i>
                        </td>
                        <td>
                            {member.link_facebook ? (
                                <a
                                    href={member.link_facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {member.link_facebook}
                                </a>
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td width="5%">
                            <i className="ui itunes note icon"></i>
                        </td>
                        <td>
                            {member.username_tiktok ? (
                                <a
                                    href={'https://www.tiktok.com/@' + member.username_tiktok}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    @{member.username_tiktok}
                                </a>
                            ) : (
                                '-'
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ViewMemberContactInformation;
