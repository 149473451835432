import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';

import UserCreateEditForm from './UserCreateEditForm';

const UserCreateModal = (props) => (
    <React.Fragment>
        <Modal.Header>Benutzer {props.data ? 'bearbeiten' : 'hinzufügen'}</Modal.Header>
        <Modal.Content>
            <UserCreateEditForm closeModal={props.closeModal} />
        </Modal.Content>
        <Modal.Actions>
            <Button
                primary
                content={props.data ? 'Speichern' : 'Hinzufügen'}
                icon={props.data ? 'edit' : 'add user'}
                onClick={() => props.submit('userCreateEdit')}
            />
            <Button content="Schließen" onClick={props.closeModal} />
        </Modal.Actions>
    </React.Fragment>
);

UserCreateModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
};

export default connect(null, { submit })(UserCreateModal);
