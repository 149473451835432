import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Container, Dimmer, Image, Menu, Sidebar } from 'semantic-ui-react';
import styled from 'styled-components';

import { toggleSidebar, setMobile } from './redux/layoutActions';
import { sidebarIsVisible, isMobile } from './redux/layoutSelector';
import { isAuthenticated } from '../../features/auth/AuthSelector';
import MainMenu from './MainMenu';
import LogoImg from 'assets/img/Logo-PNG-Blau-72.png';

import 'semantic-ui-css/semantic.min.css';
import 'styling/main.scss';
import './layoutStyles.scss';

const Brand = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem;
    letter-spacing: 0.1em;
    color: var(--primary);
`;

const ToggleSidebarButton = styled(Button)`
    &.ui.button {
        margin-left: auto;
        padding: 0;
        font-size: 1.5rem;
        background-color: transparent;
        border: none;
        box-shadow: none !important;
    }
`;

const PageFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Footer = styled.footer`
    margin-top: auto;
    padding: 1rem;
    background-color: var(--lighter-color);
`;

class Layout extends Component {
    componentDidMount() {
        this.setMobile();
        window.addEventListener('resize', this.setMobile);
        this.sidebarNode = document.getElementById('mobile-sidebar');
        if (this.sidebarNode) {
            this.sidebarNode.addEventListener('click', this.closeSidebarOnItemClick);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setMobile);
        if (this.sidebarNode) {
            this.sidebarNode.removeEventListener('click', this.closeSidebarOnItemClick);
        }
    }

    setMobile = () => {
        this.props.setMobile(window.innerWidth < 768);
    };

    closeSidebarOnItemClick = (e) => {
        const _target = e.target;
        if (_target) {
            const isItem = _target.tagName === 'A' && _target.classList.contains('item');
            const parentItem = _target.closest('.item');
            if (isItem || (parentItem && parentItem.tagName === 'A')) {
                this.props.toggleSidebar();
            }
        }
    };

    render() {
        return (
            <Sidebar.Pushable>
                <Sidebar
                    animation="overlay"
                    visible={
                        this.props.isAuthenticated &&
                        this.props.isMobile &&
                        this.props.sidebarIsVisible
                    }
                    as={Menu}
                    vertical
                    id="mobile-sidebar"
                >
                    <Menu.Item header>
                        <Image
                            src={LogoImg}
                            width={140}
                            centered
                            style={{ margin: '1rem auto' }}
                            alt="Heinrich Heine Consulting e.V. - Next generation for today's business"
                        />
                    </Menu.Item>
                    <MainMenu isAuthenticated={this.props.isAuthenticated} />
                </Sidebar>
                <Sidebar.Pusher>
                    <PageFlexContainer>
                        <Menu as="nav" id="main-navbar">
                            <Container className="page-container">
                                {this.props.isMobile ? (
                                    <React.Fragment>
                                        <Brand>intern.HHC</Brand>
                                        <ToggleSidebarButton
                                            onClick={this.props.toggleSidebar}
                                            icon={this.props.sidebarIsVisible ? 'close' : 'bars'}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <MainMenu isAuthenticated={this.props.isAuthenticated} />
                                )}
                            </Container>
                        </Menu>
                        <Dimmer
                            active={this.props.isMobile && this.props.sidebarIsVisible}
                            onClickOutside={this.props.toggleSidebar}
                            style={{ minHeight: '100vh', zIndex: '1001' }}
                        />
                        <Container as="main" className="page-container" id="main-content">
                            {this.props.children}
                        </Container>
                        <Footer>
                            <Container className="page-container">
                                intern.hhc v{process.env.REACT_APP_VERSION}
                            </Container>
                        </Footer>
                    </PageFlexContainer>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    sidebarIsVisible: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    setMobile: PropTypes.func.isRequired,
};

export default withRouter(
    connect(
        (state) => ({
            isAuthenticated: isAuthenticated(state),
            sidebarIsVisible: sidebarIsVisible(state),
            isMobile: isMobile(state),
        }),
        {
            toggleSidebar,
            setMobile,
        }
    )(Layout)
);
