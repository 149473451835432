import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';

import ActiveFilterTagList from 'components/lists/FilterTag/ActiveFilterTagList';
import SearchInput from 'components/lists/SearchInput';
import { Modal, openModal } from 'components/Modal';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/reduxTypes';
import useMediaQueries from 'utilities/mediaQueriesHook';
import { RIGHTS, useUserRights } from 'utilities/useUserRights';
import MemberCreateModal from './components/MemberCreateModal';
import MemberDeleteModal from './components/MemberDeleteModal';
import MemberDetailsModal from './components/MemberDetailsModal';
import MemberEditModal from './components/MemberEditModal';
import MemberList from './components/MemberList';
import {
    fetchMember,
    setMemberSearchFilter,
    setMemberSearchValue,
    setMemberSortValue,
} from './memberActions';
import {
    getMemberCreating,
    getMemberDeleting,
    getMemberFetching,
    getMemberFilter,
    getMemberList,
    getMemberSortDirection,
    getMemberSortValue,
    getMemberUpdating,
    getSearchValue,
} from './memberSelector';

const MemberPage = () => {
    const { memberId: memberIdFromParams } = useParams<{ memberId: string }>();

    const {
        memberList,
        memberFetching,
        searchValue,
        sortValue,
        sortDirection,
        filter,
        creating,
        updating,
        deleting,
    } = useSelector((state: RootState) => ({
        memberList: getMemberList(state),
        memberFetching: getMemberFetching(state),
        searchValue: getSearchValue(state),
        sortValue: getMemberSortValue(state),
        sortDirection: getMemberSortDirection(state),
        filter: getMemberFilter(state),
        creating: getMemberCreating(state),
        updating: getMemberUpdating(state),
        deleting: getMemberDeleting(state),
    }));

    const dispatch = useDispatch();

    const { isMobile } = useMediaQueries();
    const userRights = useUserRights();

    useEffect(() => {
        loadData();
    }, []);

    // open memberDetails modal for id in url
    useEffect(() => {
        const memberId = parseInt(memberIdFromParams);
        if (memberId || memberId === 0) {
            const member = memberList.filter((member) => member.id === memberId)[0];
            console.log(member);
            if (member) {
                dispatch(openModal('memberDetails', member));
            }
        }
    }, [memberIdFromParams, memberList.length]);

    const loadData = async () => {
        await dispatch(fetchMember());
        //await fetchStatus();
    };

    const isFetching = () => memberFetching;

    return (
        <Grid centered stackable>
            {!isMobile && (
                <Grid.Column width={16}>
                    <Header as="h1" content="Mitglieder" textAlign="center" />
                </Grid.Column>
            )}
            {/* {!isMobile && (
                <Grid.Column width={4}>
                    <Segment>
                        <MemberFilter />
                    </Segment>
                </Grid.Column>
            )} */}
            <Grid.Column width={16}>
                <Segment.Group>
                    <Segment stacked>
                        <div style={{ display: 'flex' }}>
                            <SearchInput
                                style={{ flex: 'auto', marginRight: '0.25em' }}
                                searchValue={searchValue}
                                changeSearchValue={(search: string) =>
                                    dispatch(setMemberSearchValue(search))
                                }
                            />
                            <Fragment>
                                <Button
                                    basic
                                    icon="refresh"
                                    loading={isFetching()}
                                    onClick={loadData}
                                    title="Daten aktualisieren"
                                />
                                <Button
                                    basic
                                    icon="add user"
                                    onClick={() => dispatch(openModal('memberCreate'))}
                                    title="Neues Mitglied aus Kontakten hinzufügen"
                                    disabled={!userRights[RIGHTS.createMember]}
                                />
                            </Fragment>
                        </div>
                        <ActiveFilterTagList
                            filter={filter}
                            filterAction={(data) => dispatch(setMemberSearchFilter(data))}
                        />
                    </Segment>
                    <Segment>
                        <MemberList
                            member={memberList}
                            fetching={isFetching()}
                            searchValue={searchValue}
                            filter={filter}
                            sortValue={sortValue}
                            sortDirection={sortDirection}
                            setFilter={(sortValue) => dispatch(setMemberSortValue(sortValue))}
                        />
                    </Segment>
                </Segment.Group>
            </Grid.Column>
            <Modal name="memberDetails" component={MemberDetailsModal} />
            <Modal
                name="memberCreate"
                component={MemberCreateModal}
                loading={creating}
                closeOnDimmerClick={false}
            />
            <Modal
                name="memberEdit"
                component={MemberEditModal}
                restricted
                loading={updating}
                closeOnDimmerClick={false}
            />
            <Modal
                name="memberDelete"
                component={MemberDeleteModal}
                loading={deleting}
                closeOnDimmerClick={false}
            />
        </Grid>
    );
};

export default MemberPage;
