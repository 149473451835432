export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';

export const OPEN_CONTACT_DETAILS = 'OPEN_CONTACT_DETAILS';
export const CLOSE_CONTACT_DETAILS = 'CLOSE_CONTACT_DETAILS';
export const OPEN_CONTACT_CREATE = 'OPEN_CONTACT_CREATE';
export const CLOSE_CONTACT_CREATE = 'CLOSE_CONTACT_CREATE';
export const LOADED_CONTACT_CREATE = 'LOADED_CONTACT_CREATE';
export const OPEN_CONTACT_EDIT = 'OPEN_CONTACT_EDIT';
export const CLOSE_CONTACT_EDIT = 'CLOSE_CONTACT_EDIT';
export const OPEN_CONTACT_DELETE = 'OPEN_CONTACT_DELETE';
export const CLOSE_CONTACT_DELETE = 'CLOSE_CONTACT_DELETE';

export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';

export const EDIT_CONTACT = 'EDIT_CONTACT';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILURE = 'EDIT_CONTACT_FAILURE';

export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';

export const SET_CONTACT_SEARCH_VALUE = 'SET_CONTACT_SEARCH_VALUE';
export const SET_CONTACT_SEARCH_VALUE_FINISHED = 'SET_CONTACT_SEARCH_VALUE_FINISHED';
export const SET_CONTACT_SEARCH_FILTER = 'SET_CONTACT_SEARCH_FILTER';
export const SET_CONTACT_SEARCH_FILTER_FINISHED = 'SET_CONTACT_SEARCH_FILTER_FINISHED';
export const SET_CONTACT_SORT_VALUE = 'SET_CONTACT_SORT_VALUE';
export const SET_CONTACT_SORT_VALUE_FINISHED = 'SET_CONTACT_SORT_VALUE_FINISHED';
export const SET_CONTACT_SORT_DIRECTION = 'SET_CONTACT_SORT_DIRECTION';
export const SET_CONTACT_SORT_DIRECTION_FINISHED = 'SET_CONTACT_SORT_DIRECTION_FINISHED';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_FILTER_FINISHED = 'CLEAR_FILTER_FINISHED';
