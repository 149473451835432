import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Image, List, Segment } from 'semantic-ui-react';
import CustomIcon from 'components/CustomIcon';

import LogoImg from 'assets/img/Logo-PNG-Blau-72.png';
import MattermostLogo from 'assets/img/mattermost_logo.svg';

const OverviewPage = () => {
    return (
        <div id="overview-page">
            <Segment basic>
                <Image
                    src={LogoImg}
                    width={320}
                    centered
                    alt="Heinrich Heine Consulting e.V. - Next generation for today's business"
                />
            </Segment>
            <Segment padded style={{ marginTop: '2rem' }}>
                <Grid columns={2} divided stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h2">Externe Tools</Header>
                            <List relaxed="very" size="large">
                                <List.Item>
                                    <List.Icon name="envelope outline" />
                                    <List.Content>
                                        <List.Header
                                            href="https://webmail.all-inkl.com/"
                                            target="_blank"
                                        >
                                            WebMail
                                        </List.Header>
                                        <List.Description>
                                            Schreibe und empfange E-Mails mit deiner
                                            HHC-E-Mail-Adresse direkt im Browser.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <CustomIcon src={MattermostLogo} />
                                    <List.Content>
                                        <List.Header
                                            href="https://mattermost.hhc-duesseldorf.de/"
                                            target="_blank"
                                        >
                                            Mattermost
                                        </List.Header>
                                        <List.Description>
                                            Kommuniziere mit allen HHC-Mitgliedern und deinem
                                            Ressort.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="cloud" />
                                    <List.Content>
                                        <List.Header
                                            href="https://drive.google.com/drive/folders/0AGkc2XmGi5VHUk9PVA"
                                            target="_blank"
                                        >
                                            GDrive
                                        </List.Header>
                                        <List.Description>
                                            Speichere deine für HHC relevanten Dateien und finde
                                            wichtige HHC-Dokumente in der Cloud.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="hubspot" />
                                    <List.Content>
                                        <List.Header
                                            href="https://app.hubspot.com/"
                                            target="_blank"
                                        >
                                            HubSpot
                                        </List.Header>
                                        <List.Description>
                                            Arbeite mit den Kontaktdaten unserer Kunden, pflege
                                            deine Deals und schau dir an, welche Projekte wir
                                            aktuell in der Pipeline haben und wie dort der aktuelle
                                            Stand ist.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                <List.Icon name="trello" />
                                    <List.Content>
                                        <List.Header href="https://wekan.hhc-duesseldorf.de" target="_blank">
                                            Wekan
                                        </List.Header>
                                        <List.Description>
                                            Organisiere Projekte übersichtlich in Teilaufgaben.
                                        </List.Description>
                                        
                                    </List.Content>
                                </List.Item>
                                <List.Item>

                                    <List.Icon name="gitlab" />
                                    <List.Content>
                                        <List.Header
                                            href="https://gitlab.hhc-duesseldorf.de"
                                            target="_blank"
                                        >
                                            GitLab
                                        </List.Header>
                                        <List.Description>
                                            Versioniere deinen Programmiercode und kollaboriere beim
                                            Programmieren mit anderen HHClern.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="wikipedia w" />
                                    <List.Content>
                                        <List.Header
                                            href="https://wiki.hhc-duesseldorf.de"
                                            target="_blank"
                                        >
                                            Wiki
                                        </List.Header>
                                        <List.Description>
                                            Finde alle wichtigen Informationen zum Verein und seinen
                                            Prozessen.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h2">Interne Tools</Header>
                            <List relaxed="very" size="large">
                                <List.Item>
                                    <List.Icon name="group" />
                                    <List.Content>
                                        <List.Header as={Link} to="/member">
                                            Mitgliederliste
                                        </List.Header>
                                        <List.Description>
                                            Verwalte dein HHC-Profil und andere HHC-Datensätze
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="code" />
                                    <List.Content>
                                        <List.Header as={Link} to="/user/signature">
                                            E-Mail Signatur
                                        </List.Header>
                                        <List.Description>
                                            Lasse dir deine individuelle E-Mail-Signatur im
                                            HHC-Design generieren.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="gift" />
                                    <List.Content>
                                        <List.Header as={Link} to="/member/geburtstagskalender">
                                            Geburtstagskalender
                                        </List.Header>
                                        <List.Description>
                                            Sieh dir an, welcher HHCler als nächstes Geburtstag hat.
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </div>
    );
};

export default OverviewPage;
