import React, { Component } from 'react';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

import Template from './Template';

class FieldArrayWrapper extends Component {
    render() {
        return (
            <FieldArray
                name={this.props.name}
                component={Template}
                content={this.props.component}
                validate={this.props.validation}
                min={this.props.min ? this.props.min : 0}
                disabled={this.props.disabled}
            />
        );
    }
}

FieldArrayWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    validation: PropTypes.func,
    min: PropTypes.number,
    disabled: PropTypes.bool,
};

export default FieldArrayWrapper;
