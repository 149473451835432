import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';

import RoleCreateEditForm from './RoleCreateEditForm';

const RoleCreateEditModal = (props) => (
    <React.Fragment>
        <Modal.Header>Rolle {props.data ? 'bearbeiten' : 'hinzufügen'}</Modal.Header>
        <Modal.Content>
            <RoleCreateEditForm initialValues={props.data} close={props.closeModal} />
        </Modal.Content>
        <Modal.Actions>
            <Button
                primary
                content={props.data ? 'Speichern' : 'Hinzufügen'}
                icon={props.data ? 'edit' : 'add'}
                onClick={() => props.submit('roleCreateEdit')}
            />
            <Button content="Schließen" onClick={props.closeModal} />
        </Modal.Actions>
    </React.Fragment>
);

RoleCreateEditModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
};

export default connect(null, { submit })(RoleCreateEditModal);
