export const FETCH_MEMBER = 'FETCH_MEMBER';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_FAILURE = 'FETCH_MEMBER_FAILURE';

//export const FETCH_STATUS = 'FETCH_STATUS';

export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';

export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE';

export const EDIT_MEMBER = 'EDIT_MEMBER';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER_FAILURE = 'EDIT_MEMBER_FAILURE';

export const SET_MEMBER_SEARCH_VALUE = 'SET_MEMBER_SEARCH_VALUE';
export const SET_MEMBER_SEARCH_VALUE_FINISHED = 'SET_MEMBER_SEARCH_VALUE_FINISHED';
export const SET_MEMBER_SEARCH_FILTER = 'SET_MEMBER_SEARCH_FILTER';
export const SET_MEMBER_SEARCH_FILTER_FINISHED = 'SET_MEMBER_SEARCH_FILTER_FINISHED';
export const SET_MEMBER_SORT_VALUE = 'SET_MEMBER_SORT_VALUE';
export const SET_MEMBER_SORT_VALUE_FINISHED = 'SET_MEMBER_SORT_VALUE_FINISHED';
export const SET_MEMBER_SORT_DIRECTION = 'SET_MEMBER_SORT_DIRECTION';
export const SET_MEMBER_SORT_DIRECTION_FINISHED = 'SET_MEMBER_SORT_DIRECTION_FINISHED';

export const CLEAR_MEMBER_FILTER = 'CLEAR_MEMBER_FILTER';
export const CLEAR_MEMBER_FILTER_FINISHED = 'CLEAR_MEMBER_FILTER_FINISHED';
