import Select from 'components/Select';
import { EducationStatus } from 'features/member/memberEnums';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { datetimeToDateFieldFormatter } from 'utilities';

import fieldLevelValidation from '../../utilities/fieldLevelValidation';
import Input from '../Input';

const statusOptions = [
    { key: 'ACTIVE', text: 'aktiv', value: EducationStatus.ACTIVE },
    { key: 'DONE', text: 'abgeschlossen', value: EducationStatus.DONE },
    { key: 'CANCELED', text: 'abgebrochen', value: EducationStatus.CANCELED },
];

const EditEducation = (props) => {
    const namePrefix =
        props.name !== undefined && props.index !== undefined
            ? props.name + '.' + props.index + '.'
            : 'educations.';

    return (
        <>
            <Form.Group>
                <Field
                    width={6}
                    name={namePrefix + 'school'}
                    component={Input}
                    label="Uni"
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                />
                <Field
                    width={6}
                    name={namePrefix + 'degree'}
                    component={Input}
                    label="Abschluss"
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                />
            </Form.Group>
            <Form.Group>
                <Field
                    width={6}
                    name={namePrefix + 'subject'}
                    component={Input}
                    label="Fach"
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                />
                <Field
                    width={6}
                    name={namePrefix + 'focus'}
                    component={Input}
                    label="Schwerpunkt"
                    disabled={props.disabled}
                />
            </Form.Group>
            <Form.Group>
                <Field
                    width={4}
                    name={namePrefix + 'begin'}
                    component={Input}
                    type="date"
                    label="von"
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                    format={datetimeToDateFieldFormatter}
                />
                <Field
                    width={4}
                    name={namePrefix + 'end'}
                    component={Input}
                    type="date"
                    label="bis"
                    disabled={props.disabled}
                    format={datetimeToDateFieldFormatter}
                />
                <Field
                    width={4}
                    name={namePrefix + 'status'}
                    component={Select}
                    options={statusOptions}
                    label="Status"
                    disabled={props.disabled}
                />
            </Form.Group>
        </>
    );
};

EditEducation.propTypes = {
    index: PropTypes.number,
    meta: PropTypes.object,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default EditEducation;
