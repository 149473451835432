import * as types from './authTypes';

const authStore = JSON.parse(localStorage.getItem('auth') || null);

const auth = (
    state = {
        loggingIn: false,
        loggingOut: false,
        error: '',
        apiToken: '',
        rights: [],
        member: null,
        username: '',
        ...authStore,
    },
    action
) => {
    switch (action.type) {
        case types.LOGIN:
            const username = action._request.auth.username;
            const email =
                username.split('@hhc-duesseldorf.de').length === 1
                    ? username + '@hhc-duesseldorf.de'
                    : username;

            return {
                ...state,
                loggingIn: true,
                username: email,
                error: '',
            };

        case types.LOGIN_SUCCESS:
            const payload = {
                memberId: action.payload.member_id,
                rights: action.payload.rights,
                username: action.payload.email,
                apiToken: action.payload.token,
            };
            localStorage.setItem('auth', JSON.stringify(payload));
            return {
                ...state,
                ...payload,
                loggingIn: false,
                error: '',
            };

        case types.LOGIN_GITLAB:
            return {
                ...state,
                loggingIn: true,
                error: '',
            };

        case types.LOGIN_GITLAB_SUCCESS:
            const payload_gitlab = {
                memberId: action.payload.member_id,
                rights: action.payload.rights,
                username: action.payload.email,
                apiToken: action.payload.token,
            };
            localStorage.setItem('auth', JSON.stringify(payload_gitlab));
            return {
                ...state,
                ...payload_gitlab,
                loggingIn: false,
                error: '',
            };

        case types.LOGIN_FAILURE:
            return {
                ...state,
                loggingIn: false,
                loggingOut: false,
                error:
                    action.error?.response?.status === 401
                        ? 'Falscher Username oder falsches Passwort.'
                        : action.error && action.error.data,
                apiToken: '',
                member: null,
                rights: [],
                username: '',
            };

        case types.LOGOUT_FAILURE:
        case types.LOGOUT_SUCCESS:
            localStorage.removeItem('auth');
            return {
                ...state,
                loggingIn: false,
                loggingOut: false,
                error: action.error && action.error.data,
                apiToken: '',
                member: null,
                rights: [],
                username: '',
            };

        case types.LOGOUT:
            return {
                ...state,
                loggingOut: true,
            };

        default:
            return state;
    }
};

export default auth;
