import React, { FC } from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';
import { translatePrefixIntoImage } from 'utilities';
import { CloseModal, OpenModal } from 'utilities/types';
import { RIGHTS, useUserRights } from 'utilities/useUserRights';
import { Member } from '../memberTypes';
import MemberDetails from './MemberDetails';

interface MemberDetailsModalProps {
    data: Member;
    closeModal: CloseModal;
    openModal: OpenModal;
}

const MemberDetailsModal: FC<MemberDetailsModalProps> = ({
    data: member,
    closeModal,
    openModal,
}) => {
    const userRights = useUserRights({ memberId: member.id });

    const openMemberEditModal = () => {
        openModal('memberEdit', member);
        closeModal(null, '/member');
    };

    const profileImage = translatePrefixIntoImage(member.salutation);

    return (
        <React.Fragment>
            <Modal.Header>Details</Modal.Header>
            <Modal.Content image>
                <Image wrapped size="medium" src={profileImage} />
                <Modal.Description>
                    <MemberDetails member={member} />
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Bearbeiten"
                    onClick={openMemberEditModal}
                    icon="edit"
                    primary
                    disabled={
                        !(userRights[RIGHTS.updateAllMember] || userRights[RIGHTS.updateOwnMember])
                    }
                />
                <Button content="Schließen" onClick={() => closeModal(null, '/member')} />
            </Modal.Actions>
        </React.Fragment>
    );
};

export default MemberDetailsModal;
