import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';

import { editMember } from '../memberActions';
import { Member } from '../memberTypes';
import MemberCreateEditForm from './MemberCreateEditForm';

interface MemberEditModalProps {
    data: Member;
    closeModal: () => void;
}

const MemberEditModal: FC<MemberEditModalProps> = ({ data: member, closeModal }) => {
    const dispatch = useDispatch();

    const submitHandler = async (data: any) => {
        await dispatch(editMember(data));

        // TODO: only close if edit was successfull
        closeModal();
    };

    return (
        <React.Fragment>
            <Modal.Header>Mitglied Bearbeiten</Modal.Header>
            <Modal.Content scrolling>
                <MemberCreateEditForm initialValues={member} onSubmit={submitHandler} />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    content="Speichern"
                    onClick={() => dispatch(submit('memberCreateEdit'))}
                    icon="edit"
                />
                <Button content="Abbrechen" onClick={closeModal} />
            </Modal.Actions>
        </React.Fragment>
    );
};

export default MemberEditModal;
