import React from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import omit from 'lodash/omit';
import Select from '../Select';

import CustomInput from './CustomInput';

class SelectCustom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            custom: false,
            customValue: '',
            selectValue: this.props.defaultValue ? this.props.defaultValue : '',
        };
    }

    componentDidMount() {
        this.chechForCustomValue();
    }

    chechForCustomValue = () => {
        const {
            options,
            input: { value },
        } = this.props;
        // check if the value is in the options
        if (options.filter((option) => option.value === value).length <= 0 && value.trim() !== '') {
            // value is not in the options
            // add value as the customValue
            this.setState({
                custom: true,
                customValue: value,
            });
        }
    };

    toggleCustomize = (param, data) => {
        const { value, name } = this.props.input;
        const formName = this.props.meta.form;
        if (this.state.custom) {
            this.setState({
                custom: false,
                customValue: value,
            });
            this.props.meta.dispatch(change(formName, name, this.state.selectValue));
        } else if (data === this.state.customValue) {
            this.setState({
                custom: true,
                selectValue: value,
            });
        }
    };

    changeCustomValue = (param, data) => {
        this.setState({ customValue: data });
    };

    customOptions = () => {
        return [
            ...this.props.options,
            {
                key: 'custom',
                text: 'Benutzerdefiniert',
                value: this.state.customValue,
            },
        ];
    };

    render() {
        const inputProps = omit(this.props, 'options');
        const { input, search, validation, ...props } = inputProps;
        return this.state.custom ? (
            <CustomInput
                {...input}
                {...props}
                validate={validation}
                onChange={this.changeCustomValue}
                toggleHandler={this.toggleCustomize}
                defaultValue={this.state.customValue}
            />
        ) : (
            <Field
                {...input}
                {...props}
                component={Select}
                defaultValue={this.state.selectValue}
                validate={validation}
                options={this.customOptions()}
                search={search}
                onChange={this.toggleCustomize}
            />
        );
    }
}

SelectCustom.propTypes = {
    meta: PropTypes.object,
    input: PropTypes.object,
    value: PropTypes.any,
    name: PropTypes.string,
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
};

export default SelectCustom;
