import PropTypes from 'prop-types';
import React from 'react';

import FilterableTableList from 'components/lists/FilterableTableList';
// import SortButton from 'components/lists/SortButton/SortButton';
import { connect } from 'react-redux';
import * as UsersSelector from '../redux/usersSelector';
import UsersListEntry from './UsersListEntry';

const UsersList = (props) => {
    const data = props.usersWithRolesAndMember;

    const header = ['E-Mail', 'Vorname', 'Nachname', 'Rollen'];
    return (
        <FilterableTableList
            component={UsersListEntry}
            items={data}
            fetching={props.fetching}
            header={header}
            // columnWidth={['42px', 'auto', '2rem']}
            listType="divided dashed striped hoverable"
            title="Benutzer"
        />
    );
};

UsersList.propTypes = {
    users: PropTypes.array,
    members: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    usersWithRolesAndMember: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
    return {
        usersWithRolesAndMember: UsersSelector.usersWithRolesAndMemberSelector(state),
    };
};

export default connect(mapStateToProps)(UsersList);
