import PropTypes from 'prop-types';
import React from 'react';

import { Field, reduxForm } from 'redux-form';
import { Form as SUIForm, Header, Segment } from 'semantic-ui-react';

import Input from 'components/Input';
import Select from 'components/Select';

import Form from 'components/Form';
import fieldLevelValidation from 'utilities/fieldLevelValidation';
import { RIGHTS, useUserRights } from 'utilities/useUserRights';
import EditEducation from '../../../components/edit/EditEducation';
import EditExpertTeam from '../../../components/edit/EditExpertTeam';
import EditPosition from '../../../components/edit/EditPosition';
import EditRessort from '../../../components/edit/EditRessort';
import EditStatus from '../../../components/edit/EditStatus';
import FieldArrayWrapper from '../../../components/FieldArrayWrapper/FieldArrayWrapper';

/* const dummyData = {
    first_name: 'Max',
    last_name: 'Mustermann',

    salutation: 'Herr',
    birth_date: '1990-01-01',

    mail_hhc: 'max.mustermann@hhc-duesseldorf.de',
    mail_private: 'max.mustermann@gmail.de',
    phone: '+49 0176 123 456 78',

    // studies: [],

    ressorts: [
        {
            name_short: 'MA',
            begin: '2018-06-01',
            end: '2020-01-01',
        },
        {
            name_short: 'IT',
            begin: '2020-01-01',
            end: null,
        },
    ],

    status: [
        {
            title: 'Trainee',
            begin: '2015-04-01',
        },
        {
            title: 'Active',
            begin: '2015-08-01',
        },
    ],

    positions: [
        {
            // position: 'Ressortleiter',
            title: 'HeadOf_IT',
            begin: '2020-01-02',
            end: null,
        },
    ],

    expert_teams: [
        {
            position: 'TeamLead',
            title: 'Website',
            begin: '2018-02-10',
            end: '2019-02-02',
        },
        {
            position: 'Member',
            title: 'Print&Design',
            begin: '2020-06-10',
            end: null,
        },
        {
            position: 'TeamLead',
            title: 'Development',
            begin: '2021-02-12',
            end: null,
        },
    ],

    link_linkedin: 'https://linkedin.com',
    link_xing: 'https://xing.de',
    link_facebook: 'https://facebook.com',
    link_instagram: 'https://instagram.com',
}; */

const MemberCreateEditForm = ({ handleSubmit, disabled, ...props }) => {
    const userRights = useUserRights({ memberId: props.initialValues?.id });

    const disableAddressInputs =
        !userRights[RIGHTS.readAddressData] && !userRights[RIGHTS.readOwnAddressData];

    const prefixOptions = [
        { key: 'Herr', text: 'Herr', value: 'Herr' },
        { key: 'Frau', text: 'Frau', value: 'Frau' },
    ];

    return (
        <Form onSubmit={handleSubmit(props.onSubmit)}>
            <>
                <Segment>
                    <SUIForm.Group>
                        <Field
                            width={4}
                            name="salutation"
                            component={Select}
                            options={prefixOptions}
                            type="text"
                            placeholder="Anrede"
                            label="Anrede"
                            validate={fieldLevelValidation.required}
                            required
                            disabled={disabled}
                        />
                        <Field
                            width={6}
                            name="first_name"
                            component={Input}
                            type="text"
                            placeholder="Vornamne"
                            label="Vornamne"
                            validate={[
                                fieldLevelValidation.required,
                                fieldLevelValidation.minLength3,
                            ]}
                            required
                            disabled={disabled}
                            autoComplete="given-name"
                        />
                        <Field
                            width={6}
                            name="last_name"
                            component={Input}
                            type="text"
                            placeholder="Nachname"
                            label="Nachname"
                            validate={[
                                fieldLevelValidation.required,
                                fieldLevelValidation.minLength3,
                            ]}
                            required
                            disabled={disabled}
                            autoComplete="family-name"
                        />
                    </SUIForm.Group>
                    <SUIForm.Group>
                        <Field
                            width={4}
                            name="birth_date"
                            component={Input}
                            type="date"
                            placeholder="(Geburtsdatum)"
                            label="(Geburtsdatum)"
                            validate={fieldLevelValidation.date}
                            disabled={disabled}
                        />
                        <Field
                            width={4}
                            name="id"
                            component={Input}
                            type="number"
                            label="Member ID"
                            disabled={
                                disabled ||
                                !!props.initialValues?.id ||
                                props.initialValues?.id === 0
                            }
                        />
                    </SUIForm.Group>
                </Segment>

                <Segment>
                    <Header as="h3" content="Kontakt" />
                    <SUIForm.Group>
                        <Field
                            width={8}
                            name="mail_hhc"
                            component={Input}
                            type="mail"
                            placeholder="max.mustermann@hhc-duesseldorf.de"
                            label="HHC E-Mail"
                            validate={fieldLevelValidation.email}
                            disabled={disabled}
                        />
                        <Field
                            width={8}
                            name="mail_private"
                            component={Input}
                            type="mail"
                            placeholder="max.mustermann@gmail.com"
                            label="Private E-Mail"
                            validate={fieldLevelValidation.email}
                            disabled={disabled}
                        />
                    </SUIForm.Group>
                    <SUIForm.Group>
                        <Field
                            width={8}
                            name="phone"
                            component={Input}
                            type="text"
                            placeholder="+49 0176 123 456 78"
                            label="Handynummer"
                            disabled={disabled}
                            icon="phone"
                        />
                    </SUIForm.Group>
                    <SUIForm.Group>
                        <Field
                            width={4}
                            name="link_linkedin"
                            component={Input}
                            type="text"
                            label="(LinkedIn URL)"
                            disabled={disabled}
                        />
                        <Field
                            width={4}
                            name="link_xing"
                            component={Input}
                            type="text"
                            label="(Xing URL)"
                            disabled={disabled}
                        />
                        <Field
                            width={4}
                            name="link_facebook"
                            component={Input}
                            type="text"
                            label="(Facebook URL)"
                            disabled={disabled}
                        />
                        <Field
                            width={4}
                            name="link_instagram"
                            component={Input}
                            type="text"
                            label="(Instagram username)"
                            disabled={disabled}
                            icon="at"
                        />
                        <Field
                            width={4}
                            name="username_tiktok"
                            component={Input}
                            type="text"
                            label="(TikTok Username)"
                            disabled={disabled}
                            icon="at"
                        />
                    </SUIForm.Group>
                </Segment>

                <Segment>
                    <Header as="h3" content="Adresse" />
                    <SUIForm.Group>
                        <Field
                            width={8}
                            name="street"
                            component={Input}
                            type="text"
                            placeholder="Universitätsstraße"
                            label="Straße"
                            disabled={disabled || disableAddressInputs}
                        />
                        <Field
                            width={8}
                            name="street_number"
                            component={Input}
                            type="text"
                            placeholder="1"
                            label="Hausnummer"
                            disabled={disabled || disableAddressInputs}
                        />
                    </SUIForm.Group>
                    <SUIForm.Group>
                        <Field
                            width={4}
                            name="postcode"
                            component={Input}
                            type="text"
                            placeholder="40225"
                            label="Postleitzahl"
                            disabled={disabled || disableAddressInputs}
                        />
                        <Field
                            width={12}
                            name="city"
                            component={Input}
                            type="text"
                            placeholder="Düsseldorf"
                            label="Stadt"
                            disabled={disabled || disableAddressInputs}
                        />
                    </SUIForm.Group>
                </Segment>

                <Segment>
                    <Header as="h3" content="Ressorts" />
                    <FieldArrayWrapper name="ressorts" component={EditRessort} />
                </Segment>

                <Segment>
                    <Header as="h3" content="Positions" />
                    <FieldArrayWrapper name="positions" component={EditPosition} />
                </Segment>

                <Segment>
                    <Header as="h3" content="Status" />
                    <FieldArrayWrapper name="status" component={EditStatus} />
                </Segment>

                <Segment>
                    <Header as="h3" content="Expert Teams" />
                    <FieldArrayWrapper name="expert_teams" component={EditExpertTeam} />
                </Segment>
                <Segment>
                    <Header as="h3" content="Ausbildung" />
                    <FieldArrayWrapper name="educations" component={EditEducation} />
                </Segment>
            </>
        </Form>
    );
};

MemberCreateEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

export default reduxForm({
    form: 'memberCreateEdit',
    // initialValues: dummyData,
})(MemberCreateEditForm);
