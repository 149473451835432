import * as types from './userTypes';

export const changePassword = (data) => ({
    type: types.CHANGE_PASSWORD,
    request: {
        url: 'user/changePassword',
        method: 'PUT',
        data,
    },
});

export const requestPasswordReset = (data) => ({
    type: types.REQUEST_PASSWORD_RESET,
    request: {
        url: '/user/resetPasswordRequest',
        data,
    },
});

export const resetPassword = (data) => ({
    type: types.RESET_PASSWORD,
    request: {
        url: '/user/resetPasswordSet',
        data,
    },
});
