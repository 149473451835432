import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reset, submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';

import { createMember, fetchMember } from '../memberActions';
import MemberCreateEditForm from './MemberCreateEditForm';

interface MemberCreateModal {
    closeModal: () => void;
}

const MemberCreateModal: FC<MemberCreateModal> = ({ closeModal }) => {
    const dispatch = useDispatch();

    const submitHandler = async (data: any) => {
        await dispatch(createMember(data));

        // TODO: only close if create was successfull
        closeModal();
        dispatch(fetchMember());
    };

    return (
        <React.Fragment>
            <Modal.Header>Neues Mitglied</Modal.Header>
            <Modal.Content scrolling>
                <MemberCreateEditForm onSubmit={submitHandler} />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    icon="edit"
                    content="Speichern"
                    primary
                    onClick={() => dispatch(submit('memberCreateEdit'))}
                />
                <Button
                    type="button"
                    content="Zurücksetzen"
                    onClick={() => dispatch(reset('memberCreateEdit'))}
                />
                <Button content="Abbrechen" onClick={closeModal} />
            </Modal.Actions>
        </React.Fragment>
    );
};

export default connect(null, { submit, reset, createMember, fetchMember })(MemberCreateModal);
