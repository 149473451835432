import profileImagePlaceholderMale from '../assets/img/profile_image_placeholder_elliot.jpg';
import profileImagePlaceholderFemale from '../assets/img/profile_image_placeholder_jenny.jpg';
import profileImagePlaceholderEmpty from '../assets/img/profile_image_placeholder_empty.jpg';

/**
 * Calculate the years from the given date until today (e.g. to calculate the age by the birth date)
 * source: https://stackoverflow.com/a/7091965
 * @param  {string} date
 * @return {string}      years from the birth date until today
 */
export const calcAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

/**
 * Transform a date form us to de or from de to us
 * @param  {string} date string or date object
 * @param  {string} format target format to return ['de', us']
 * @return {string}         date in the target format
 */
export const formatDate = (date, targetFormat = 'de') => {
    if (date) {
        const dateObject = date instanceof Date ? date : new Date(date);

        if (targetFormat === 'us') {
            return dateObject.toISOString().substr(0, 10);
        }

        // targetFormat === 'de'
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        return (
            '' +
            (day <= 9 ? '0' + day : day) +
            '.' +
            (month <= 9 ? '0' + month : month) +
            '.' +
            year
        ); // dd.mm.yyyy
    }
};

/**
 * Capitalize the first letter of a string or all if the string is shorter than four letters
 * e.g. testString -> TestString; sTr -> STR
 * @param  {string} string string to apitalize
 * @return {string}        capitalized string
 * @return {firstOnly}     bool
 */
export const capitalize = (string, firstOnly) => {
    if (typeof string === 'string') {
        return string.length < 4 && !firstOnly
            ? string.toUpperCase()
            : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
};

export const escapeRegExp = (string) => {
    return string.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
};

export const replaceAll = (searchString, find, replace) => {
    return searchString.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const replaceUmlaute = (string) => {
    let value = string.toLowerCase();
    value = replaceAll(value, 'ä', 'ae');
    value = replaceAll(value, 'ö', 'oe');
    value = replaceAll(value, 'ü', 'ue');
    value = replaceAll(value, 'ß', 'ss');
    value = replaceAll(value, 'é', 'e');
    value = replaceAll(value, 'è', 'e');
    value = replaceAll(value, 'ê', 'e');
    value = replaceAll(value, 'á', 'a');
    value = replaceAll(value, 'â', 'a');
    value = replaceAll(value, 'î', 'i');
    value = replaceAll(value, 'ô', 'o');
    value = replaceAll(value, 'ù', 'u');
    value = replaceAll(value, 'û', 'u');
    return value;
};

export const insertUmlaute = (string) => {
    let value = string.toLowerCase();
    value = replaceAll(value, 'ae', 'ä');
    value = replaceAll(value, 'oe', 'ö');
    value = replaceAll(value, 'ue', 'ü');
    value = replaceAll(value, 'ss', 'ß');
    return value;
};

export const generateUsername = (firstName, lastName) => {
    return [firstName, lastName].map((name) => replaceUmlaute(name.split(' ')[0])).join('.');
};

export const transformUsernameIntoName = (username) => {
    return username.split('.').map(insertUmlaute).map(capitalize).join(' ');
};

export const getAllDefaultStudySchools = () => [
    'Heinrich-Heine-Universität',
    'Universität Duisburg Essen',
    'Universität Köln',
    'FOM',
];

export const getAllDefaultStudyDegrees = () => [
    'b_sc',
    'b_a',
    'm_sc',
    'm_a',
    'diplom',
    'exam',
    'dr',
];

export const translateStudyDegree = (degree) => {
    switch (degree) {
        case 'b_sc':
            return 'Bachelor of Science';
        case 'b_a':
            return 'Bachelor of Arts';
        case 'm_sc':
            return 'Master of Science';
        case 'm_a':
            return 'Master of Arts';
        case 'diplom':
            return 'Diplom';
        case 'exam':
            return 'Staatsexamen';
        case 'dr':
            return 'Doktor';
        default:
            return degree;
    }
};

export const getAllStudyStatus = () => ['active', 'done', 'cancelled'];

export const translateStudyStatus = (status) => {
    switch (status) {
        case 'active':
            return 'Aktiv';
        case 'done':
            return 'Abgeschlossen';
        case 'cancelled':
            return 'Abgebrochen';
        default:
            return status;
    }
};

export const getAllStudyCourses = () => [
    'Wirtschaftswissenschaften',
    'Naturwissenschaften',
    'Wirtschaftschemie',
    'Informatik/Mathematik',
    'Rechtswissenschaften',
    'Psychologie',
    'Geisteswissenschaften',
    'Politik-, Sozial- und Medienwissenschaften',
];

export const getAllDefaultSkills = () => [
    'Microsoft Office',
    'Photoshop',
    'HTML, CSS',
    'JavaScript',
    'Python',
    'PHP',
    'Java',
    'Englisch',
    'Französisch',
    'Chinesisch',
    'Russisch',
];

export const getAllMemberStati = () => ['Trainee', 'Active', 'Passive', 'Alumni', 'Out'];

export const translateMemberStatusTitle = (memberStatusTitle) => {
    return memberStatusTitle;
};

export const getAllMemberRessorts = () => [
    'IT',
    'MA',
    'HR',
    'SA',
    'FI',
    'QM',
    'NW',
    'VORSTAND',
    'NONE',
];

export const translateMemberRessortShortName = (memberRessortShortName = '') => {
    switch (memberRessortShortName.toUpperCase()) {
        case 'IT':
            return 'IT';
        case 'MA':
            return 'Marketing';
        case 'HR':
            return 'Human-Resources';
        case 'SA':
            return 'Sales';
        case 'FI':
            return 'Finance-Legal';
        case 'QM':
            return 'QM';
        case 'NW':
            return 'Network';
        case 'VORSTAND':
            return 'Vorstand';
        case 'NONE':
            return 'Kein Ressort';
        default:
            return memberRessortShortName;
    }
};

export const POSITIONS = {
    Member: 'Member',
    HeadOf_NW: 'HeadOf_NW',
    HeadOf_QM: 'HeadOf_QM',
    HeadOf_FI: 'HeadOf_FI',
    HeadOf_IT: 'HeadOf_IT',
    HeadOf_HR: 'HeadOf_HR',
    HeadOf_MA: 'HeadOf_MA',
    HeadOf_SA: 'HeadOf_SA',
    CEO: 'CEO',
    COO: 'COO',
    CFO: 'CFO',
    COO_2: 'COO-2', 
};

export const getAllMemberPositions = () => Object.values(POSITIONS);

export const translateMemberPositionTitle = (memberPositionTitle, prefix = null) => {
    if (memberPositionTitle?.substr(0, 7) === 'HeadOf_') {
        const translation = prefix === 'Frau' ? 'Ressortleiterin für ' : 'Ressortleiter für ';
        switch (memberPositionTitle) {
            case 'HeadOf_NW':
                return translation + 'Netzwerke';
            case 'HeadOf_QM':
                return translation + 'Qualitätsmanagement';
            case 'HeadOf_FI':
                return translation + 'Finanzen';
            case 'HeadOf_IT':
                return translation + 'IT';
            case 'HeadOf_HR':
                return translation + 'Human Resources';
            case 'HeadOf_MA':
                return translation + 'Marketing';
            case 'HeadOf_SA':
                return translation + 'Sales';
            default:
                return memberPositionTitle;
        }
    }

    switch (memberPositionTitle) {
        case 'Member':
            return 'Mitglied';
        case 'CEO':
            return '1. Vorstand';
        case 'COO':
            return 'Mitglied des Vorstandes 1';
        case 'CFO':
            return 'Vorstand für Finanzen';
        case 'COO-2':
            return 'Mitglied des Vorstandes 2';
        default:
            return memberPositionTitle;
    }
};

export const getAllMemberExpertTeamPositons = () => ['Member', 'TeamLead'];

export const getAllMemberExpertTeamTitles = () => [
    '(SA) Pre-Sales',
    '(SA) Customers',
    '(SA) After-Sales',
    '(MA) Website',
    '(MA) Print&Design',
    '(MA) Public-Relations',
    '(MA) Social-Media',
    '(NW) Junior-Enterprises',
    '(NW) Accounts',
    '(NW) Key-Accounts',
    '(NW) Alumni',
    '(HR) Recruting',
    '(HR) Applicants',
    '(HR) Trainee',
    '(HR) People-Development',
    '(IT) Infrastructure',
    '(IT) Administraion',
    '(IT) Development',
    '(FI) Controlling',
    '(FI) Accounting',
    '(FI) Legal',
    '(QM) Process',
    '(QM) Data',
    '(QM) Projects',
];

export const translatePrefixIntoImage = (salutation) => {
    if (salutation === 'Herr') {
        return profileImagePlaceholderMale;
    } else if (salutation === 'Frau') {
        return profileImagePlaceholderFemale;
    } else {
        return profileImagePlaceholderEmpty;
    }
};

/**
 * Get the date of the next meeting (next/today's thursday)
 * TODO: get events from backend
 * @return {string} date of the next meeting
 */
export const getNextMeeting = () => {
    const meetingDay = 4; // Thursday
    const today = new Date();
    const todayDay = today.getDay();

    let diff = 0;
    if (todayDay > meetingDay) diff = 6 - todayDay + meetingDay + 1;
    else if (todayDay < meetingDay) diff = meetingDay - todayDay;

    const nextMeeting = new Date(today.setDate(today.getDate() + diff));
    return formatDate(nextMeeting, 'us');
};

export const round = (value, decimals) =>
    Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);

export function getApiUrl() {
    console.log('server', process.env.REACT_APP_SERVER);
    switch (process.env.REACT_APP_SERVER) {
        case 'heroku':
            return 'https://intern-hhc-backend.herokuapp.com/';
        case 'dev':
            return 'https://backend-dev.hhc-duesseldorf.de';
        case 'upcoming':
            return 'https://backend-upcoming.hhc-duesseldorf.de';
        case 'live':
            return 'https://backend.hhc-duesseldorf.de';
	    //return 'http://localhost:8081/';
        case 'local':
        default:
            return 'http://0.0.0.0:8081/';
    }
}

export function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
}

export function generateRandomString(from, to) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    const length = randomNumber(from, to);

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

/**
 * @param {string} value 'yyyy-mm-dd hh:mm:ss+hh:mm'
 * @param {string} name 'field name'
 * @returns {string | null} 'yyyy-mm-dd'
 */
export function datetimeToDateFieldFormatter(value, name) {
    if (value) {
        return value.slice(0, 10);
    }
    return null;
}
