import FilterTag from 'components/lists/FilterTag/FilterTag';
import { openModal } from 'components/Modal';
import ViewMember from 'components/view/ViewMember';
import { toggleMemberSearchFilter } from 'features/member/memberActions';
import { Member } from 'features/member/memberTypes';
import {
    getCurrentMemberPositions,
    getCurrentMemberRessorts,
    getCurrentMemberStatus,
} from 'features/member/utilities/getCurrentMemberData';
import React, { FC, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Icon, Popup } from 'semantic-ui-react';
import {
    translateMemberPositionTitle,
    translateMemberRessortShortName,
    translateMemberStatusTitle,
    translatePrefixIntoImage,
} from 'utilities';
import useMediaQueries from 'utilities/mediaQueriesHook';
import { RIGHTS, useUserRights } from 'utilities/useUserRights';
import './memberListEntryStyles.scss';

interface MemberListEntryProps {
    data: Member;
}

const MemberListEntry: FC<MemberListEntryProps> = ({ data: member }) => {
    const dispatch = useDispatch();

    const { isMobile } = useMediaQueries();
    const userRights = useUserRights({ memberId: member.id });

    const openMemberDetailsModal = () => {
        dispatch(openModal('memberDetails', member));
    };

    const openMemberEditModal = () => {
        dispatch(openModal('memberEdit', member));
    };

    const member_id = member.id;
    const first_name = member.first_name;
    const last_name = member.last_name;
    const mail = member.mail_hhc;
    const prefix = member.salutation;
    const phone = member.phone;
    const currentMemberStatusTitle = getCurrentMemberStatus(member)?.title;
    const currentRessortNameShort = getCurrentMemberRessorts(member)[0]?.name_short;
    const currentPositionTitle = getCurrentMemberPositions(member)[0]?.title;

    if (!currentMemberStatusTitle) {
        console.log(member, getCurrentMemberStatus(member));
    }

    const profileImage = translatePrefixIntoImage(prefix);

    const disableEditMember = !(
        userRights[RIGHTS.updateAllMember] || userRights[RIGHTS.updateOwnMember]
    );

    return (
        <tr className="member-list-entry">
            <td className="image">
                <div className="image-cell-wrapper">
                    <Popup
                        hoverable
                        position="right center"
                        trigger={
                            <img
                                src={profileImage}
                                className="profile-picture hover-pointer"
                                width="42"
                                alt="Profilbild"
                                onClick={() => openMemberDetailsModal()}
                            />
                        }
                    >
                        <Popup.Header>
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ flex: 'auto' }}>
                                    {first_name} {last_name}&nbsp;
                                </span>
                                <Button
                                    basic
                                    circular
                                    size="tiny"
                                    icon="eye"
                                    onClick={openMemberDetailsModal}
                                    title={`Details zu ${first_name} ${last_name} anzeigen`}
                                />
                                &nbsp;
                                <Button
                                    basic
                                    circular
                                    size="tiny"
                                    icon="edit"
                                    onClick={openMemberEditModal}
                                    title={`Daten von ${first_name} ${last_name} bearbeiten`}
                                    disabled={disableEditMember}
                                />
                            </div>
                        </Popup.Header>
                        <Popup.Content>
                            <ViewMember member={member} />
                        </Popup.Content>
                    </Popup>
                </div>
            </td>
            <td
                className="hover-pointer"
                title={`Details zu ${first_name} ${last_name} anzeigen`}
                onClick={() => openMemberDetailsModal()}
            >
                <b>
                    {first_name} {last_name}
                </b>
            </td>
            <td>{member_id}</td>
            {isMobile ? (
                <td style={{ width: '1px' }}>
                    <Button.Group icon>
                        {mail?.length > 0 && (
                            <Button
                                basic
                                circular
                                icon="mail"
                                title={`E-Mail an ${first_name} ${last_name} senden`}
                                href={`mailto:${mail}`}
                                color="blue"
                            />
                        )}
                        {phone?.length > 0 && (
                            <Button
                                basic
                                circular
                                title={`${first_name} ${last_name} anrufen`}
                                icon="phone"
                                href={`tel:${phone}`}
                                style={{ marginRight: 0 }}
                                color="green"
                            />
                        )}
                    </Button.Group>
                </td>
            ) : (
                <Fragment>
                    <td>
                        <FilterTag
                            name="status"
                            value={currentMemberStatusTitle}
                            label={translateMemberStatusTitle(currentMemberStatusTitle)}
                            filterAction={() => dispatch(toggleMemberSearchFilter)}
                        />
                    </td>
                    <td>
                        <FilterTag
                            name="ressort"
                            value={currentRessortNameShort}
                            label={translateMemberRessortShortName(currentRessortNameShort)}
                            filterAction={() => dispatch(toggleMemberSearchFilter)}
                        />
                    </td>
                    <td>
                        <FilterTag
                            name="position"
                            value={currentPositionTitle}
                            label={translateMemberPositionTitle(currentPositionTitle)}
                            filterAction={() => dispatch(toggleMemberSearchFilter)}
                        />
                    </td>
                    <td className="actions" width="1%">
                        <Dropdown
                            icon={
                                <Icon
                                    name="ellipsis vertical"
                                    color="grey"
                                    size="large"
                                    title={`Schnellmenü für ${first_name} ${last_name} öffnen`}
                                />
                            }
                            floating
                            pointing
                            className="right"
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    text="Details"
                                    icon="unhide"
                                    title={`Details zu ${first_name} ${last_name} anzeigen`}
                                    onClick={() => dispatch(openModal('memberDetails', member))}
                                />
                                <Dropdown.Item
                                    icon="edit"
                                    text="Bearbeiten"
                                    title={`Daten von ${first_name} ${last_name} bearbeiten`}
                                    onClick={() => dispatch(openModal('memberEdit', member))}
                                    disabled={disableEditMember}
                                />
                                <Dropdown.Divider />

                                <Dropdown.Item
                                    text="Mitgliedschaft beenden"
                                    icon="ban"
                                    title={`Mitglidschaft von ${first_name} ${last_name} beenden`}
                                    onClick={() => dispatch(openModal('memberDelete', member))}
                                    disabled={!userRights[RIGHTS.deleteMemberById]}
                                />

                                {mail?.length > 0 && (
                                    <Dropdown.Item
                                        title={`E-Mail an ${first_name} ${last_name} senden`}
                                    >
                                        <Icon className="mail" />
                                        <a href={`mailto:${mail}`}>Mail</a>
                                    </Dropdown.Item>
                                )}
                                {phone?.length > 0 && (
                                    <Dropdown.Item title={`${first_name} ${last_name} anrufen`}>
                                        <Icon className="phone" />
                                        <a href={`tel:${phone}`}>Anrufen</a>
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </Fragment>
            )}
        </tr>
    );
};

export default MemberListEntry;
