import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { calcAge, formatDate } from '../../utilities';

export default class ViewContact extends Component {
    static propTypes = {
        contact: PropTypes.object,
    };

    render() {
        const contact = this.props.contact;

        return (
            <Header>
                {contact.prefix} {contact.first_name} {contact.last_name}
                <Header.Subheader>
                    {contact.birth_date ? (
                        <>
                            {formatDate(contact.birth_date)} ({calcAge(contact.birth_date)} Jahre)
                        </>
                    ) : (
                        '-'
                    )}
                </Header.Subheader>
            </Header>
        );
    }
}
