import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';

import UserCreateEditForm from './UserCreateEditForm';
import { usersWithRolesAndMemberSelector } from '../redux/usersSelector';
import { fetchUsers } from '../redux/usersActions';
import { fetchRoles } from 'features/administration/roles/redux/rolesActions';

const UserEditModal = (props) => {
    useEffect(() => {
        props.fetchUsers();
        props.fetchRoles();
    }, []);

    const userData = props.userWithRolesAndMember;

    return (
        <React.Fragment>
            <Modal.Header>Benutzer {props.data ? 'bearbeiten' : 'hinzufügen'}</Modal.Header>
            <Modal.Content>
                <UserCreateEditForm initialValues={props.data} closeModal={props.closeModal} />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    content={props.data ? 'Speichern' : 'Hinzufügen'}
                    icon={props.data ? 'edit' : 'add user'}
                    onClick={() => props.submit('userCreateEdit')}
                />
                <Button content="Schließen" onClick={props.closeModal} />
            </Modal.Actions>
        </React.Fragment>
    );
};

UserEditModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    usersWithRolesAndMemberSelector: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        userWithRolesAndMember: usersWithRolesAndMemberSelector(state),
    };
};

export default connect(mapStateToProps, { submit, fetchUsers, fetchRoles })(UserEditModal);
