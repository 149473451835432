import * as types from './rightsTypes';

export const fetchRights = () => ({
    type: types.FETCH_RIGHTS,
    request: {
        url: 'rights',
        method: 'get',
    },
});

export const updateRight = (rightData) => ({
    type: types.UPDATE_RIGHT,
    request: {
        url: 'rights/updateRight',
        method: 'put',
        data: rightData,
    },
});

// export const fetchDescriptionForRight = (right) => ({
//     type: types.FETCH_DESCRIPTION_FOR_RIGHT,
//     request: {
//         url: `rights/${right}`,
//         method: 'get',
//     },
// });
