import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

const styles = {
    title: {
        fontSize: '4rem',
        fontWeight: '700',
        textTransform: 'uppercase',
        marginTop: '3rem',
    },
    subtitle: {
        fontSize: '2rem',
    },
};

const NotFoundPage = ({ statusCode, message }) => (
    <div id="page-not-found">
        <Header as="h1" style={styles.title}>
            Fehler {statusCode}
        </Header>
        <Header as="h2" style={styles.subtitle}>
            {message}
        </Header>
    </div>
);

NotFoundPage.propTypes = {
    statusCode: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
};

NotFoundPage.defaultProps = {
    statusCode: 404,
    message: 'Diese Seite konnte leider nicht gefunden werden',
};

export default NotFoundPage;
