import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

const LoadingBlock = ({ loading, inverted, dimmed }) => {
    return loading ? (
        <div>
            <div
                className={
                    'ui active dimmer' +
                    (!inverted ? ' inverted' : '') +
                    (!dimmed ? ' transparent' : '')
                }
            >
                <div className="ui loader"></div>
            </div>
            <Divider hidden />
        </div>
    ) : null;
};

LoadingBlock.defaultProps = {
    loading: true,
    dimmed: true,
};

LoadingBlock.propTypes = {
    loading: PropTypes.bool,
    inverted: PropTypes.bool,
    dimmed: PropTypes.bool,
};

export default LoadingBlock;
