import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import * as authTypes from '../features/auth/authTypes';
import auth from '../features/auth/authReducer';
import layout from '../components/Layout/redux/layoutReducer';
import contacts from '../features/contacts/contactsReducer';
import member from '../features/member/memberReducer';
import modals from '../components/Modal/modalReducer';
import user from '../features/user/userReducer';
import users from '../features/administration/users/redux/usersReducer';
import roles from '../features/administration/roles/redux/rolesReducer';
import rights from '../features/administration/rights/redux/rightsReducer';

const appReducer = combineReducers({
    form,
    modals,
    auth,
    layout,
    contacts,
    member,
    user,
    users,
    roles,
    rights,
});

const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === authTypes.LOGOUT_SUCCESS || action.type === authTypes.LOGOUT_FAILURE) {
        newState = {};
    }
    return appReducer(newState, action);
};

export default rootReducer;
