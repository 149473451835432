import uniq from 'lodash/uniq';

import { RootState } from 'redux/reduxTypes';
import { POSITIONS, translatePrefixIntoImage } from 'utilities';

export const getMember = (state: RootState) => state.member;

// MEMBER LIST
export const getMemberList = (state: RootState) => getMember(state).memberList;

export const getMemberFetching = (state: RootState) => getMember(state).fetching;

export const getRessorts = (state: RootState) => {
    const ressorts: Array<string> = [];

    getMemberList(state).forEach((member) => {
        member.ressorts
            .filter((ressort) => !ressort.end)
            .forEach((ressort) => {
                ressorts.push(ressort.name_short);
            });
    });
    return uniq(ressorts);
};

export const getPositions = (state: RootState) => {
    const positions: Array<string> = [];
    getMemberList(state).forEach((member) => {
        member.positions
            .filter((position) => !position.end)
            .forEach((position) => {
                positions.push(position.title);
            });
    });
    return uniq(positions);
};

export const getStati = (state: RootState) => {
    const stati: Array<string> = [];
    getMemberList(state).forEach((member) => {
        member.status
            .filter((status) => !status.end)
            .forEach((status) => {
                stati.push(status.title);
            });
    });
    return uniq(stati);
};

// LOADING
export const getMemberCreating = (state: RootState) => getMember(state).creating;

export const getMemberUpdating = (state: RootState) => getMember(state).updating;

export const getMemberDeleting = (state: RootState) => getMember(state).deleting;

// SEARCH
export const getSearchData = (state: RootState) => getMember(state).search;

export const getSearchValue = (state: RootState) => getSearchData(state).searchValue;

export const getMemberFilter = (state: RootState) => getSearchData(state).filter;

export const getMemberSortValue = (state: RootState) => getSearchData(state).sortValue;

export const getMemberSortDirection = (state: RootState) => getSearchData(state).sortDirection;

// SINGLE MEMBER

export const getMemberForId = (state: RootState, memberId: number) =>
    getMemberList(state).filter((member) => member.id === memberId)[0] || null;

// TODO: Create backend route to get the current mangement board
export const getCurrentVorstaende = (state: RootState) => {
    const vorstandPositionTitles = [POSITIONS.CEO, POSITIONS.COO, POSITIONS.CFO, POSITIONS.COO_2];

    const allMember = getMemberList(state);

    const currentVorstaende = allMember.filter((member) => {
        const vorstandPositions = member.positions.filter((p) => {
            return vorstandPositionTitles.includes(p.title);
        });

        const hasActiveVorstandsPositions = vorstandPositions.some((p) => !p.end);

        return hasActiveVorstandsPositions;
    });

    return currentVorstaende;
};

export const getProfileImageForMemberId = (state: RootState, memberId: number) => {
    const member = getMemberForId(state, memberId);
    return translatePrefixIntoImage(member?.salutation);
};
