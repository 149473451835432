import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import Row from './Row';

class Template extends Component {
    componentDidMount() {
        const { fields, min } = this.props;
        if (fields.length < min) {
            const diff = min - fields.length;
            for (let i = 0; i < diff; i++) {
                fields.push();
            }
        }
    }

    renderRows = () => {
        const { content, fields, meta, min, disabled } = this.props;
        return fields.map((item, index) => (
            <Row
                key={index}
                remove={fields.remove}
                name={fields.name}
                {...{ content, index, meta, fields, min, disabled }}
            />
        ));
    };

    render() {
        const { fields, meta, disabled } = this.props;
        return (
            <div>
                {this.renderRows()}
                <div className="field">
                    <Button
                        type="button"
                        content="Hinzufügen"
                        size="mini"
                        icon="plus"
                        className="blank"
                        onClick={() => fields.push()}
                        disabled={disabled}
                    />
                </div>
                {meta.submitFailed && meta.error && (
                    <span className="validation-message error">{meta.error}</span>
                )}
            </div>
        );
    }
}

Template.propTypes = {
    content: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    min: PropTypes.number,
    disabled: PropTypes.bool,
};

export default Template;
