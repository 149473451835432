import omit from 'lodash/omit';
import * as types from './modalTypes';

const modals = (state = {}, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            return {
                ...state,
                [action.name]: {
                    data: action.data,
                },
            };

        case types.CLOSE_MODAL:
            return omit(state, action.name);

        default:
            return state;
    }
};

export default modals;
