import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';

import { formatDate, datesEqual } from './calendarHelper';
import { Controls, SelectedDate, Days, Weekday, Termine, Termin } from './calendarStyles';
import Day from './Day';

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.date || new Date(),
        };
    }

    /**
     * SETTER
     */

    previousMonth = () => {
        this.setState((state) => ({
            date: new Date(state.date.setMonth(state.date.getMonth() - 1)),
        }));
    };

    nextMonth = () => {
        this.setState((state) => ({
            date: new Date(state.date.setMonth(state.date.getMonth() + 1)),
        }));
    };

    selectToday = () => {
        this.setState({
            date: new Date(),
        });
    };

    selectDate = (date) => {
        this.setState((state) => ({
            date: new Date(state.date.setDate(date)),
        }));
    };

    /**
     * GETTER
     */

    termineForDate = (date) => {
        const termine = this.props.termine.filter((termin) =>
            datesEqual(new Date(termin.date), date)
        );
        return termine;
    };

    /**
     * RENDERER
     */

    renderWeekdays = () =>
        ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((weekday, i) => (
            <Weekday key={i} weekend={i === 5 || i === 6}>
                {weekday}
            </Weekday>
        ));

    renderDays = () => {
        const date = this.state.date;
        const currentYear = date.getFullYear();
        const currentMonth = date.getMonth();
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

        const days = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(currentYear, currentMonth, day);
            days.push(
                <Day
                    key={day}
                    onClick={() => this.selectDate(day)}
                    selected={day === this.state.date.getDate()}
                    date={currentDate}
                    termine={this.termineForDate(currentDate)}
                />
            );
        }
        return days;
    };

    renderTermine = () => {
        const termine = this.termineForDate(this.state.date);
        return termine.length
            ? termine.map((termin, i) => <Termin key={i}>{termin.title}</Termin>)
            : `Keine ${this.props.termineLabel}`;
    };

    render() {
        return (
            <Segment>
                <Controls>
                    <Button basic onClick={this.previousMonth} icon="chevron left" />
                    <SelectedDate>{formatDate(this.state.date)}</SelectedDate>
                    <Button basic onClick={this.nextMonth} icon="chevron right" />
                    <Button
                        primary
                        onClick={this.selectToday}
                        style={{ marginLeft: 'auto' }}
                        content="heute"
                    />
                </Controls>
                <Days>
                    {this.renderWeekdays()}
                    {this.renderDays()}
                </Days>
                <Termine>{this.renderTermine()}</Termine>
            </Segment>
        );
    }
}

Calendar.propTypes = {
    termine: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    termineLabel: PropTypes.string,
};

Calendar.defaultProps = {
    termineLabel: 'Termine',
};

export default Calendar;
