const rolesData = (state) => state.roles;

/**
 * ROLES LIST
 */
export const roles = (state) => rolesData(state).rolesList;

export const rolesFetching = (state) => rolesData(state).fetching;

/**
 * SINGLE ROLE
 */
export const getRoleForId = (state, id) => roles(state).filter((role) => role.id === id)[0];

export const getRoleNameForId = (state, id) => getRoleForId(state, id).name;
