import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openModal } from 'components/Modal';

const RightsListEntry = (props) => {
    const openEditModal = () => props.openModal('rightCreateEdit', props.data);

    return (
        <tr onClick={openEditModal} className="hover-pointer">
            <td>{props.data.name}</td>
            <td style={{ width: '2rem' }}></td>
        </tr>
    );
};

RightsListEntry.propTypes = {
    data: PropTypes.object,
    openModal: PropTypes.func.isRequired,
};

export default connect(null, { openModal })(RightsListEntry);
