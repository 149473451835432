export default class fieldLevelValidation {
    static required = (value) => (!value && value !== 0 ? 'Erforderlich' : undefined);

    static maxLength = (max) => (value) =>
        value && value.length > max ? `Maximal ${max} Zeichen` : undefined;

    static maxLength15 = fieldLevelValidation.maxLength(15);

    static minLength = (min) => (value) =>
        value && value.length < min ? `Mindestens ${min} Zeichen` : undefined;

    static minLength3 = fieldLevelValidation.minLength(3);

    static number = (value) => (value && isNaN(Number(value)) ? 'Keine gültige Zahl' : undefined);

    static email = (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? 'Ungültige E-Mail Adresse'
            : undefined;

    static date = (value) =>
        value &&
        !/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/i.test(value) &&
        isNaN(Date.parse(value))
            ? 'Ungültiges Datum (yyyy-mm-dd or Date)'
            : undefined;

    static isEqualToField = (fieldName) => (value, formValues) =>
        value && formValues[fieldName] && value !== formValues[fieldName]
            ? 'Felder sind nicht identisch.'
            : undefined;
}
