import uniq from 'lodash/uniq';

import { calcAge, translatePrefixIntoImage } from 'utilities';

export const getContacts = (state) => state.contacts;

// CONTACT LIST
export const getContactsList = (state) => getContacts(state).contactList;

export const getContactsFetching = (state) => getContacts(state).fetching;

export const getAges = (state) => {
    return uniq(getContactsList(state).map((contact) => calcAge(contact.contact.birth_date)));
};

export const getStudies = (state) => {
    const studiesArrays = getContactsList(state).map((contact) => contact.study);
    let studies = [];
    studiesArrays.forEach((study) => {
        studies = studies.concat(study);
    });
    return studies;
};

export const getSchools = (state) => uniq(getStudies(state).map((study) => study.school));

export const getCourses = (state) => uniq(getStudies(state).map((study) => study.course));
export const getDegrees = (state) => uniq(getStudies(state).map((study) => study.degree));

// LOADING
export const getContactCreating = (state) => getContacts(state).creating;

export const getContactUpdating = (state) => getContacts(state).updating;

export const getContactDeleting = (state) => getContacts(state).deleting;

// SEARCH
export const getSearchData = (state) => getContacts(state).search;

export const getSearchValue = (state) => getSearchData(state).searchValue;

export const getContactsFilter = (state) => getSearchData(state).filter;

export const getContactsSortValue = (state) => getSearchData(state).sortValue;

export const getContactsSortDirection = (state) => getSearchData(state).sortDirection;

// SINGLE CONTACT
export const getContactIdFromContact = (contact) => contact.contact.id;

export const getContactForID = (state, contactId) =>
    getContactsList(state).filter((contact) => getContactIdFromContact(contact) === contactId)[0];

export const getPrefixFromContact = (contact) =>
    contact && contact.contact && contact.contact.prefix;

export const getProfileImageForContact = (contact) =>
    translatePrefixIntoImage(getPrefixFromContact(contact));

export const getProfileImageForContactId = (state, contactId) =>
    translatePrefixIntoImage(getPrefixFromContact(getContactForID(state, contactId)));
