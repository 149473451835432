import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Header } from 'semantic-ui-react';

import Input from 'components/Input';
import Select from 'components/Select';
import fieldLevelValidation from 'utilities/fieldLevelValidation';
import { ThemeConsumer } from 'styled-components';
import RoleDeleteModal from 'features/administration/roles/components/RoleDeleteModal';
import { values } from 'lodash';

const EditRight = (props) => {
    const namePrefix = props.name ? props.name + '.' : '';

    const rolesOptions = [];
    for (const role of props.roles) {
        rolesOptions.push({
            key: role.rolename,
            value: role.rolename,
            text: role.rolename,
        });
    }

    return (
        <React.Fragment>
            <h3> {props.value.name} </h3>
            <p> {props.value.description} </p>
            <Field
                multiple
                search
                fluid
                name={namePrefix + 'roles'}
                component={Select}
                options={rolesOptions}
                label="Benutzerrollen"
                placeholder="Benutzerrollen"
                disabled={props.disabled}
            />
        </React.Fragment>
    );
};

EditRight.propTypes = {
    roles: PropTypes.array,
    disabled: PropTypes.func,
    values: PropTypes.object,
    name: PropTypes.string,
};

export default EditRight;
