import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from 'features/auth/PrivateRoute';
import Layout from 'components/Layout/Layout';
import routes from './routes';
import store from 'redux/store';

const Root = () => {
    function renderRoutes() {
        return routes.map(({ isPrivate, ...routeProps }, i) =>
            isPrivate ? <PrivateRoute key={i} {...routeProps} /> : <Route key={i} {...routeProps} />
        );
    }

    return (
        <Provider store={store}>
            <Router>
                <Layout>
                    <Switch>{renderRoutes()}</Switch>
                </Layout>
            </Router>
        </Provider>
    );
};

export default Root;
