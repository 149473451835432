import omit from 'lodash/omit';
import React, { FC, ReactNode } from 'react';

import LoadingBlock from 'components/LoadingBlock';
import './listStyles.scss';

interface ListProps {
    component: FC<any>;
    data: Array<any>;
    fetching: boolean;
    header?: Array<null | ReactNode> | null;
    listType?: string;
    columnWidth?: Array<string>;
}

const List: FC<ListProps> = (props) => {
    const listEntries = () => {
        const dataList = props.data;
        const ListEntry = props.component;
        const customProps = omit(props, ['data', 'component', 'fetching', 'header']);
        if (dataList && dataList.length > 0) {
            return dataList.map((listItem, index) => {
                return <ListEntry key={index} data={listItem} {...customProps} />;
            });
        }
        return (
            <tr>
                <td colSpan={100}>
                    <div style={{ position: 'relative' }}>Keine Einträge gefunden.</div>
                </td>
            </tr>
        );
    };

    const getColumnWidth = (index: number) => {
        return props.columnWidth ? props.columnWidth[index] : undefined;
    };

    const renderHeader = () => {
        if (props.header) {
            return props.header.map((headerItem, index) => {
                return (
                    <th key={index} style={{ width: getColumnWidth(index) }}>
                        {headerItem}
                    </th>
                );
            });
        }
        return null;
    };

    const listTypes = () => (props.listType ? props.listType : '');

    return (
        <div className="list-container">
            <table className={'list ' + listTypes()}>
                {props.header && (
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                )}
                <tbody>
                    {props.fetching ? (
                        <tr>
                            <td colSpan={100} style={{ position: 'relative' }}>
                                <LoadingBlock loading />
                            </td>
                        </tr>
                    ) : (
                        listEntries()
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default List;
