import React, { FC, useEffect } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Button, Divider, Message, Segment } from 'semantic-ui-react';

import Form from 'components/Form';
import Input from 'components/Input';
import fieldLevelValidation from 'utilities/fieldLevelValidation';
import { LoginFormValues } from './LoginPage';
import { loginGitlab } from './authActions';

const FormMessage = ({ backendError }: { backendError: string }) => (
    <Message negative={!!backendError} positive={!backendError}>
        <Message.Header>{backendError ? 'Fehler!' : 'Login erfolgreich!'}</Message.Header>
        {backendError ? <p>{backendError}</p> : ''}
    </Message>
);

interface LoginFormProps {
    loginSubmit: (values: LoginFormValues) => void;
    loading: boolean;
    backendError: string;
    success: boolean;
}


const LoginForm: FC<LoginFormProps & InjectedFormProps<LoginFormValues, LoginFormProps>> = (
    props
) => {
    const { handleSubmit, loginSubmit, loading, backendError, success } = props;
    const REACT_APP_GITLAB_WEBSITE = process.env.REACT_APP_GITLAB_WEBSITE;
    const REACT_APP_GITLAB_CLIENT_ID = process.env.REACT_APP_GITLAB_CLIENT_ID;
    const REACT_APP_GITLAB_REDIRECT_URI = process.env.REACT_APP_GITLAB_REDIRECT_URI
    const REACT_APP_LOGIN_MODE = process.env.REACT_APP_LOGIN_MODE

    const dispatch = useDispatch();
    const handleGitLabLogin = async () => {
        window.location.href = 
        '' + REACT_APP_GITLAB_WEBSITE + '/oauth/authorize?response_type=code&client_id=' + REACT_APP_GITLAB_CLIENT_ID + '&redirect_uri=' + REACT_APP_GITLAB_REDIRECT_URI;
    };

    useEffect(() => {
        const newUrl = window.location.href;
        const hasCode = newUrl.includes('?code=');
    
        if (hasCode) {
            const url = newUrl.split('?code=')[1].split('#/login');
            const data = url[0]
            submitGitLab(data);
        }
    }, []);
    
    const submitGitLab = async (value : String) => {
        try {
            await dispatch(loginGitlab(value)); // trigger accountLogin action with dispatch
        } catch (error) {
            console.error('Unable to login with GitLab:', error);
        }
    };


    return (
        <Form onSubmit={handleSubmit(loginSubmit)} loading={loading} className="">
            <Segment>
            {REACT_APP_LOGIN_MODE !== 'gitlab' && (
                <>
                    <Field
                        name="username"
                        component={Input}
                        label="Username"
                        placeholder="Username"
                        type="text"
                        validate={[fieldLevelValidation.required]}
                        required
                        autoComplete="username"
                    />
                    <Field
                        name="password"
                        component={Input}
                        label="Passwort"
                        placeholder="Passwort"
                        type="password"
                        validate={fieldLevelValidation.required}
                        required
                        autoComplete="password"
                    />
                    <Divider hidden />
                    <Button
                        primary
                        content="Login"
                        icon="sign in"
                        style={{ minWidth: '50%' }}
                        type="submit"
                        loading={loading}
                    />
                </>
            )}
            {REACT_APP_LOGIN_MODE !== 'email' && (
                <Button
                    content="Mit GitLab anmelden"
                    icon="gitlab"
                    onClick={handleGitLabLogin}
                    style={{ minWidth: '100%' }}
                />
            )}
            </Segment>
            {(backendError || success) && <FormMessage backendError={backendError} />}
        </Form>
    );
};

export default reduxForm<LoginFormValues, LoginFormProps>({
    form: 'login',
})(LoginForm);
