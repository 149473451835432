import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';

import { translatePrefixIntoImage } from 'utilities';
import fieldLevelValidation from 'utilities/fieldLevelValidation';
import Select from 'components/Select';
import EditUser from './EditUser';
import Form from 'components/Form';

import * as ContactsSelector from 'features/contacts/contactsSelector';
import * as UsersSelector from '../redux/usersSelector';
//import * as RolesSelector from 'features/administration/roles/redux/rolesSelector';
import { fetchRoles } from 'features/administration/roles/redux/rolesActions';
import {
    fetchUsers,
    editUser,
    createUser,
    fetchRolesForUser,
    fetchMemberForUser,
} from '../redux/usersActions';

class UserCreateEditForm extends Component {
    isNew = () => {
        return !this.props.initialValues;
    };

    submit = async (data) => {
        if (this.isNew()) {
            await this.props.createUser(data);
        } else {
            await this.props.editUser(data);
        }

        this.props.fetchUsers();
        this.props.fetchRoles();
        this.props.closeModal();
    };

    render() {
        const { handleSubmit } = this.props;
        return (
            <Form onSubmit={handleSubmit(this.props.onSubmit || this.submit)}>
                <EditUser
                    new={this.isNew()}
                    changeFieldValue={this.props.change}
                    formName={this.props.form}
                    username={this.props.username}
                    contacts={this.props.contacts}
                    userRoles={this.props.roles}
                />
            </Form>
        );
    }
}

UserCreateEditForm.propTypes = {
    contacts: PropTypes.array,
    users: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    reset: PropTypes.func,
    submitting: PropTypes.bool,
    closeModal: PropTypes.func,
    initialValues: PropTypes.object,
    form: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    noContact: PropTypes.bool,
    username: PropTypes.string,
    fetchUsers: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    fetchRoles: PropTypes.func,
};

const mapStateToProps = (state) => ({
    contacts: ContactsSelector.getContactsList(state),
    users: UsersSelector.users(state),
    roles: UsersSelector.userRolesByUserId(state, this?.props.initialValues.user.id), // ToDo <-
});

export default connect(mapStateToProps, {
    fetchUsers,
    fetchRoles,
    editUser,
    createUser,
    fetchMemberForUser,
    fetchRolesForUser,
})(
    reduxForm({
        form: 'userCreateEdit',
    })(UserCreateEditForm)
);
