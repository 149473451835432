import * as types from './rightsTypes';

const initialState = {
    rightsList: [],
    fetching: false,
    error: '',
    errorDescriptionForRight: '',
};

const rights = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_RIGHTS:
            return {
                ...state,
                fetching: true,
            };
        case types.FETCH_RIGHTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                rightsList: action.payload,
            };
        case types.FETCH_RIGHTS_FAILURE:
            return {
                ...state,
                fetching: false,
                error: action.error && action.error.data,
            };
        case types.UPDATE_RIGHT:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default rights;
