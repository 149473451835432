import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'semantic-ui-react';

const Row = ({ content: Content, index, meta, name, remove, fields, min, disabled }) => {
    const removeHandler = () => {
        if (fields.length > min) {
            remove(index);
        }
    };

    return (
        <div>
            {index > 0 && <Divider />}
            <div className="two fields">
                <div className="fifteen wide field">
                    {/* {React.createElement(content, {index, name, meta, disabled})} */}
                    <Content {...{ index, name, meta, disabled }} />
                </div>
                <div className="one wide field">
                    <Button
                        circular
                        type="button"
                        icon="close"
                        className="blank"
                        onClick={() => removeHandler()}
                        disabled={disabled}
                        data-label="Entfernen"
                    />
                </div>
            </div>
        </div>
    );
};

Row.propTypes = {
    content: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    meta: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    min: PropTypes.number,
    disabled: PropTypes.bool,
};

export default Row;
