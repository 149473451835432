import omit from 'lodash/omit';
import * as types from './contactsTypes';

const contacts = (
    state = {
        contactList: [],
        fetching: false,
        failed: false,
        error: {},
        creating: false,
        updating: false,
        deleting: false,
        search: {
            searchValue: '',
            filter: {},
            sortValue: 'contact.last_name',
            sortDirection: 'asc',
        },
    },
    action
) => {
    switch (action.type) {
        /**
         * FETCH CONTACTS
         */
        case types.FETCH_CONTACTS:
            return {
                ...state,
                fetching: true,
            };
        case types.FETCH_CONTACTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                contactList: action.payload,
            };
        case types.FETCH_CONTACTS_FAILURE:
            return {
                ...state,
                fetching: false,
                failed: true,
                error: action.error && action.error.data,
            };
        case types.CLEAR_CONTACTS:
            return {
                ...state,
                fetching: false,
                contactList: [],
            };

        /**
         * CREATE CONTACT
         */
        case types.CREATE_CONTACT:
            return {
                ...state,
                creating: true,
            };
        case types.CREATE_CONTACT_SUCCESS:
            return {
                ...state,
                creating: false,
            };
        case types.CREATE_CONTACT_FAILURE:
            return {
                ...state,
                creating: false,
                error: action.error && action.error.data,
            };

        /**
         * EDIT CONTACT
         */
        case types.EDIT_CONTACT:
            return {
                ...state,
                updating: true,
                error: action.error && action.error.data,
            };
        case types.EDIT_CONTACT_SUCCESS:
            return {
                ...state,
                updating: false,
            };
        case types.EDIT_CONTACT_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.error && action.error.data,
            };

        /**
         * DELETE CONTACT
         */
        case types.DELETE_CONTACT_REQUEST:
            return {
                ...state,
                deleting: true,
            };
        case types.DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                deleting: false,
            };
        case types.DELETE_CONTACT_FAILURE:
            return {
                ...state,
                deleting: false,
                error: action.error && action.error.data,
            };

        // ==================================
        // ============= SEARCH =============
        // ==================================

        // TODO: Refactor!

        case types.SET_CONTACT_SEARCH_VALUE:
            return {
                ...state,
                fetching: true,
                search: {
                    ...state.search,
                    searchValue: action.searchValue,
                },
            };

        case types.SET_CONTACT_SEARCH_VALUE_FINISHED:
            return {
                ...state,
                fetching: false,
            };

        case types.SET_CONTACT_SEARCH_FILTER: {
            let newState = { ...state.search.filter };
            if (action.checked) {
                if (!newState[action.name]) newState[action.name] = [];
                newState[action.name].push(action.value);
            } else {
                const index = newState[action.name].indexOf(action.value);
                if (index > -1) newState[action.name].splice(index, 1);
            }
            if (newState[action.name].length === 0) newState = omit(newState, action.name);
            return {
                ...state,
                fetching: true,
                search: {
                    ...state.search,
                    filter: newState,
                },
            };
        }

        case types.SET_CONTACT_SEARCH_FILTER_FINISHED:
            return {
                ...state,
                fetching: false,
            };

        case types.CLEAR_FILTER: {
            let newState = { ...state.search.filter };
            if (action.filter) {
                newState = omit(newState, action.filter);
            } else {
                newState = {};
            }
            return {
                ...state,
                fetching: true,
                search: {
                    ...state.search,
                    filter: newState,
                },
            };
        }

        case types.CLEAR_FILTER_FINISHED:
            return {
                ...state,
                fetching: false,
            };

        case types.SET_CONTACT_SORT_VALUE:
            return {
                ...state,
                fetching: true,
                search: {
                    ...state.search,
                    sortValue: action.sortValue,
                    sortDirection: 'asc',
                },
            };

        case types.SET_CONTACT_SORT_VALUE_FINISHED:
            return {
                ...state,
                fetching: false,
            };

        case types.SET_CONTACT_SORT_DIRECTION:
            return {
                ...state,
                fetching: true,
                search: {
                    ...state.search,
                    sortDirection: action.sortDirection,
                },
            };

        case types.SET_CONTACT_SORT_DIRECTION_FINISHED:
            return {
                ...state,
                fetching: false,
            };

        default:
            return state;
    }
};

export default contacts;
