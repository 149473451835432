import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, unregisterField } from 'redux-form';

import Input from 'components/Input';
import Select from 'components/Select';
import { translatePrefixIntoImage } from 'utilities';

import fieldLevelValidation from 'utilities/fieldLevelValidation';
import { fetchMember } from '../../../member/memberActions';
import * as MemberSelector from '../../../member/memberSelector';
import { fetchRoles } from '../../roles/redux/rolesActions';
import * as RolesSelector from '../../roles/redux/rolesSelector';
import { fetchUsers } from '../redux/usersActions';
import * as UsersSelector from '../redux/usersSelector';

function EditUser(props) {
    useEffect(() => {
        props.fetchRoles();
        props.fetchMember();
        props.fetchUsers();

        if (props.new) {
            props.changeFieldValue('user.password', 'HHC_2021!');
        }
    }, []);

    const memberOptions = useMemo(() => {
        console.log('generate member options');
        // generate array with member ids for all existing users
        const usersMemberIds = props.users
            .filter((user) => user.member_id)
            .map((user) => user.member_id);

        return (
            props.member
                // only member that are not users already
                .filter((member) => !usersMemberIds.includes(member.id))
                // sort by first name
                .sort((a, b) => a.first_name.localeCompare(b.first_name))
                // format member for semantic ui select input
                .map((member) => ({
                    key: member.id,
                    text: member.first_name + ' ' + member.last_name,
                    value: member.id,
                    image: {
                        avatar: true,
                        src: translatePrefixIntoImage(member.salutation),
                    },
                }))
        );
    }, [props.member, props.users]);

    const rolesOptions = useMemo(() => {
        return props.roles.map((role) => ({
            value: role.id,
            key: role.id,
            text: role.rolename,
        }));
    }, [props.roles]);

    return (
        <div>
            <Field
                name="user.email"
                component={Input}
                placeholder="E-Mail"
                label="E-Mail"
                disabled={props.disabled}
                validate={fieldLevelValidation.isRequired}
                required
            />
            <Field
                multiple
                search
                fluid
                name="user.user_roles"
                component={Select}
                options={rolesOptions}
                placeholder="Benutzerrollen"
                label="Benutzerrollen"
                disabled={props.disabled}
            />

            <Field
                name="user.password"
                component={Input}
                placeholder="Passwort"
                defaultValue="HHC_2021!"
                label={props.new ? 'Passwort' : 'Neues Passwort setzen'}
                disabled={props.disabled}
            />

            {props.new && (
                <>
                    <Field
                        name="user.member_id"
                        component={Select}
                        search
                        options={memberOptions}
                        label="Verknüpfe den User mit einem bereits erstellten Mitglied (optional)"
                        disabled={props.disabled}
                    />
                </>
            )}
        </div>
    );
}

EditUser.propTypes = {
    disabled: PropTypes.bool,
    new: PropTypes.bool,
    formName: PropTypes.string,
    userFormValues: PropTypes.any,
    contacts: PropTypes.array,
    unregisterField: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    username: PropTypes.string,
    roles: PropTypes.array,
    rolesFetching: PropTypes.bool,
    fetchRoles: PropTypes.func.isRequired,
    fetchMember: PropTypes.func.isRequired,
    member: PropTypes.array.isRequired,
    memberFetching: PropTypes.bool.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    usersFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
    const selector = formValueSelector(props.formName);
    return {
        roles: RolesSelector.roles(state),
        rolesFetching: RolesSelector.rolesFetching(state),
        userFormValues: selector(state, 'user'),
        member: MemberSelector.getMemberList(state),
        memberFetching: MemberSelector.getMemberFetching(state),
        users: UsersSelector.users(state),
        usersFetching: UsersSelector.usersFetching(state),
    };
};

const mapDispatchToProps = {
    changeField: change,
    unregisterField,
    fetchRoles,
    fetchMember,
    fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
