import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { FC, ReactNode, useMemo, useState } from 'react';

import { isMobile as isMobileSelector } from 'components/Layout/redux/layoutSelector';
import List from 'components/lists/List/List';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reduxTypes';
import { Pagination } from 'semantic-ui-react';
import * as filterFunctions from 'utilities/filterFunctions';
import { Filter } from 'utilities/types';

interface FilterableTableListProps {
    items: Array<any>;
    fetching: boolean;
    component: FC<any>;
    searchValue?: string;
    searchRange?: Array<string>;
    filter?: Filter;
    sortValue?: string;
    sortDirection?: string;
    minLength?: number;
    showTitle?: boolean;
    title?: string | ((itemsCount: number) => string);
    showItemsCount?: boolean;
    header?: Array<null | ReactNode> | null;
    listType?: string;
    columnWidth?: string[];
    itemsPerPage?: number;
    disabledPagination?: boolean;
}

const FilterableTableList: FC<FilterableTableListProps> = ({
    items,
    searchValue,
    searchRange,
    filter,
    sortValue,
    sortDirection,
    minLength = 2,
    showTitle = true,
    title = 'Suchergebnisse',
    showItemsCount = true,
    fetching,
    component,
    header,
    listType,
    columnWidth,
    itemsPerPage = 10,
    disabledPagination,
}) => {
    const [page, setPage] = useState<number>(1);
    const [itemsCount, setItemsCount] = useState<number>(0);

    const { isMobile } = useSelector((state: RootState) => ({
        isMobile: isMobileSelector(state),
    }));

    const testPatternInSearchRange = (item: any, pattern: RegExp) =>
        searchRange && searchRange.some((path) => pattern.test(get(item, path)));

    const itemMatchesSearchWords = (item: any, searchWords: string[]) =>
        !minLength || searchWords[0].length >= minLength
            ? searchWords.every((searchWord) => {
                  const pattern = new RegExp(escapeRegExp(searchWord), 'i');
                  return testPatternInSearchRange(item, pattern);
              })
            : true;

    const defaultFilterFunction = (item: any, filter: string) => filter.indexOf(item[filter]) > -1;

    const itemMatchesFilter = (item: any, filter: Filter) =>
        filter && Object.keys(filter).length
            ? Object.keys(filter).every((key) =>
                  filterFunctions[key as keyof typeof filterFunctions]
                      ? filterFunctions[key as keyof typeof filterFunctions](item, filter[key])
                      : defaultFilterFunction(item, filter[key] as string)
              )
            : true;

    const filteredItems = useMemo(() => {
        if (items) {
            let _filteredItems = items;
            if (filter) {
                _filteredItems = _filteredItems.filter((item) => itemMatchesFilter(item, filter));
            }
            if (searchValue) {
                const searchWords = searchValue.trim().split(' ');
                _filteredItems = _filteredItems.filter((item) =>
                    itemMatchesSearchWords(item, searchWords)
                );
            }

            setItemsCount(_filteredItems.length);
            setPage(1);
            return _filteredItems;
        }

        setPage(1);
        setItemsCount(0);
        return [];
    }, [items, filter, searchValue]);

    const filteredAndsortedItems = useMemo(() => {
        if (filteredItems?.length) {
            if (sortValue) {
                const sortedItems = sortBy(filteredItems, sortValue);
                return sortDirection && sortDirection === 'desc'
                    ? sortedItems.reverse()
                    : sortedItems;
            }
            return filteredItems;
        }
        return [];
    }, [filteredItems, sortValue, sortDirection]);

    const itemsForPage = useMemo(() => {
        const pageStartIndex: number = (page - 1) * (itemsPerPage || 1);
        const pageEndIndex: number = pageStartIndex + (itemsPerPage || 1);

        return disabledPagination
            ? filteredAndsortedItems
            : filteredAndsortedItems.slice(pageStartIndex, pageEndIndex);
    }, [filteredAndsortedItems, page, itemsPerPage]);

    const pageCount = itemsCount && itemsPerPage ? Math.ceil(itemsCount / itemsPerPage) : 0;

    function renderTitle() {
        if (typeof title === 'string') {
            return (
                <h2>
                    {title}
                    {showItemsCount && <span> ({itemsCount})</span>}
                </h2>
            );
        } else if (typeof title === 'function' && itemsCount) {
            return title(itemsCount);
        }
        return null;
    }

    return (
        <div className="filterable-table-list">
            {showTitle && renderTitle()}
            <List
                data={itemsForPage}
                component={component}
                header={header}
                listType={listType}
                columnWidth={columnWidth}
                fetching={fetching}
            />

            {!disabledPagination && (
                <Pagination
                    activePage={page}
                    onPageChange={(e, { activePage }) => setPage(activePage as number)}
                    siblingRange={isMobile ? 1 : 3}
                    totalPages={pageCount}
                    firstItem={null}
                    lastItem={null}
                    size={isMobile ? 'mini' : 'small'}
                    className="test mt-5"
                />
            )}
        </div>
    );
};

export default FilterableTableList;
