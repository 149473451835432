import { DELETE_CONTACT_FAILURE } from 'features/contacts/contactsTypes';
import * as types from './usersTypes';

export const fetchUsers = () => ({
    type: types.FETCH_USERS,
    request: {
        url: 'users',
    },
});

export const fetchMemberForUser = (member_id) => ({
    type: types.FETCH_MEMBER_FOR_USER,
    request: {
        url: 'user/memberForUser',
        method: 'put',
        data: { member_id: member_id },
    },
});

export const fetchRolesForUser = (id) => ({
    type: types.FETCH_ROLES_FOR_USER,
    request: {
        url: 'roles/rolesForUser',
        method: 'put',
        data: { id },
    },
    id,
});

export const createUser = (user) => ({
    type: types.CREATE_USER,
    request: {
        url: 'users',
        data: user,
        method: 'post',
    },
});

export const editUser = (user) => ({
    type: types.EDIT_USER,
    request: {
        url: `users/${user.user.id}`,
        method: 'put',
        data: user,
    },
});

export const deleteUser = (id) => ({
    type: types.DELETE_USER,
    request: {
        url: `users/${id}`,
        method: 'delete',
    },
});
