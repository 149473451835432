import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';

import { Modal, openModal } from 'components/Modal';
import FlexContainer from 'components/FlexContainer';
import UsersList from './components/UsersList';
import UserCreateModal from './components/UserCreateModal';
import UserEditModal from './components/UserEditModal';
import UserDeleteModal from './components/UserDeleteModal';
import { fetchUsers } from './redux/usersActions';
import { fetchMember } from 'features/member/memberActions';
import { fetchRoles } from 'features/administration/roles/redux/rolesActions';
import * as UsersSelector from './redux/usersSelector';
import * as ContactsSelector from 'features/contacts/contactsSelector';
import * as MemberSelector from 'features/member/memberSelector';
import * as RolesSelector from 'features/administration/roles/redux/rolesSelector';

class UsersPage extends Component {
    componentDidMount() {
        this.props.fetchUsers();
        this.props.fetchMember();
        this.props.fetchRoles();
    }

    isFetching = () => this.props.usersFetching || this.props.memberFetching;

    render() {
        return (
            <div>
                <FlexContainer hAlign="right">
                    <Button
                        basic
                        icon="add user"
                        onClick={() => this.props.openModal('userCreate')}
                        title="Benutzer hinzufügen"
                        content="Benutzer hinzufügen"
                    />
                </FlexContainer>
                <Segment>
                    <UsersList
                        users={this.props.users}
                        members={this.props.members}
                        roles={this.props.roles}
                        fetching={this.isFetching()}
                    />
                </Segment>
                <Modal
                    name="userEdit"
                    component={UserEditModal}
                    loading={
                        this.props.usersFetching ||
                        this.props.rolesFetching ||
                        this.props.userEditing
                    }
                    closeOnDimmerClick={false}
                />
                <Modal
                    name="userCreate"
                    component={UserCreateModal}
                    loading={this.props.userCreating}
                    closeOnDimmerClick={false}
                />
                <Modal
                    name="userDelete"
                    component={UserDeleteModal}
                    loading={this.props.userDeleting}
                />
            </div>
        );
    }
}

UsersPage.propTypes = {
    users: PropTypes.array.isRequired,
    usersFetching: PropTypes.bool.isRequired,
    userCreating: PropTypes.bool.isRequired,
    userEditing: PropTypes.bool.isRequired,
    userDeleting: PropTypes.bool.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    contacts: PropTypes.array.isRequired,
    contactsFetching: PropTypes.bool.isRequired,
    members: PropTypes.array.isRequired,
    memberFetching: PropTypes.bool.isRequired,
    fetchMember: PropTypes.func.isRequired,
    rolesFetching: PropTypes.bool,
    fetchRoles: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    users: UsersSelector.users(state),
    usersFetching: UsersSelector.usersFetching(state),
    userCreating: UsersSelector.userCreating(state),
    userEditing: UsersSelector.userEditing(state),
    userDeleting: UsersSelector.userDeleting(state),
    contacts: ContactsSelector.getContactsList(state),
    contactsFetching: ContactsSelector.getContactsFetching(state),
    members: MemberSelector.getMemberList(state),
    memberFetching: MemberSelector.getMemberFetching(state),
    roles: RolesSelector.roles(state),
    rolesFetching: RolesSelector.rolesFetching(state),
});

const mapDispatchToProps = {
    fetchUsers,
    fetchMember,
    fetchRoles,
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
