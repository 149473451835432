import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import omit from 'lodash/omit';

function getValue(value, multiple) {
    if (value || value === 0) {
        return value;
    }
    return multiple ? [] : '';
}

/*
 * Semantic UI Form.Select
 */
const Select = ({ input, meta, width, disabled, label, ...rest }) => (
    <Form.Field width={width}>
        <Form.Select
            {...omit(rest, 'defaultValue')}
            name={input.name}
            value={getValue(input.value, rest.multiple)}
            label={(label ? label : '') + (meta.touched && meta.error ? ' - ' + meta.error : '')}
            disabled={disabled}
            error={meta.touched && !!meta.error}
            selection={input}
            onChange={(param, data) => input.onChange(data.value)}
        />
        {/* {meta.touched && meta.error && <span className='validation-message error'>{meta.error}</span>} */}
    </Form.Field>
);

Select.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    width: PropTypes.number,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

export default Select;
