import { formatDate } from 'features/calendar/calendarHelper';
import { EducationStatus } from 'features/member/memberEnums';
import React, { FC } from 'react';
import { Education } from '../../features/member/memberTypes';

interface ViewMemberEducationPropsType {
    educations: Array<Education>;
}

const ViewMemberEducation: FC<ViewMemberEducationPropsType> = ({ educations }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Uni</th>
                    <th>Fach</th>
                    <th>von</th>
                    <th>bis</th>
                    <th style={{ width: '1px' }}></th>
                </tr>
            </thead>
            <tbody>
                {educations?.map((education) => (
                    <tr key={education.id}>
                        <td>{education.school}</td>
                        <td>
                            {education.subject}
                            <br />
                            {education.focus && `(${education.focus})`}
                        </td>
                        <td>{formatDate(new Date(education.begin))}</td>

                        <td>{formatDate(new Date(education.end))}</td>
                        <td>
                            {education.status && (
                                <div
                                    title={
                                        education.status === EducationStatus.ACTIVE
                                            ? 'aktiv'
                                            : education.status === EducationStatus.CANCELED
                                            ? 'abgebrochen'
                                            : 'abgeschlossen'
                                    }
                                    style={{
                                        width: '1rem',
                                        height: '1rem',
                                        borderRadius: '50%',
                                        backgroundColor:
                                            education.status === EducationStatus.ACTIVE
                                                ? 'green'
                                                : education.status === EducationStatus.CANCELED
                                                ? 'red'
                                                : 'blue',
                                    }}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ViewMemberEducation;
