import * as types from './rolesTypes';

export const fetchRoles = () => ({
    type: types.FETCH_ROLES,
    request: {
        url: 'roles',
    },
});

export const createRole = (role) => ({
    type: types.CREATE_ROLE,
    request: {
        url: 'roles/createRole',
        data: role,
        method: 'put',
    },
});

export const editRole = (role) => ({
    type: types.EDIT_ROLE,
    request: {
        url: 'roles/updateRole',
        data: role,
        method: 'put',
    },
});

export const deleteRole = (id) => ({
    type: types.DELETE_ROLE,
    request: {
        url: `roles/${id}`,
        method: 'delete',
    },
});
