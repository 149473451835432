import React from 'react';
import PropTypes from 'prop-types';

import FilterableTableList from 'components/lists/FilterableTableList';
// import SortButton from 'components/lists/SortButton/SortButton';
import RolesListEntry from './RolesListEntry';

const RolesList = (props) => {
    const header = ['ID', 'Rolle', ''];

    return (
        <FilterableTableList
            component={RolesListEntry}
            items={props.roles}
            fetching={props.fetching}
            header={header}
            columnWidth={['3rem', 'auto', '2rem']}
            listType="divided dashed striped hoverable"
            title="Benutzerrollen"
        />
    );
};

RolesList.propTypes = {
    roles: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
};

export default RolesList;
