import { types } from './layoutActions';

const initialState = {
    sidebarIsVisible: false,
    isMobile: false,
};

/**
 * LayoutReducer
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebarIsVisible: !state.sidebarIsVisible,
            };

        case types.SET_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile,
                sidebarIsVisible: action.isMobile ? state.sidebarIsVisible : false,
            };

        default:
            return state;
    }
};
