export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_GITLAB = 'LOGIN_GITLAB';
export const LOGIN_GITLAB_SUCCESS = 'LOGIN_GITLAB_SUCCESS';
export const LOGIN_GITLAB_FAILURE = 'LOGIN_GITLAB_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const VALIDATE_API_TOKEN = 'VALIDATE_API_TOKEN';
export const VALIDATE_API_TOKEN_SUCCESS = 'VALIDATE_API_TOKEN_SUCCESS';
export const VALIDATE_API_TOKEN_FAILURE = 'VALIDATE_API_TOKEN_FAILURE';
