import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import fieldLevelValidation from '../../utilities/fieldLevelValidation';
import Input from '../Input';
import Select from '../Select';
import { datetimeToDateFieldFormatter, getAllMemberStati } from 'utilities';

const EditStatus = (props) => {
    const namePrefix =
        props.name !== undefined && props.index !== undefined
            ? props.name + '.' + props.index + '.'
            : 'status.';

    const statusOptions = getAllMemberStati().map((status) => ({
        key: status,
        value: status,
        text: status,
    }));

    return (
        <Form.Group>
            <Field
                search
                width={8}
                name={namePrefix + 'title'}
                component={Select}
                label="Status"
                options={statusOptions}
                validate={fieldLevelValidation.required}
                required
                disabled={props.disabled}
            />
            <Field
                width={4}
                name={namePrefix + 'begin'}
                component={Input}
                type="date"
                label="von"
                validate={fieldLevelValidation.required}
                required
                disabled={props.disabled}
                format={datetimeToDateFieldFormatter}
            />
            <Field
                width={4}
                name={namePrefix + 'end'}
                component={Input}
                type="date"
                label="bis"
                disabled={props.disabled}
                format={datetimeToDateFieldFormatter}
            />
        </Form.Group>
    );
};

EditStatus.propTypes = {
    index: PropTypes.number,
    meta: PropTypes.object,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default EditStatus;
