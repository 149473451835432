import axios from 'axios';

import { getApiToken } from 'features/auth/AuthSelector';
import { getApiUrl } from '../../utilities';

const axiosMiddleware = (store) => (next) => (action) => {
    // check if action has the type request and an url
    if (action.request && action.request.url.length) {
        // make axios requets with the options defined in action.requets
        // use api token as Basic HTTP Auth username or use auth object
        // that should contain username and password
        // dispatch new actions on success or failure

        const { url, data, method, auth } = action.request;
        const token = getApiToken(store.getState());

        if ((!method || method.toLowerCase() === 'get') && data) {
            console.error('GET Requests do not support a body', action.request);
            return;
        }

        store.dispatch({
            type: action.type,
            _request: action.request,
        });

        return axios({
            url,
            method: method || 'get',
            data,
            auth: token ? { username: token } : auth,
            baseURL: getApiUrl(),
        })
            .then((response) => {
                console.debug('[BACKEND_SUCCESS]', response.status, response.statusText, {
                    url: response.config.url,
                    method: response.config.method,
                    response: response.data,
                    config: response.config,
                });

                store.dispatch({
                    type: action.type + '_SUCCESS',
                    payload: response.data,
                    _request: action.request,
                });

                return Promise.resolve((response.data && response.data) || true);
            })
            .catch((error) => {
                console.error(
                    '[BACKEND_ERROR]',
                    error?.response?.status,
                    `(${error?.response?.statusText})`,
                    error?.response?.data,
                    {
                        url: error?.config?.url,
                        method: error?.config?.method,
                        response: error?.response,
                    }
                );

                if (error?.response?.status === 401) {
                    // NOT AUTHORIZED
                    store.dispatch({ type: 'LOGOUT_SUCCESS' });
                } else {
                    store.dispatch({
                        type: action.type + '_FAILURE',
                        error,
                    });
                }

                return Promise.reject(error?.response?.data);
            });
    }

    // call next middleware if type is not a backend request
    return next(action);
};

export default axiosMiddleware;
