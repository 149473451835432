import * as types from './modalTypes';

export const openModal = (name, data) => {
    return {
        type: types.OPEN_MODAL,
        name: name,
        data: data,
    };
};

export const closeModal = (name) => {
    return {
        type: types.CLOSE_MODAL,
        name,
    };
};
