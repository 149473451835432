import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'semantic-ui-react';

const SearchInput = ({ searchValue, changeSearchValue, ...inputProps }) => {
    const handleSearchChange = (e) => {
        const value = e.target.value;
        changeSearchValue(value);
    };

    const clearValue = () => {
        changeSearchValue('');
    };

    const searchIcon = () => {
        return searchValue.length ? (
            <Icon link name="remove" onClick={clearValue} />
        ) : (
            <Icon name="search" />
        );
    };

    return (
        <Input
            {...inputProps}
            value={searchValue}
            onChange={handleSearchChange}
            icon={searchIcon()}
        />
    );
};

SearchInput.defaultProps = {
    placeholder: 'Suchen...',
    fluid: true,
};

SearchInput.propTypes = {
    searchValue: PropTypes.string.isRequired,
    changeSearchValue: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    fluid: PropTypes.bool,
};

export default SearchInput;
