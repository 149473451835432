import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Container, Header } from 'semantic-ui-react';
import { login } from './authActions';
import AuthSelector from './AuthSelector';
import LoginForm from './LoginForm';

export interface LoginFormValues {
    username: string;
    password: string;
}

const LoginPage = () => {
    const location = useLocation<{ previous: string }>();
    const dispatch = useDispatch();
    const { loading, backendError, isAuthenticated } = useSelector((state: any) => ({
        loading: state.auth.loggingIn,
        backendError: state.auth.error,
        isAuthenticated: AuthSelector.isAuthenticated(state),
    }));

    const loginSubmitHandler = (values: LoginFormValues) => {
        const username = values.username.split('@')[0]; // allow hhc mail as username
        dispatch(login(username, values.password));
    };

    return isAuthenticated ? (
        <Redirect to={location.state?.previous || '/member'} />
    ) : (
        <Container className="small">
            <Header content="intern.HHC Login" textAlign="center" />
            <LoginForm
                loginSubmit={loginSubmitHandler}
                loading={loading}
                backendError={backendError}
                success={isAuthenticated}
            />
        </Container>
    );
};

// TODO: replace any

export default LoginPage;
