import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';

import Form from 'components/Form';
import Select from 'components/Select';
import fieldLevelValidation from 'utilities/fieldLevelValidation';

import { fetchRoles, deleteRole } from 'features/administration/roles/redux/rolesActions';
import * as RoleSelector from 'features/administration/roles/redux/rolesSelector';

class RoleDeleteModal extends Component {
    handleConfirm = (data) => {
        this.props.deleteRole(this.props.data.id);
        this.props.fetchRoles();
        this.props.closeModal();
    };

    render() {
        const role = this.props.data;
        const roleOptions = this.props.roles.map((userRole) => ({
            key: userRole.id,
            value: userRole.id,
            text: userRole.rolename,
        }));

        return (
            <React.Fragment>
                <Modal.Header>Rolle löschen</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.props.handleSubmit(this.handleConfirm)}>
                        <p className="text-big">
                            Sind Sie sicher, dass Sie die Rolle <strong>{role.rolename}</strong>{' '}
                            löschen möchten?
                        </p>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        negative
                        content="Rolle löschen"
                        icon="checkmark"
                        labelPosition="left"
                        type="submit"
                        onClick={() => this.props.submit('roleDelete')}
                    />
                    <Button content="Abbrechen" onClick={this.props.closeModal} type="button" />
                </Modal.Actions>
            </React.Fragment>
        );
    }
}

RoleDeleteModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    roles: PropTypes.array,
};

const mapStateToProps = (state) => ({
    roles: RoleSelector.roles(state),
});

export default connect(mapStateToProps, { fetchRoles, deleteRole, submit })(
    reduxForm({
        form: 'roleDelete',
    })(RoleDeleteModal)
);
