import { getUserContactId } from 'features/auth/AuthSelector';
import { getContactForID } from 'features/contacts/contactsSelector';
import { getMemberForId, getProfileImageForMemberId } from 'features/member/memberSelector';

export const getUserData = (state) => state.user;

// CHANGE PASSWORD
export const getChangePasswordData = (state) => getUserData(state).changePassword;

export const getPasswordIsChanging = (state) => getChangePasswordData(state).changing;

export const getPasswordChangigError = (state) => getChangePasswordData(state).error;

// REQUEST PASSWORD RESET
export const getRequestPasswordResetData = (state) => getUserData(state).requestPasswordReset;

export const getRequestPasswordResetIsLoading = (state) =>
    getRequestPasswordResetData(state).loading;

export const getRequestPasswordResetError = (state) => getRequestPasswordResetData(state).error;

// REQUEST PASSWORD RESET
export const getResetPasswordData = (state) => getUserData(state).resetPassword;

export const getResetPasswordIsLoading = (state) => getResetPasswordData(state).loading;

export const getResetPasswordError = (state) => getResetPasswordData(state).error;

// USER RELATED INFOS

export const getUserContact = (state, id) => getContactForID(state, id || getUserContactId(state));

export const getUserMember = (state, id = null) =>
    getMemberForId(state, id || getUserContactId(state));

export const getUserContactWithMember = (state) => {
    const userId = getUserContactId(state);
    return {
        contact: getUserContact(state, userId),
        member: getUserMember(state, userId),
    };
};

export const getUserProfileImage = (state) =>
    getProfileImageForMemberId(state, getUserContactId(state));
