import styled from 'styled-components';

export const CalendarWrapper = styled.div`
    display: inline-block;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    user-select: none;
`;

export const Controls = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
`;

export const SelectedDate = styled.div`
    margin: 0 0.75rem;
`;

export const Days = styled.div`
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(7, 1fr);
    margin-top: 1rem;
    user-select: none;
`;

export const Weekday = styled.div`
    height: 2rem;
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    ${(p) => p.weekend && 'color: rgba(0,0,0, 0.35);'};
`;

export const Termine = styled.div`
    padding: 1rem;
`;

export const Termin = styled.div`
    margin: 0.5rem 0;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    input {
        margin-right: 0.5rem;
    }
`;
