import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Icon } from 'semantic-ui-react';

import {
    capitalize,
    translateMemberPositionTitle,
    translateMemberRessortShortName,
    translateMemberStatusTitle,
    translateStudyDegree,
} from 'utilities';
import FilterTag from './FilterTag';

const ActiveFilterTagList = ({ filter, filterAction }) => {
    const filterLabel = (name, value) => {
        switch (name) {
            case 'ressort':
                return translateMemberRessortShortName(value);
            case 'position':
                return translateMemberPositionTitle(value);
            case 'status':
                return translateMemberStatusTitle(value);
            case 'mDegree':
            case 'degree':
                return translateStudyDegree(value);
            default:
                return value;
        }
    };

    return (
        <div className="active-filter-tag-list">
            {Object.keys(filter).length > 0 && <Divider hidden className="small" />}
            {Object.keys(filter).map((name, i) =>
                filter[name].map((value, j) => (
                    <FilterTag
                        key={(j + 1) * (i + 1)}
                        {...{ name, value, filterAction }}
                        label={filterLabel(name, value)}
                    >
                        <span className="name">{capitalize(name)}</span>
                        <span className="value">{filterLabel(name, value)}</span>
                        <Icon name="close" />
                    </FilterTag>
                ))
            )}
        </div>
    );
};

ActiveFilterTagList.propTypes = {
    filter: PropTypes.object.isRequired,
    filterAction: PropTypes.func.isRequired,
};

export default ActiveFilterTagList;
