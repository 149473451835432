import * as types from './memberTypes';
import backendRequest from 'utilities/backendRequest';

// FETCH_MEMBER
export const fetchMember = () => ({
    type: types.FETCH_MEMBER,
    request: {
        url: 'members',
    },
});

// CREATE_MEMBER
export const createMember = (member) => ({
    type: types.CREATE_MEMBER,
    request: {
        url: 'members',
        data: member,
        method: 'post',
    },
});

// FETCH_STATUS
//export const fetchStatus = () => ({
//   type: types.FETCH_STATUS,
//    request: {
//        url: 'members/status/1' //ToDo: variable member_id
//    },
//});

// DELETE_MEMBER
export const deleteMember = (memberId) => ({
    type: types.DELETE_MEMBER,
    request: {
        url: `members/${memberId}`,
        method: 'delete',
    },
});

// EDIT_MEMBER
export const editMember = (member) => ({
    type: types.EDIT_MEMBER,
    request: {
        url: `members/${member.id}`,
        method: 'put',
        data: member,
    },
});

// TODO: Refactor!
// SEARCH

/**
 * @param  {string} searchValue
 */
export const setMemberSearchValue = (searchValue) => (dispatch) => {
    dispatch({
        type: types.SET_MEMBER_SEARCH_VALUE,
        searchValue,
    });
};

/**
 * @param  {object} data {checked [bool], name [string], value [string/int]}
 */
export const setMemberSearchFilter = (data) => (dispatch) => {
    dispatch({
        type: types.SET_MEMBER_SEARCH_FILTER,
        checked: data.checked,
        name: data.name,
        value: data.value,
    });
};

export const toggleMemberSearchFilter = (data) => (dispatch, getState) => {
    const checked = getState().member.search.filter[data.name] ? false : true;
    dispatch(setMemberSearchFilter({ ...data, checked }));
};

export const clearMemberFilter =
    (filter = null) =>
    (dispatch) => {
        dispatch({
            type: types.CLEAR_MEMBER_FILTER,
            filter,
        });
    };

export const setMemberSortDirection = (sortDirection) => (dispatch) => {
    dispatch({
        type: types.SET_MEMBER_SORT_DIRECTION,
        sortDirection,
    });
};

export const setMemberSortValue = (sortValue) => (dispatch, getState) => {
    if (getState().member.search.sortValue === sortValue) {
        const sortDirection = getState().member.search.sortDirection === 'asc' ? 'desc' : 'asc';
        dispatch(setMemberSortDirection(sortDirection));
    } else {
        dispatch({
            type: types.SET_MEMBER_SORT_VALUE,
            sortValue,
        });
    }
};
