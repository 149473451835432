import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import fieldLevelValidation from '../../utilities/fieldLevelValidation';
import Input from '../Input';
import SelectCustom from '../SelectCustom/SelectCustom';
import {
    datetimeToDateFieldFormatter,
    getAllMemberExpertTeamPositons,
    getAllMemberExpertTeams,
    getAllMemberExpertTeamTitles,
} from 'utilities';

const EditExpertTeam = (props) => {
    const namePrefix =
        props.name !== undefined && props.index !== undefined
            ? props.name + '.' + props.index + '.'
            : 'ressorts.';

    const expertTeamPositionOptions = getAllMemberExpertTeamPositons().map((position) => ({
        key: position,
        value: position,
        text: position,
    }));

    const expertTeamTitleOptions = getAllMemberExpertTeamTitles().map((title) => ({
        key: title,
        value: title,
        text: title,
    }));

    return (
        <>
            <Form.Group>
                <Field
                    search
                    width={6}
                    name={namePrefix + 'position'}
                    component={SelectCustom}
                    label="Position"
                    options={expertTeamPositionOptions}
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                />
                <Field
                    search
                    width={6}
                    name={namePrefix + 'title'}
                    component={SelectCustom}
                    label="Expert Team"
                    options={expertTeamTitleOptions}
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                />
            </Form.Group>
            <Form.Group>
                <Field
                    width={4}
                    name={namePrefix + 'begin'}
                    component={Input}
                    type="date"
                    label="von"
                    validate={fieldLevelValidation.required}
                    required
                    disabled={props.disabled}
                    format={datetimeToDateFieldFormatter}
                />
                <Field
                    width={4}
                    name={namePrefix + 'end'}
                    component={Input}
                    type="date"
                    label="bis"
                    disabled={props.disabled}
                    format={datetimeToDateFieldFormatter}
                />
            </Form.Group>
        </>
    );
};

EditExpertTeam.propTypes = {
    index: PropTypes.number,
    meta: PropTypes.object,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

export default EditExpertTeam;
