import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import { closeModal, openModal } from './modalActions';
import LoadingBlock from 'components/LoadingBlock';
import { omit } from 'lodash';
import { withRouter } from 'react-router-dom';
// import './modalStyles.scss';

class ReduxModal extends Component {
    handleClose = (modalName, redirectUrl = null) => {
        this.props.closeModal(typeof modalName === 'string' ? modalName : this.props.name);

        if (redirectUrl) {
            this.props.history.push(redirectUrl);
        }
    };

    isOpen = () => !!this.props.modal;

    render() {
        const ModalComponent = this.props.component;
        return (
            this.isOpen() && (
                <Modal
                    open={this.isOpen()}
                    onClose={this.handleClose}
                    closeOnEscape={this.props.closeOnEscape}
                    closeOnDimmerClick={this.props.closeOnDimmerClick}
                >
                    {this.props.loading && <LoadingBlock />}
                    <ModalComponent
                        closeModal={this.handleClose}
                        openModal={this.props.openModal}
                        data={this.props.modal.data}
                    />
                </Modal>
            )
        );
    }
}

ReduxModal.propTypes = {
    name: PropTypes.string.isRequired,
    component: PropTypes.elementType,
    modal: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    closeOnDimmerClick: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
    modal: state.modals[ownProps.name],
});

const mapDispatchToProps = {
    closeModal,
    openModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReduxModal));
