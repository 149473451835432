import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { deleteUser, fetchUsers } from '../redux/usersActions';

const UserDeleteModal = (props) => {
    const handleConfirm = () => {
        console.info('DATA: ', props.data);
        props.deleteUser(props.data.user.id);
        props.closeModal();
        props.fetchUsers();
    };

    const user = props.data.user;
    return (
        <React.Fragment>
            <Modal.Header>Benutzer löschen</Modal.Header>
            <Modal.Content className="text-big">
                Sind Sie sicher, dass Sie den Benutzer
                {user && (
                    <span>
                        {' '}
                        <b>{user.email}</b>{' '}
                    </span>
                )}
                löschen möchten?
                <br />
                Die Mitgliedsdaten bleiben davon unberührt.
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    content="Benutzer löschen"
                    icon="checkmark"
                    labelPosition="left"
                    onClick={handleConfirm}
                />
                <Button content="Abbrechen" onClick={props.closeModal} />
            </Modal.Actions>
        </React.Fragment>
    );
};

UserDeleteModal.propTypes = {
    data: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    deleteUser,
    fetchUsers,
};

export default connect(null, mapDispatchToProps)(UserDeleteModal);
