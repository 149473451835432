import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Message, Popup, Segment } from 'semantic-ui-react';
import inlineCss from 'inline-css';

import LoadingBlock from 'components/LoadingBlock';
import { fetchMember } from 'features/member/memberActions';
import { getUserMember } from 'features/user/userSelector';
import { getCurrentVorstaende, getMemberFetching } from 'features/member/memberSelector';

import signatureTemplate from './signatureTemplate';
import './signatureGeneratorStyle.scss';
import { POSITIONS, translateMemberPositionTitle } from 'utilities';

const POPUP_DELAY = 750;

const SignatureGenerator = (props) => {
    const [state, setState] = useState({
        signature: '',
        codePopupOpen: false,
        previewPopupOpen: false,
        previewReady: false,
    });

    const hiddenPreviewRef = useRef();
    const previewRef = useRef();
    const codeRef = useRef();

    useEffect(() => {
        props.fetchMember();
    }, []);

    useEffect(() => {
        if (
            !props.memberFetching &&
            props.member &&
            hiddenPreviewRef.current &&
            previewRef.current
        ) {
            console.log(previewRef.current);
            const signatureHtml = renderSignature();

            const inlineCssOptions = {
                url: ' ',
                preserveMediaQueries: true,
                applyWidthAttributes: true,
                applyTableAttributes: true,
                removeHtmlSelectors: false,
            };

            inlineCss(signatureHtml, inlineCssOptions).then((inlinedSignatureHtml) => {
                setState((state) => ({ ...state, signature: inlinedSignatureHtml }));
                writePreviewToIframe();
            });
        }
    }, [props.member, props.memberFetching, hiddenPreviewRef.current, previewRef.current]);

    const highestCurrentPosition = useMemo(() => {
        if (props.member) {
            const currentPositions = props.member.positions.filter((p) => !p.end);
            const currentPositionTitles = currentPositions.map((p) => p.title);

            if (currentPositionTitles.includes(POSITIONS.CEO)) {
                return translateMemberPositionTitle(POSITIONS.CEO);
            } else if (currentPositionTitles.includes(POSITIONS.CFO)) {
                return translateMemberPositionTitle(POSITIONS.CFO);
            } else if (currentPositionTitles.includes(POSITIONS.COO)) {
                return translateMemberPositionTitle(POSITIONS.COO);
            } else if (currentPositionTitles.includes(POSITIONS.COO_2)) {
                return translateMemberPositionTitle(POSITIONS.COO_2);
            } else {
                const headOfsTitles = [
                    POSITIONS.HeadOf_NW,
                    POSITIONS.HeadOf_QM,
                    POSITIONS.HeadOf_FI,
                    POSITIONS.HeadOf_IT,
                    POSITIONS.HeadOf_HR,
                    POSITIONS.HeadOf_MA,
                    POSITIONS.HeadOf_SA,
                ];

                currentPositions.sort((a, b) => b.begin.localeCompare(a.begin));

                let newestHeadOfPosition = null;

                for (let i = 0; i < currentPositions.length; i++) {
                    const position = currentPositions[i];
                    if (headOfsTitles.includes(position.title)) {
                        newestHeadOfPosition = position;
                        break;
                    }
                }

                if (newestHeadOfPosition) {
                    return translateMemberPositionTitle(
                        newestHeadOfPosition.title,
                        props.member.salutation
                    );
                } else {
                    return 'Consultant';
                }
            }
        }
    }, [props.member]);

    const renderSignature = () =>
        signatureTemplate(props.member, highestCurrentPosition, formatVorstand());

    const formatVorstand = () => ({
        first: formatSingleVorstand(filterVorstand(POSITIONS.CEO), POSITIONS.CEO),
        second: formatSingleVorstand(filterVorstand(POSITIONS.COO), POSITIONS.COO),
        third: formatSingleVorstand(filterVorstand(POSITIONS.COO_2), POSITIONS.COO_2),
        finance: formatSingleVorstand(filterVorstand(POSITIONS.CFO), POSITIONS.CFO),
    });

    // TODO: Create backend route to get the current mangement board
    const filterVorstand = (positionTitle) => {
        return props.currentVorstaende.find((vorstand) => {
            const currentPositions = vorstand.positions.filter((position) => !position.end);
            return currentPositions.map((p) => p.title).includes(positionTitle);
        });
    };

    const formatSingleVorstand = (vorstand, positionTitle) => ({
        firstName: vorstand ? vorstand.first_name : '',
        lastName: vorstand ? vorstand.last_name : '',
        position: vorstand ? positionTitle : '',
    });

    const writePreviewToIframe = () => {
        setTimeout(() => {
            const hiddenPreviewElement = hiddenPreviewRef.current;
            const iframe = previewRef.current;
            iframe.contentWindow.document.open('text/html', 'replace');
            iframe.contentWindow.document.write(hiddenPreviewElement.innerHTML);
            iframe.contentWindow.document.close();
            setState((state) => ({ ...state, previewReady: true }));
        }, 100);
    };

    const copyCode = () => {
        // clear preview selection
        previewRef.current.contentWindow.getSelection() &&
            previewRef.current.contentWindow.getSelection().empty();
        // select code
        window.getSelection().selectAllChildren(codeRef.current);
        // copy code to clipboard
        document.execCommand('Copy');

        setState((state) => ({ ...state, codePopupOpen: true }));
        setTimeout(() => {
            setState((state) => ({ ...state, codePopupOpen: false }));
        }, POPUP_DELAY);
    };

    const copyPreview = () => {
        // clear code selection
        window.getSelection() && window.getSelection().empty();
        // select preview
        const iframeWindow = previewRef.current.contentWindow;
        const iframeBody = iframeWindow.document.querySelector('body');
        iframeWindow.getSelection().selectAllChildren(iframeBody);
        // copy preview to clipboard
        iframeWindow.document.execCommand('Copy');
        // open preview popup
        setState((state) => ({ ...state, previewPopupOpen: true }));
        setTimeout(() => {
            setState((state) => ({ ...state, previewPopupOpen: false }));
        }, POPUP_DELAY);
    };

    const downloadHtml = () => {
        const html = state.signature;
        const filename = 'hhc_signature.html';

        // Create anchor
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new File([html], filename));
        a.download = filename;

        // Append anchor to body.
        document.body.appendChild(a);
        a.click();

        // Remove anchor from body
        document.body.removeChild(a);
    };

    if (!props.memberFetching && !props.member) {
        return (
            <Container className="signature-generator">
                <Header as="h1" content="E-Mail Signaturengenerator" />

                <Message warning>
                    <Message.Header>Fehler</Message.Header>
                    <p>Diesem User ist kein Member zugeordnet. Bitte wende dich an die IT.</p>
                </Message>
            </Container>
        );
    }

    return (
        <Container className="signature-generator">
            <Header as="h1" content="E-Mail Signaturengenerator" />

            <Message info>
                <Message.Header>Anleitung</Message.Header>
                <Message.List>
                    <Message.Item>
                        Lade deine Signatur als HTML-Datei herunter oder kopiere den Code oder die
                        Vorschau direkt in deine Zwischenablage.
                    </Message.Item>
                    <Message.Item>
                        Anleitungen zum einfügen einer HTML-Sigantur in deinen E-Mail-Client findest
                        du über Google. Bei Fragen oder Problemen steht dir{' '}
                        <a href="mailto:it@hhc-duesseldorf.de">it@hhc-duesseldorf.de</a> gerne zur
                        Seite.
                    </Message.Item>
                    <Message.Item>
                        Sollten deine Daten in der Signatur nicht stimmen, überprüfe bitte, dass in
                        deinem{' '}
                        {props.memberFetching ? (
                            'Mitglieder-Profil'
                        ) : (
                            <Link to={`/member/${props.member.id}`}>Mitglieder-Profil</Link>
                        )}{' '}
                        alles richtig eingetragen ist. Sollte trotz richtiger Daten in deinem
                        Mitglieder-Profil ein Fehler in der Signatur sein, wende dich bitte an die{' '}
                        <a href="mailto:it@hhc-duesseldorf.de">IT</a>.
                    </Message.Item>
                </Message.List>
            </Message>
            {props.memberFetching && (
                <Segment basic>
                    <LoadingBlock dimmed={false} />
                </Segment>
            )}
            <div style={{ display: props.memberFetching ? 'none' : 'block' }}>
                <Button
                    content="HTML-Datei herunterladen"
                    icon="download"
                    onClick={downloadHtml}
                    basic
                />
                <Popup
                    inverted
                    open={state.codePopupOpen}
                    position="top center"
                    content="kopiert"
                    trigger={
                        <Button basic content="HTML-Code kopieren" icon="code" onClick={copyCode} />
                    }
                />
                <Popup
                    inverted
                    open={state.previewPopupOpen}
                    position="top center"
                    content="kopiert"
                    trigger={
                        <Button
                            basic
                            content="Vorschau kopieren"
                            icon="paragraph"
                            onClick={copyPreview}
                        />
                    }
                />

                <Header as="h2" content="Vorschau" />
                <Segment stacked>
                    <div
                        style={{ display: 'none' }}
                        ref={hiddenPreviewRef}
                        dangerouslySetInnerHTML={{
                            __html: state.signature,
                        }}
                    />
                    <iframe
                        title="Signatur Preview"
                        width="100%"
                        height="220px"
                        src="about:blank"
                        ref={previewRef}
                    />
                    <LoadingBlock loading={!state.previewReady} />
                </Segment>

                <Header as="h2" content="HTML-Code" />
                <Segment>
                    <code ref={codeRef}>{state.signature}</code>
                </Segment>
            </div>
        </Container>
    );
};

SignatureGenerator.propTypes = {
    member: PropTypes.object,
    currentVorstaende: PropTypes.array,
    memberFetching: PropTypes.bool.isRequired,
    fetchMember: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    member: getUserMember(state),
    currentVorstaende: getCurrentVorstaende(state),
    memberFetching: getMemberFetching(state),
});

const mapDispatchToProps = {
    fetchMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignatureGenerator);
