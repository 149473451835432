import React from 'react';
import PropTypes from 'prop-types';
import { Form as FormRF } from 'redux-form';

const Form = ({ children, loading, className, ...props }) => (
    <FormRF {...props} className={'ui form ' + className + (loading ? ' loading ' : '')}>
        {children}
    </FormRF>
);

Form.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    className: PropTypes.string,
};

export default Form;
