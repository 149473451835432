import React from 'react';
import PropTypes from 'prop-types';

import './sortButtonStyles.scss';

const SortButton = ({ name, value, sortValue, sortDirection, setFilter }) => {
    const filterHandler = () => {
        setFilter(value);
    };

    const arrow = () => {
        if (sortValue === value) {
            return sortDirection === 'asc' ? ' ⏷' : ' ⏶';
        }
        return '';
    };

    return (
        <span
            className="sort-button"
            onClick={filterHandler}
            title={`Nach ${name} ${
                sortValue === value && sortDirection === 'asc' ? 'ab' : 'auf'
            }steigend sortieren`}
        >
            {name + arrow()}
        </span>
    );
};

SortButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    sortValue: PropTypes.string.isRequired,
    sortDirection: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
};

export default SortButton;
