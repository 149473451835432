const { useSelector } = require('react-redux');

export const RIGHTS = {
    deleteMemberById: 'deleteMemberById',
    updateAllMember: 'updateAllMember',
    updateOwnMember: 'updateOwnMember',
    readAllMember: 'readAllMember',
    createMember: 'createMember',
    editUsersRolesRights: 'editUsersRolesRights',
    readAddressData: 'readAddressData',
    readOwnAddressData: 'readOwnAddressData',
};

export function useUserRights(payload = {}) {
    const rights = useSelector((state) => state.auth.rights);
    const memberId = useSelector((state) => state.auth.memberId);

    function hasRight(right) {
        return rights.includes(right);
    }

    function isOwnMember() {
        return (memberId || memberId === 0) && payload?.memberId === memberId;
    }

    return {
        [RIGHTS.deleteMemberById]: hasRight('deleteMemberById'),
        [RIGHTS.updateAllMember]: hasRight('updateAllMember'),
        [RIGHTS.updateOwnMember]: hasRight('updateOwnMember') && isOwnMember(),
        [RIGHTS.readAllMember]: hasRight('readAllMember'),
        [RIGHTS.createMember]: hasRight('createMember'),
        [RIGHTS.editUsersRolesRights]: hasRight('editUsersRolesRights'),
        [RIGHTS.readAddressData]: hasRight('readAddressData'),
        [RIGHTS.readOwnAddressData]: hasRight('readOwnAddressData') && isOwnMember(),
    };
}
