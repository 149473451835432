import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

import { deleteMember, fetchMember } from '../memberActions';
import { Member } from '../memberTypes';

interface MemberDeleteModalProps {
    closeModal: () => void;
    data: Member;
}

const MemberDeleteModal: FC<MemberDeleteModalProps> = ({ closeModal, data: member }) => {
    const dispatch = useDispatch();

    const handleConfirm = async () => {
        await dispatch(deleteMember(member.id));
        closeModal();
        dispatch(fetchMember());
    };

    return (
        <React.Fragment>
            <Modal.Header>Mitgliedschaft Löschen</Modal.Header>
            <Modal.Content className="text-big">
                Sind Sie sicher, dass Sie die Mitgliedschaft von
                {member && (
                    <span>
                        {' '}
                        <b>
                            {member.first_name} {member.last_name}
                        </b>{' '}
                    </span>
                )}
                beenden möchten?
                <br />
                Die Kontaktdaten bleiben davon unberührt.
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    content="Mitgliedschaft beenden"
                    icon="ban"
                    onClick={handleConfirm}
                />
                <Button content="Abbrechen" onClick={closeModal} />
            </Modal.Actions>
        </React.Fragment>
    );
};

export default MemberDeleteModal;
