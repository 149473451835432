import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'semantic-ui-react';
import omit from 'lodash/omit';
import { formatDate } from 'utilities';

/*
 * Semantic UI Form.Input
 */
const Input = ({ input, meta, width, disabled, inputRef, icon, ...props }) => {
    return (
        <Form.Field width={width}>
            <Form.Input
                {...omit(props, 'defaultValue')}
                name={input.name}
                value={input.value || ''}
                disabled={disabled}
                ref={inputRef}
                error={meta.touched && !!meta.error}
                onChange={(param, data) => input.onChange(data.value)}
                iconPosition={icon && 'left'}
            >
                {icon && <Icon name={icon} />}
                <input />
            </Form.Input>
            {meta.touched &&
                ((meta.error && <span className="validation-message error">{meta.error}</span>) ||
                    (meta.warning && (
                        <span className="validation-message warning">{meta.warning}</span>
                    )))}
        </Form.Field>
    );
};

Input.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    width: PropTypes.number,
    disabled: PropTypes.bool,
    inputRef: PropTypes.func,
};

export default Input;
