import omit from 'lodash/omit';

import * as types from './memberTypes';

const member = (
    state = {
        memberList: [],
        fetching: false,
        creating: false,
        updating: false,
        deleting: false,
        error: null,
        search: {
            searchValue: '',
            filter: {},
            sortValue: 'member.first_name',
            sortDirection: 'asc',
        },
    },
    action
) => {
    switch (action.type) {
        case types.FETCH_MEMBER:
            return {
                ...state,
                fetching: true,
            };

        case types.FETCH_MEMBER_SUCCESS:
            return {
                ...state,
                fetching: false,
                memberList: action.payload,
            };

        case types.FETCH_MEMBER_FAILURE:
            return {
                ...state,
                fetching: false,
                error: action.error && action.error.data,
            };

        /**
         * CREATE MEMBER
         */
        case types.CREATE_MEMBER:
            return {
                ...state,
                creating: true,
            };
        case types.CREATE_MEMBER_SUCCESS:
            return {
                ...state,
                creating: false,
            };
        case types.CREATE_MEMBER_FAILURE:
            return {
                ...state,
                creating: false,
                error: action.error && action.error.data,
            };

        /**
         * EDIT MEMBER
         */
        case types.EDIT_MEMBER:
            return {
                ...state,
                updating: true,
                error: action.error && action.error.data,
            };
        case types.EDIT_MEMBER_SUCCESS:
            return {
                ...state,
                updating: false,
                // TODO: get the updated member from the action.payload (currently this only includes the basic member data without ressorts etc.)
                memberList: state.memberList.map((member) =>
                    member.id === action._request.data.id ? action._request.data : member
                ),
            };
        case types.EDIT_MEMBER_FAILURE:
            return {
                ...state,
                updating: false,
                error: action.error && action.error.data,
            };

        /**
         * DELETE MEMBER
         */
        case types.DELETE_MEMBER:
            return {
                ...state,
                deleting: true,
            };
        case types.DELETE_MEMBER_SUCCESS:
            return {
                ...state,
                deleting: false,
            };
        case types.DELETE_MEMBER_FAILURE:
            return {
                ...state,
                deleting: false,
                error: action.error && action.error.data,
            };

        // ==================================
        // ============= SEARCH =============
        // ==================================

        // TODO: Refactor!

        case types.SET_MEMBER_SEARCH_VALUE:
            return {
                ...state,
                search: {
                    ...state.search,
                    searchValue: action.searchValue,
                },
            };

        case types.SET_MEMBER_SEARCH_FILTER: {
            let newState = { ...state.search.filter };
            if (action.checked) {
                if (!newState[action.name]) newState[action.name] = [];
                newState[action.name].push(action.value);
            } else {
                const index = newState[action.name].indexOf(action.value);
                if (index > -1) newState[action.name].splice(index, 1);
            }
            if (newState[action.name].length === 0) {
                newState = omit(newState, action.name);
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filter: newState,
                },
            };
        }

        case types.CLEAR_MEMBER_FILTER: {
            let newState = { ...state.search.filter };
            if (action.filter) {
                newState = omit(newState, action.filter);
            } else {
                newState = {};
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filter: newState,
                },
            };
        }

        case types.SET_MEMBER_SORT_VALUE:
            return {
                ...state,
                search: {
                    ...state.search,
                    sortValue: action.sortValue,
                    sortDirection: 'asc',
                },
            };

        case types.SET_MEMBER_SORT_DIRECTION:
            return {
                ...state,
                search: {
                    ...state.search,
                    sortDirection: action.sortDirection,
                },
            };

        default:
            return state;
    }
};

export default member;
