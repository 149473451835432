import { combineReducers } from 'redux';

import * as types from './userTypes';

const changePassword = (
    state = {
        changing: false,
        error: '',
    },
    action
) => {
    switch (action.type) {
        case types.CHANGE_PASSWORD:
            return {
                ...state,
                changing: true,
                error: '',
            };

        case types.CHANGE_PASSWORD_SUCCESS:
        case types.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changing: false,
                error: action.error && (action.error.response.data || action.error.message),
            };

        default:
            return state;
    }
};

const requestPasswordReset = (
    state = {
        loading: false,
        error: '',
    },
    action
) => {
    switch (action.type) {
        case types.REQUEST_PASSWORD_RESET:
            return {
                ...state,
                loading: true,
                error: '',
            };

        case types.REQUEST_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case types.REQUEST_PASSWORD_RESET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error && (action.error.data || action.error.message),
            };

        default:
            return state;
    }
};

const resetPassword = (
    state = {
        loading: false,
        error: '',
    },
    action
) => {
    switch (action.type) {
        case types.RESET_PASSWORD:
            return {
                ...state,
                loading: true,
                error: '',
            };

        case types.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case types.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error && action.error.data,
            };

        default:
            return state;
    }
};

export default combineReducers({
    changePassword,
    requestPasswordReset,
    resetPassword,
});
