import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button } from 'semantic-ui-react';

import Input from '../Input';

const CustomInput = ({ toggleHandler, ...props }) => (
    <Field
        {...props}
        component={Input}
        action={<Button basic type="button" icon="arrow left" onClick={() => toggleHandler()} />}
        actionPosition="left"
    />
);

CustomInput.propTypes = {
    changeHandler: PropTypes.func,
    toggleHandler: PropTypes.func,
};

export default CustomInput;
