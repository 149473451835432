import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { translatePrefixIntoImage } from 'utilities';
// import fieldLevelValidation from 'utilities/fieldLevelValidation';
import Select from 'components/Select';
import Input from 'components/Input';

class EditRole extends Component {
    render() {
        const rightsOptions = this.props.rights.map((right) => ({
            key: right.name.toLowerCase(),
            text: right.name.toLowerCase(),
            value: right.name.toLowerCase(),
        }));

        const userOptions = this.props.users.map((user) => ({
            value: user.id,
            key: user.id,
            text: `${user.email}`,
            image: {
                avatar: true,
                src: translatePrefixIntoImage(user?.prefix),
            },
        }));

        const userLabel = (label) => ({
            content: label.text,
            image: label.image,
        });

        return (
            <div>
                <Field
                    name="rolename"
                    component={Input}
                    type="text"
                    label="Name"
                    placeholder="Name"
                />
                <Field
                    multiple
                    search
                    fluid
                    name="rights"
                    component={Select}
                    options={rightsOptions}
                    placeholder="Rechte"
                    label="Rechte"
                    disabled={this.props.disabled}
                />
                <Field
                    multiple
                    search
                    fluid
                    name="users"
                    component={Select}
                    options={userOptions}
                    renderLabel={userLabel}
                    placeholder="Benutzer"
                    label="Benutzer"
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

EditRole.propTypes = {
    disabled: PropTypes.bool,
    formName: PropTypes.string,
    users: PropTypes.array,
    rights: PropTypes.array,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(EditRole);
