import { useState, useEffect, useCallback } from 'react';

export default function useMediaQueries() {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = useCallback(() => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    });

    return {
        isMobile: width < 768,
        isTablet: width < 1024,
        isDesktop: width >= 1024,
    };
}
