import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Button, Modal } from 'semantic-ui-react';
import { reduxForm } from 'redux-form';

import Form from 'components/Form';
import EditRight from './EditRight';
import { fetchRoles } from 'features/administration/roles/redux/rolesActions';
import * as RolesSelector from 'features/administration/roles/redux/rolesSelector';

import { updateRight, fetchRights } from '../redux/rightsActions';
import * as RightsSelectors from '../redux/rightsSelector';
//import { fetchDescriptionForRight } from '../redux/rightsActions';

class RightCreateEditModal extends Component {
    componentDidMount() {
        this.props.fetchRoles();
    }

    onSubmit = (data) => {
        this.props.updateRight(data);
        this.props.closeModal();
        this.props.fetchRights();
    };

    render() {
        return (
            <React.Fragment>
                <Modal.Header>Recht {this.props.data ? 'bearbeiten' : 'hinzufügen'}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <EditRight
                            roles={this.props.roles}
                            value={this.props.initialValues}
                            rightDescription={this.props.name?.description}
                            name={this.props.name?.name}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        content={this.props.data ? 'Speichern' : 'Hinzufügen'}
                        icon={this.props.data ? 'edit' : 'add'}
                        onClick={() => this.props.submit('rightCreateEdit')}
                    />
                    <Button content="Schließen" onClick={this.props.closeModal} />
                </Modal.Actions>
            </React.Fragment>
        );
    }
}

RightCreateEditModal.propTypes = {
    data: PropTypes.object,
    roles: PropTypes.array,
    fetchRoles: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    initialValues: ownProps.data,
    roles: RolesSelector.roles(state),
});

export default connect(mapStateToProps, {
    submit,
    fetchRoles,
    updateRight,
    fetchRights,
})(
    reduxForm({
        form: 'rightCreateEdit',
    })(RightCreateEditModal)
);
