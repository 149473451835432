import React from 'react';
import PropTypes from 'prop-types';

import {
    formatDate,
    translateMemberRessortShortName,
    translateMemberPositionTitle,
    translateMemberStatusTitle,
} from 'utilities';
import { Divider } from 'semantic-ui-react';

const ViewMember = (props) => {
    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td width="20%">Eintritt:</td>
                        <td>{formatDate(props.member.status[0]?.begin)}</td>
                    </tr>
                    {props.member.status[props.member.status.length - 1]?.end && (
                        <tr>
                            <td width="20%">Austritt:</td>
                            <td>
                                {formatDate(
                                    props.member.status[props.member.status.length - 1].end
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Divider />
            <table>
                <thead>
                    <tr>
                        <th>Ressorts</th>
                        <th style={{ width: '25%' }}>von</th>
                        <th style={{ width: '25%' }}>bis</th>
                    </tr>
                </thead>
                <tbody>
                    {props.member.ressorts.map((ressort, index) => (
                        <tr key={index}>
                            <td>{translateMemberRessortShortName(ressort.name_short)}</td>
                            <td>{formatDate(ressort.begin)}</td>
                            <td>{ressort.end ? formatDate(ressort.end) : '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table style={{ marginTop: '1.5rem' }}>
                <thead>
                    <tr>
                        <th>Positions</th>
                        <th style={{ width: '25%' }}>von</th>
                        <th style={{ width: '25%' }}>bis</th>
                    </tr>
                </thead>
                <tbody>
                    {props.member.positions.map((position, index) => (
                        <tr key={index}>
                            <td>{translateMemberPositionTitle(position.title)}</td>
                            <td>{formatDate(position.begin)}</td>
                            <td>{position.end ? formatDate(position.end) : '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table style={{ marginTop: '1.5rem' }}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th style={{ width: '25%' }}>von</th>
                        <th style={{ width: '25%' }}>bis</th>
                    </tr>
                </thead>
                <tbody>
                    {props.member.status.map((status, index) => (
                        <tr key={index}>
                            <td>{translateMemberStatusTitle(status.title)}</td>
                            <td>{formatDate(status.begin)}</td>
                            <td>{status.end ? formatDate(status.end) : '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table style={{ marginTop: '1.5rem' }}>
                <thead>
                    <tr>
                        <th>Expert Teams</th>
                        <th>Position</th>
                        <th style={{ width: '25%' }}>von</th>
                        <th style={{ width: '25%' }}>bis</th>
                    </tr>
                </thead>
                <tbody>
                    {props.member.expert_teams.map(({ title, position, begin, end }, index) => (
                        <tr key={index}>
                            <td>{title}</td>
                            <td>{position}</td>
                            <td>{formatDate(begin)}</td>
                            <td>{end ? formatDate(end) : '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

ViewMember.propTypes = {
    member: PropTypes.object,
};

export default ViewMember;
