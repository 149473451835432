import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import axiosMiddleware from './axiosMiddleware';

// import createLogger from 'redux-logger';
// const logger = createLogger();

const middleware = compose(
    applyMiddleware(
        thunk,
        promise,
        axiosMiddleware
        /* , looger */
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

export default middleware;
