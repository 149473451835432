import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';

import { Modal, openModal } from 'components/Modal';
import FilterableTableList from 'components/lists/FilterableTableList';
import FlexContainer from 'components/FlexContainer';
import RightsListEntry from './components/RightsListEntry';
import RightCreateEditModal from './components/RightCreateEditModal';
import RightDeleteModal from './components/RightDeleteModal';
import { fetchRights } from './redux/rightsActions';
import * as RightsSelector from './redux/rightsSelector';

class RightsPage extends Component {
    static propTypes = {
        rights: PropTypes.array.isRequired,
        fetchRights: PropTypes.func.isRequired,
        rightsFetching: PropTypes.bool.isRequired,
        openModal: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.fetchRights();
    }

    render() {
        return (
            <div className="rights-page">
                <FlexContainer hAlign="right"></FlexContainer>
                <Segment>
                    <FilterableTableList
                        component={RightsListEntry}
                        items={this.props.rights}
                        fetching={this.props.rightsFetching}
                        header={['Recht', '']}
                        listType="divided dashed striped hoverable"
                        title="Benutzerrechte"
                        columnWidth={['3rem', 'auto', '2rem']}
                    />
                </Segment>
                <Modal name="rightCreateEdit" component={RightCreateEditModal} loading={false} />
                <Modal name="rightDelete" component={RightDeleteModal} loading={false} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    rights: RightsSelector.rights(state),
    rightsFetching: RightsSelector.rightsFetching(state),
});

const mapDispatchToProps = {
    fetchRights,
    openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightsPage);
