import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';

import { Modal, openModal } from 'components/Modal';
import FlexContainer from 'components/FlexContainer';
import RolesList from './components/RolesList';
import RoleCreateEditModal from './components/RoleCreateEditModal';
import RoleDeleteModal from './components/RoleDeleteModal';
import { fetchRoles } from './redux/rolesActions';
import * as RolesSelector from './redux/rolesSelector';
import { fetchUsers } from '../users/redux/usersActions';
import { fetchRights } from '../rights/redux/rightsActions';

class RolesPage extends Component {
    componentDidMount() {
        this.props.fetchRoles();
        //this.props.fetchUsers();
        //this.props.fetchRights();
    }

    render() {
        return (
            <div className="roles-page">
                <FlexContainer hAlign="right">
                    <Button
                        basic
                        icon="add"
                        onClick={() => this.props.openModal('roleCreateEdit')}
                        title="Rolle hinzufügen"
                        content="Rolle hinzufügen"
                    />
                </FlexContainer>
                <Segment>
                    <RolesList roles={this.props.roles} fetching={this.props.rolesFetching} />
                </Segment>
                <Modal name="roleCreateEdit" component={RoleCreateEditModal} loading={false} closeOnDimmerClick={false} />
                <Modal name="roleDelete" component={RoleDeleteModal} loading={false} />
            </div>
        );
    }
}

RolesPage.propTypes = {
    roles: PropTypes.array.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    rolesFetching: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    roles: RolesSelector.roles(state),
    rolesFetching: RolesSelector.rolesFetching(state),
});

const mapDispatchToProps = {
    fetchRoles,
    openModal,
    fetchRights,
    fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);
